<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ accountId }}">{{ accountId | titlecase }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Creative Analysis Assistant</li>
                </ol>
            </nav>

            <div class="row align-items-center">
                <div class="col">
                    <h3>Images</h3>
                </div>
                <div class="col-auto">
                    Jump to:
                </div>
                <div class="col-auto">
                    <div class="input-group float-md-end" style="width: 150px;">
                        <span class="input-group-text"[ngbTooltip]="'Creative ID'"><i class="bi bi-image"></i></span>
                        <input type="text" id="creativeId" name="creativeId" [(ngModel)]="creativeId" (keydown.enter)="onCreativeIdEnter()" placeholder="IMG001" class="form-control" required>
                    </div>
                </div>
                <div class="col-auto">
                    <a class="btn btn-dark" routerLink="/ad-accounts/creative-assistant/{{ accountId }}/batch" style="margin-left: 15px;"><i class="bi bi-images" style="margin-right: 5px;"></i>Batch Add/View</a>
                </div>
            </div>
            <div *ngIf="loadingImages" style="text-align: center; margin-top: 20px;">
                <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>
            </div>
            <div class="row row-cols-auto justify-content-center" style="margin-top: 20px; margin-bottom: 30px;">
                <div *ngFor="let image of images" class="col" style="margin-bottom: 20px;">
                    <div class="card" style="width: 100px; border-color: #888888;">
                        <a routerLink="/ad-accounts/creative-assistant/{{ accountId }}/{{ image.id }}"><img [src]="image.thumbnail" class="card-img-top" style="width: 98px; height: 98px;"></a>
                        <div class="card-footer" style="height: 24px; padding: 2px 0 0 0; text-align: center; font-size: 85%; background-color: black; color: white;">
                          {{ image.id }}
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 50px;">
                <div class="row align-items-center">
                    <div class="col">
                        <h3>Videos</h3>
                    </div>
                    <div class="col-auto">
                        Jump to:
                    </div>
                    <div class="col-auto">
                        <div class="input-group float-md-end" style="width: 150px;">
                            <span class="input-group-text"[ngbTooltip]="'Creative ID'"><i class="bi bi-play-btn"></i></span>
                            <input type="text" id="creativeId" name="creativeId" [(ngModel)]="creativeId" (keydown.enter)="onCreativeIdEnter()" placeholder="VID001" class="form-control" required>
                        </div>
                    </div>
                </div>
                <div *ngIf="loadingVideos" style="text-align: center; margin-top: 20px;">
                    <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>
                </div>
                <div class="row row-cols-auto justify-content-center" style="margin-top: 20px; margin-bottom: 30px;">
                    <div *ngFor="let video of videos" class="col" style="margin-bottom: 20px;">
                        <div class="card" style="width: 100px; border-color: #888888;">
                            <a routerLink="/ad-accounts/creative-assistant/{{ accountId }}/{{ video.id }}"><img [src]="video.thumbnail" class="card-img-top" style="width: 98px; height: 98px;"></a>
                            <div class="card-footer" style="height: 24px; padding: 2px 0 0 0; text-align: center; font-size: 85%; background-color: black; color: white;">
                            {{ video.id }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

        </div>    
    </div>
</div>