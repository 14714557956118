<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <div class="row">
                <div class="col">
                    <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                            <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ accountId }}">{{ accountId | titlecase }}</a></li>
                            <li class="breadcrumb-item"><a routerLink="/ad-accounts/creative-assistant/{{ accountId }}">Creative Analysis Assistant</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Batch Creatives</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-auto">
                    <div class="dropdown" style="margin-bottom: 25px;">
                        <button class="dropdown-toggle" (click)="toggleDropdownModel()">
                          {{ selectedModel | uppercase }}
                        </button>
                        <div class="dropdown-menu" [class.show]="dropdownModelOpen">
                          <a class="dropdown-item" (click)="closeDropdownModel('openai')">
                            <img src="{{ environment.publicAssetsBucketUrl }}/app-public/images/openai.png" style="width: 20px; height: 20px; margin-right: 10px;">OpenAI
                          </a>
                          <a class="dropdown-item" (click)="closeDropdownModel('gemini')">
                            <img src="{{ environment.publicAssetsBucketUrl }}/app-public/images/gemini.png" style="width: 20px; height: 20px; margin-right: 10px;">Google Gemini
                          </a>
                        </div>
                    </div>
                </div>
            </div>            

            <div style="margin-top: 30px;">
                <div *ngIf="showSelectCreatives" style="margin: 20px 0;">
                    <h5 style="text-align: center;">Upload new creatives:</h5>

                    <div class="container" style="margin-bottom: 50px; width: 700px;">
                        <div class="drag-drop-zone" 
                        (dragover)="onDragOver($event)" 
                        (drop)="onDroppedFiles($event)" 
                        (dragleave)="onDragLeave($event)"
                        (click)="onDropZoneClicked()">
                        <div>Drag & drop files here or click to select files</div>                        
                        </div>
                        <input type="file" id="filesSelector" multiple (change)="onFilesSelected($event)" style="display: none;">
                    </div>

                    <div style="text-align: center; margin: 40px 0;">
                        <h5 style="margin: 0;">OR</h5>
                    </div>
                    <div class="row g-0 align-items-center" style="text-align: center;">
                        <div class="col"></div>
                        <div class="col-auto">
                            <h5 style="margin: 0;">View existing creatives:</h5>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="requestedCreativeIds" name="requestedCreativeIds" [(ngModel)]="requestedCreativeIds" autofocus (keydown.enter)="updateRequestedCreativeIds()" style="width: 200px; text-align: left; margin-left: 15px;;" placeholder="IMG001,IMG002..." class="form-control" required>
                        </div>
                        <div class="col"></div>
                    </div>
                </div>

                <div *ngFor="let creative of creatives; let i = index" class="card mb-3">
                    <div class="row g-0">
                        <div class="col-auto">
                            <img *ngIf="!creative.thumbnail && !creative.url" src="/assets/images/pix.gif" class="img-fluid rounded-start" alt="{{ creative.id }}" style="width: 180px; background-color: #f5f5f5;">
                            <a *ngIf="creative.thumbnail && creative.id.startsWith('IMG')" [href]="creative.url" target="_blank">
                                <img src="{{ creative.thumbnail }}" class="img-fluid rounded-start" alt="{{ creative.id }}" style="width: 180px; background-color: #f5f5f5;">
                            </a>
                            <video *ngIf="creative.url && creative.id.startsWith('VID')" controls style="width: 180px; background-color: #f5f5f5;">
                                <source [src]="creative.url" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>                          
                        </div>
                        <div class="col">
                          <div class="card-body" style="padding-bottom: 0;">
                            <table class="table table-borderless" style="margin-bottom: 0;">
                                <tbody>
                                    <tr>
                                        <td style="width: 180px;"><h5 class="card-title">{{ creative.id != '' ? creative.id : 'Image #'+ (i+1) }}</h5></td>
                                        <td style="text-align: right;">
                                            <span *ngIf="creative.error && creative.error != ''" style="margin-right: 10px; color: pink; font-size: .875em;">{{ creative.error }}</span>
                                            <span *ngIf="creative.analysisStatus != ''" style="margin-right: 10px; color: #cdcdcd; font-size: .875em;">{{ creative.analysisStatus }}</span>
                                            <span *ngIf="creative.loading" class="spinner-border spinner-border-sm" style="margin-left: 10px;" role="status" aria-hidden="true"></span><span *ngIf="creative.analysing" class="spinner-border text-info spinner-border-sm" style="margin-right: 10px;" role="status" aria-hidden="true"></span>
                                            <button type="button" *ngIf="(creative.id != '') && !creative.loading" (click)="classifyCreative(creative.id)" class="btn btn-sm btn-light"><i class="bi bi-arrow-repeat"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                                

                            <table class="table table-borderless" style="margin-bottom: 0;">
                                <tbody>
                                    <tr>
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Main Angle</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.mainAngle ?? ''" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                            <div *ngIf="creative.showDebug" style="color: gray; font-size: 85%; margin-top: 5px;">
                                                <div *ngIf="creative.mainAngleAlternatives">Alternative(s): <span *ngFor="let alternative of creative.mainAngleAlternatives" class="badge bg-light text-dark" style="margin-right: 5px; font-weight: normal; white-space: normal;">{{ alternative }}</span></div>
                                                <div>{{ creative.mainAngleReasons }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Creative Style</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.creativeStyle ?? ''" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                            <div *ngIf="creative.showDebug" style="color: gray; font-size: 85%; margin-top: 5px;">
                                                <div *ngIf="creative.creativeStyleAlternatives">Alternative(s): <span *ngFor="let alternative of creative.creativeStyleAlternatives" class="badge bg-light text-dark" style="margin-right: 5px; font-weight: normal; white-space: normal;">{{ alternative }}</span></div>
                                                <div>{{ creative.creativeStyleReasons }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Target Market</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.targetMarketDescription ?? ''" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                            <div *ngIf="creative.showDebug" style="color: gray; font-size: 85%; margin-top: 5px;">
                                                <div>{{ creative.targetMarketReasons }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Market Awareness</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.marketAwareness ?? ''" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                            <div *ngIf="creative.showDebug" style="color: gray; font-size: 85%; margin-top: 5px;">
                                                <div>{{ creative.marketAwarenessReasons }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Discounts</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.discount ?? ''" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                            <div *ngIf="creative.discountCode && creative.discountCode != '' && creative.discountCode != 'N/A'" class="row" style="color: gray; font-size: 85%; margin-top: 5px;">
                                                <div *ngIf="creative.discountCode && creative.discountCode != '' && creative.discountCode != 'N/A'" class="col-auto" style="font-weight: bold;">Discount Code</div>
                                                <div *ngIf="creative.discountCode && creative.discountCode != '' && creative.discountCode != 'N/A'" class="col-auto">{{ creative.discountCode }}</div>
                                                <div class="col"></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.headline">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Headline</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.headline ?? ''" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.personFeatureVisible || creative.personFeatureDescription">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Person Feature</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.personFeatureVisible ?? ''" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                            <div *ngIf="creative.personFeatureDescription && creative.personFeatureDescription != '' && creative.personFeatureDescription != 'N/A'" class="row" style="color: gray; font-size: 85%; margin-top: 5px;">
                                                <div *ngIf="creative.personFeatureDescription && creative.personFeatureDescription != '' && creative.personFeatureDescription != 'N/A'" class="col-auto" style="padding-right: 50px">{{ creative.personFeatureDescription }}</div>
                                                <div class="col"></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.callToAction">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Call To Action</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.callToAction" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.audioHook">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Audio Hook</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.audioHook" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.visualHook">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Visual Hook</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.visualHook" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.ageGroup">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Age Group</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.ageGroup" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.gender">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Gender</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.gender" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.length">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Length</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.length" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.dialogueCTA">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Dialogue CTA</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.dialogueCTA" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="creative.visualEndFrame">
                                        <td style="width: 180px; font-weight: bold; padding-top: 12px;">Visual End Frame</td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text" class="form-control" [value]="creative.visualEndFrame" style="background-color: #f5f5f5; border: none;">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                          </div>
                        </div>
                    </div>
                    <div class="row g-0 align-items-center">
                        <div class="col"><i class="bi bi-info-circle" (click)="showCreativeDebug(creative.id)" [ngbTooltip]="'Classified: '+ (creative.classified | date:'d MMMM hh:mma')+' - ' + creative.model + ' #' + creative.classificationId" style="margin-left: 20px;"></i></div>
                        <div class="col-auto"><button type="button" class="btn btn-sm btn-dark" style="margin: 8px 24px 20px 0;" disabled>Save</button></div>
                    </div>
                    <!-- <div class="row justify-content-center" *ngIf="creative.showDebug">
                        <div class="col">
                            <div style="max-width: 1100px; padding: 0px 25px 20px;">
                                <ngx-json-viewer [json]="creative.reasons" style="font-size: 10px;"></ngx-json-viewer>
                            </div>                            
                        </div>
                    </div> -->
                </div>
            </div>

        </div>    
    </div>
</div>