import { Routes } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';
import { AdminAuthGuardService } from './services/admin-auth-guard.service';
import { AdAccountsAuthGuardService } from './services/adaccounts-auth-guard.service';

import { HomeComponent } from './components/home/home.component';
import { AccountComponent } from './components/account/account.component';
import { Error404Component } from './components/error-404/error-404.component';
import { DashboardsComponent } from './components/dashboards/dashboards.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SupportComponent } from './components/support/support.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminUserComponent } from './components/admin/user/user.component';
import { AdAccountsComponent } from './components/ad-accounts/ad-accounts.component';
import { AdAccountComponent } from './components/ad-accounts/ad-account/ad-account.component';
import { NcGeneratorRejuvausComponent } from './components/ad-accounts/nc-generator/rejuvaus/rejuvaus.component';
import { NcGeneratorTwoobsComponent } from './components/ad-accounts/nc-generator/twoobs/twoobs.component';
import { NcGeneratorWaterdropAnzComponent } from './components/ad-accounts/nc-generator/waterdrop-anz/waterdrop-anz.component';
import { NcGeneratorUptodashComponent } from './components/ad-accounts/nc-generator/uptodash/uptodash.component';
import { NcGeneratorNinjaKitchenAnzComponent } from './components/ad-accounts/nc-generator/ninja-kitchen-anz/ninja-kitchen-anz.component';
import { NcGeneratorSharkAnzComponent } from './components/ad-accounts/nc-generator/shark-anz/shark-anz.component';
import { NcGeneratorLifeCykelAusComponent } from './components/ad-accounts/nc-generator/life-cykel-aus/life-cykel-aus.component';
import { NcGeneratorTheBeautyChefComponent } from './components/ad-accounts/nc-generator/the-beauty-chef/the-beauty-chef.component';
import { CreativeUploaderComponent } from './components/ad-accounts/creative-uploader/creative-uploader.component';
import { NcAuditorComponent } from './components/ad-accounts/nc-auditor/nc-auditor.component';
import { NamingConventionComponent } from './components/ad-accounts/naming-convention/naming-convention.component';
import { CreativeAnalysisComponent } from './components/ad-accounts/creative-analysis/creative-analysis.component';
import { CreativeAnalysisSingleComponent } from './components/ad-accounts/creative-analysis-single/creative-analysis-single.component';
import { CreativeAnalysisBatchComponent } from './components/ad-accounts/creative-analysis-batch/creative-analysis-batch.component';

import { environment } from '../environments/environment';
import { Error401Component } from './components/error-401/error-401.component';

export const routes: Routes = [
    {
        path: '',
        title: environment.appName + ' // Home',
        component: HomeComponent,
    },
    {
        path: 'dashboards',
        title: environment.appName + ' // Dashboards',
        component: DashboardsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'dashboards/:id',
        title: environment.appName + ' // Dashboards',
        component: DashboardComponent,
        canActivate: [AuthGuardService]
    },
    // Specific ad-accounts routes should come before generic ones
    {
        path: 'ad-accounts/naming-convention/:id',
        title: environment.appName + ' // Ad Accounts - Naming Convention',
        component: NamingConventionComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        // For path ad-accounts/naming-convention/ (without id provided) redirect to AdAccountsComponent
        path: 'ad-accounts/naming-convention',
        redirectTo: 'ad-accounts',
        pathMatch: 'full'           
    },
    {
        path: 'ad-accounts/creative-assistant/:id/batch',
        title: environment.appName + ' // Ad Accounts - Creative Analysis Assistant - Batch Creatives',
        component: CreativeAnalysisBatchComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/creative-assistant/:id/:creative',
        title: environment.appName + ' // Ad Accounts - Creative Analysis Assistant - Single Creative',
        component: CreativeAnalysisSingleComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/creative-assistant/:id',
        title: environment.appName + ' // Ad Accounts - Creative Analysis Assistant',
        component: CreativeAnalysisComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        // For path ad-accounts/creative-assistant/ (without id provided) redirect to AdAccountsComponent
        path: 'ad-accounts/creative-assistant',
        redirectTo: 'ad-accounts',
        pathMatch: 'full'           
    },
    {
        path: 'ad-accounts/creative-uploader/:id',
        title: environment.appName + ' // Ad Accounts - Creative Uploader',
        component: CreativeUploaderComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        // For path ad-accounts/creative-uploader/ (without id provided) redirect to AdAccountsComponent
        path: 'ad-accounts/creative-uploader',
        redirectTo: 'ad-accounts',
        pathMatch: 'full'           
    },
    {
        path: 'ad-accounts/nc-auditor/:id',
        title: environment.appName + ' // Ad Accounts - Naming Convention Auditor',
        component: NcAuditorComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        // For path ad-accounts/nc-auditor/ (without id provided) redirect to AdAccountsComponent
        path: 'ad-accounts/nc-auditor',
        redirectTo: 'ad-accounts',
        pathMatch: 'full'           
    },
    {
        path: 'ad-accounts/nc-generator/rejuvaus',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - Rejuvaus',
        component: NcGeneratorRejuvausComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/nc-generator/uptodash',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - Uptodash',
        component: NcGeneratorUptodashComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/nc-generator/twoobs',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - TWOOBS',
        component: NcGeneratorTwoobsComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/nc-generator/waterdrop-anz',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - Waterdrop ANZ',
        component: NcGeneratorWaterdropAnzComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/nc-generator/ninja-kitchen-anz',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - Ninja Kitchen ANZ',
        component: NcGeneratorNinjaKitchenAnzComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/nc-generator/shark-anz',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - Shark ANZ',
        component: NcGeneratorSharkAnzComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/nc-generator/life-cykel-aus',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - Life Cykel AUS',
        component: NcGeneratorLifeCykelAusComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts/nc-generator/the-beauty-chef',
        title: environment.appName + ' // Ad Accounts - Naming Convention Generator - The Beauty Chef',
        component: NcGeneratorTheBeautyChefComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    // Other client NcGenerator routes should come here
    {
        // For path ad-accounts/nc-generator/ (without id provided) redirect to AdAccountsComponent
        path: 'ad-accounts/nc-generator',
        redirectTo: 'ad-accounts',
        pathMatch: 'full'           
    },
    {
        path: 'ad-accounts/:id',
        title: environment.appName + ' // Ad Accounts',
        component: AdAccountComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'ad-accounts',
        title: environment.appName + ' // Ad Accounts',
        component: AdAccountsComponent,
        canActivate: [AuthGuardService, AdAccountsAuthGuardService]
    },
    {
        path: 'support',
        title: environment.appName + ' // Support',
        component: SupportComponent,
        // canActivate: [AuthGuardService]
    },
    {
        path: 'account',
        title: environment.appName + ' // Account',
        component: AccountComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'privacy',
        title: environment.appName + ' // Privacy Policy',
        component: PrivacyComponent,
    },
    {
        path: 'admin',
        title: environment.appName + ' // Admin',
        component: AdminComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
    },
    {
        path: 'admin/user/:id',
        title: environment.appName + ' // Admin - User',
        component: AdminUserComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
    },
    {
        path: '401',
        title: environment.appName + ' // Error 401',
        component: Error401Component,
    },
    {
        path: '**',
        component: Error404Component
    }
];
