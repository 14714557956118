import { Routes } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';
import { AdminAuthGuardService } from './services/admin-auth-guard.service';

import { HomeComponent } from './components/home/home.component';
import { AccountComponent } from './components/account/account.component';
import { Error404Component } from './components/error-404/error-404.component';
import { DashboardsComponent } from './components/dashboards/dashboards.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SupportComponent } from './components/support/support.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminUserComponent } from './components/admin/user/user.component';

import { environment } from '../environments/environment';
import { Error401Component } from './components/error-401/error-401.component';

export const routes: Routes = [
    {
        path: '',
        title: environment.appName + ' // Home',
        component: HomeComponent,
    },
    {
        path: 'dashboards',
        title: environment.appName + ' // Dashboards',
        component: DashboardsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'dashboards/:id',
        title: environment.appName + ' // Dashboards',
        component: DashboardComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'support',
        title: environment.appName + ' // Support',
        component: SupportComponent,
        // canActivate: [AuthGuardService]
    },
    {
        path: 'account',
        title: environment.appName + ' // Account',
        component: AccountComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'privacy',
        title: environment.appName + ' // Privacy Policy',
        component: PrivacyComponent,
    },
    {
        path: 'admin',
        title: environment.appName + ' // Admin',
        component: AdminComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
    },
    {
        path: 'admin/user/:id',
        title: environment.appName + ' // Admin - User',
        component: AdminUserComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
    },
    {
        path: '401',
        title: environment.appName + ' // Error 401',
        component: Error401Component,
    },
    {
        path: '**',
        component: Error404Component
    }
];
