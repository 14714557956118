import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet, RouterLinkActive } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Dashboard } from '../../interfaces/dashboard';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import * as _ from 'lodash';
import { DashboardService } from '../../services/dashboard.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboards',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    NgxJsonViewerModule
  ],
  templateUrl: './dashboards.component.html',
  styleUrl: './dashboards.component.css'
})
export class DashboardsComponent implements OnInit {
  // Dependency Injection
  authService = inject(AuthService);
  dashboardService: DashboardService = inject(DashboardService);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  dashboards: Dashboard[] = [];
  loadingDashboards = false;

  
  ngOnInit(): void {
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get loaded dashboards list from dashboardService
        this.dashboards = this.dashboardService.dashboards;

        // Update dashboards list
        if(this.dashboardService.countDashboards() == 0) this.loadingDashboards = true;
        this.dashboardService.getDashboards()
        .then(dashboards => {
          console.log("dashboardService Get response", dashboards);
          // Update logo URLs
          _.forEach(dashboards, (dashboard) => {
            dashboard.logo = `${environment.publicAssetsBucketUrl}/images/${dashboard.account_id}-logo.png`;
          });
          this.loadingDashboards = false;   
          this.dashboards = dashboards;
        })
        .catch(error => {
          console.log("dashboardService Get error", error.message);
          this.loadingDashboards = false;
          this.toastrService.error(error.message, 'Dashboard error');        
        });

      }
    });
  }
}
