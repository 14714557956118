import { Component, OnInit, inject } from '@angular/core';
import { RouterLink, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { AdAccount } from '../../../interfaces/ad-account';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ad-account',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgbTooltipModule
  ],
  templateUrl: './ad-account.component.html',
  styleUrl: './ad-account.component.css'
})
export class AdAccountComponent implements OnInit {
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  apiService: ApiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  accountId: string = '';
  adAccount: AdAccount | null = null;

  adAccountLoading = true;
  adAccountLoaded = false;

  ngOnInit(): void {
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get Ad Account ID from URL params
        this.activatedRoute.params.subscribe((params: Params) => {
          this.accountId = params['id'];
          console.log(`Loading Ad Account#: ${this.accountId}`);
          // Check if the user has access to the Ad Accounts section & this specific ad account
          this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
            if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.accountId)) {
              // User does not have access to the account (or the user has no ad accounts at all)
              console.log("User does not have access to this ad account");
              this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
              this.router.navigate(['/']);
            } else {
              // User has access to the account
              console.log("User has access to this ad account");

              // Get ad account details from the server
              let requestPath = `/ad-accounts?get=${this.accountId}`;
              this.adAccountLoading = true;
              this.apiService.get(requestPath)
              .then(response => {
                console.log("[AdAccountComponent] Get response", response);
                this.adAccountLoading = false;
                this.adAccountLoaded = true;
                this.adAccount = {
                  id: this.accountId, 
                  name: response.data.name, 
                  logo: `${environment.publicAssetsBucketUrl}/images/${this.accountId}-logo.png`,
                  meta_id: response.data.meta_id,
                  gs_ads_library_id: response.data.gs_ads_library_id,
                  gs_ads_library_copy_url: response.data.gs_ads_library_copy_url,
                  gs_ads_library_creatives_url: response.data.gs_ads_library_creatives_url,
                  gd_creatives_folder_id: response.data.gd_creatives_folder_id,
                  dashboard_id: response.data.dashboard_id
                };
                if(!response.data.meta_id) {
                  this.toastrService.warning("Missing Meta ID");
                }
                if(!response.data.gs_ads_library_id) {
                  this.toastrService.warning("Missing Google Sheets Ads Library");
                }
                if(!response.data.gs_ads_library_copy_url) {
                  this.toastrService.warning("Missing Google Sheets Ads Library Copy URL");
                }
                if(!response.data.gs_ads_library_creatives_url) {
                  this.toastrService.warning("Missing Google Sheets Ads Library Creatives URL");
                }
                if(!response.data.gd_creatives_folder_id) {
                  this.toastrService.warning("Missing Google Drive Creatives Folder");
                }
              }).catch(error => {
                console.log("[AdAccountComponent] Get error", error);
                this.adAccountLoading = false;
                this.toastrService.error(error.message, 'Failed to load Ad Account');
              });


            }        
          });
        });
      }  
    });
  }

}
