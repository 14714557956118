import { Component, OnInit, inject, ElementRef, Renderer2 } from '@angular/core';
import { RouterLink, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { NamingConventionService } from '../../../services/naming-convention.service';
import { NamingConvention } from '../../../interfaces/naming-convention';
import { NamingConventionField } from '../../../interfaces/naming-convention';
import { AdAccount, Campaign, Adset, Ad } from '../../../interfaces/ad-account';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { regexPatterns } from '../regex-patterns';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

@Component({
  selector: 'app-naming-convention',
  standalone: true,
  imports: [    
    CommonModule,
    RouterLink,
    NgxJsonViewerModule
],
  templateUrl: './naming-convention.component.html',
  styleUrl: './naming-convention.component.css'
})
export class NamingConventionComponent implements OnInit {
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  apiService: ApiService = inject(ApiService);
  namingConventionService: NamingConventionService = inject(NamingConventionService);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  accountId: string = '';
  adAccount: AdAccount | null = null;

  namingConvention: NamingConvention = {
    id: this.accountId
  };

  namingConventionLoading = true;
  namingConventionLoaded = false;

  namingConventionLoadingError: string | null = null;

  regexPatterns: { [key: string]: RegExp } = regexPatterns;

  fields: { [key: string]: any } = {};


  ngOnInit(): void {
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get Ad Account ID from URL params
        this.activatedRoute.params.subscribe((params: Params) => {
          this.accountId = params['id'];
          console.log(`Loading Ad Account#: ${this.accountId}`);
          // Check if the user has access to the Ad Accounts section & this specific ad account
          this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
            if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.accountId)) {
              // User does not have access to the account (or the user has no ad accounts at all)
              console.log("User does not have access to this ad account");
              this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
              this.router.navigate(['/']);
            } else {
              // User has access to the account
              console.log("User has access to this ad account");

              // Load the Naming Convention for the Ad Account
              this.namingConventionService.setId(this.accountId);
              this.namingConvention.id = this.accountId;
              this.loadNamingConvention()
              .then(() => {
                // Naming Convention loaded successfully
                console.log("Naming Convention loaded successfully", this.namingConvention);

                
              })
              .catch((error) => {
                // Naming Convention failed to load (network error or no Naming Convention)
                console.error('Error fetching Naming Convention', error, this.namingConvention);
                this.namingConventionLoadingError = "No Naming Convention found for this Ad Account";
                
              });


            }        
          });
        });
      }  
    });
  } 


  loadNamingConvention(): Promise<NamingConvention> {
    // Load the Naming Convention for the Ad Account
    return new Promise<NamingConvention>((resolve, reject) => {
      console.log("["+this.constructor.name+"] Loading Naming Convention for Ad Account", this.namingConvention.id);
      this.namingConventionLoading = true;
      this.namingConventionLoaded = false;
      this.namingConventionService.get().then(response => {
        // console.log("["+this.constructor.name+"] Naming Convention retrieved", response);
        this.namingConvention = response;
        this.namingConvention.id = this.accountId; // Re-inject the account ID
        _.forEach(this.namingConvention.fields, (field: NamingConventionField) => {
          // console.log("["+this.constructor.name+"] Field", field.id, field.name, field.validation);
          this.fields[field.id] = {
            name: field.name,
            error: field.error,
            placeholder: field.placeholder,
            description: field.description
          };
          if(field.validation.regex) {
            // If field.validation.regex) starts with "regexPatterns.", replace it with the actual regex pattern from regexPatterns.ts
            if(field.validation.regex.startsWith('regexPatterns.')) {
              // Standard regex pattern from regexPatterns.ts 
              _.forEach(this.regexPatterns, (value, key) => {
                if(field.validation.regex === 'regexPatterns.' + key) {                        
                  this.fields[field.id].regex = new RegExp(value);
                }
              });
            } else {
              // Custom regex pattern
              this.fields[field.id].regex = new RegExp(field.validation.regex);
            }
          } 
          if(field.allowed_values) this.fields[field.id].allowed_values = field.allowed_values;
          if(field.allowed_values_title) this.fields[field.id].allowed_values_title = field.allowed_values_title;
        });
        console.log("["+this.constructor.name+"] Naming Convention loaded", this.namingConvention.version);
  
        this.namingConventionLoading = false;
        this.namingConventionLoaded = true;

        resolve(this.namingConvention);
        
      }).catch(error => {
        console.log("["+this.constructor.name+"] Get Naming Convention error", error.message);
        this.namingConventionLoading = false;
        this.namingConventionLoaded = false;
        reject(error.message);
      });
  
    });
  }

}
