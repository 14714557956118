import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // Dependency Injection
  private http: HttpClient = inject(HttpClient);

  constructor(
  ) { }

  async test() {
    return new Promise<any>((resolve, reject) => {
      let url = 'https://jsonplaceholder.typicode.com/users';
      console.log('[HttpService] Getting...', url);
      this.http.get(url)
      .subscribe({
        next: (data) => {
          console.log('[HttpService] Data received', JSON.stringify(data));
          resolve(data);
        },
        error: (error) => {
          console.error('[HttpService] Get failed', error);
          reject(error);
        }
      })
      
    });
  }

  async get(url: string, options?: {headers: {}}) {
    return new Promise<any>((resolve, reject) => {
      console.log('[HttpService] Getting...', url);

      // Headers
      let headers = new HttpHeaders();
      if(options && options['headers']) {
        _.forEach(options['headers'], (value, key) => {
          // console.log('Appending HTTP header', key, value);
          headers = headers.append(key, value);
        });
        console.log('[HttpService] Modified HttpHeaders', headers);
      }

      // Send GET request
      this.http.get(url, {...options, observe: 'body', responseType: 'json', headers })
      .subscribe({
        next: (response) => {
          // Received a successful response
          console.log('[HttpService] Data received', JSON.stringify(response));
          // Return response
          resolve(response);
        },
        error: (error) => {
          // Received an error response
          console.error('[HttpService] Get failed', error);
          let returnedError = {
            status: error.status,
            message: (error.error && error.error.error && error.error.error.message) ? error.error.error.message : error.message,
            id: (error.error && error.error.error && error.error.error.id) ? error.error.error.id : null,
            name: (error.error && error.error.error && error.error.error.name) ? error.error.error.name : null,
            originalError: error
          };
          reject(returnedError);
        }
      })
    })
  }

  
  async post(url: string, body: any, options?: {headers: {}}) {
    return new Promise<any>((resolve, reject) => {
      console.log('[HttpService] Posting...', url);
      // Headers
      let headers = new HttpHeaders();
      if(options && options['headers']) {
        _.forEach(options['headers'], (value, key) => {
          // console.log('Appending HTTP header', key, value);
          headers = headers.append(key, value);
        });
        console.log('[HttpService] Modified HttpHeaders', headers);
      }
      // Send POST request
      this.http.post(url, body, {...options, observe: 'body', responseType: 'json', headers })
      .subscribe({
        next: (response) => {
          // Received a successful response
          console.log('[HttpService] Data received', JSON.stringify(response));
          // Return response
          resolve(response);
        },
        error: (error) => {
          // Received an error response
          console.error('[HttpService] Post failed', error);
          let returnedError = {
            status: error.status,
            message: (error.error && error.error.error && error.error.error.message) ? error.error.error.message : error.message,
            id: (error.error && error.error.error && error.error.error.id) ? error.error.error.id : null,
            originalError: error
          };
          reject(returnedError);
        }
      })
    })
  }

  async put(url: string, body: any, options?: {headers: {}}) {
    return new Promise<any>((resolve, reject) => {
      console.log('[HttpService] Putting...', url);
      // Headers
      let headers = new HttpHeaders();
      if(options && options['headers']) {
        _.forEach(options['headers'], (value, key) => {
          // console.log('Appending HTTP header', key, value);
          headers = headers.append(key, value);
        });
        console.log('[HttpService] Modified HttpHeaders', headers);
      }
      // Send PUT request
      this.http.put(url, body, {...options, observe: 'body', responseType: 'json', headers })
      .subscribe({
        next: (response) => {
          // Received a successful response
          console.log('[HttpService] Data received', JSON.stringify(response));
          // Return response
          resolve(response);
        },
        error: (error) => {
          // Received an error response
          console.error('[HttpService] Put failed', error);
          let returnedError = {
            status: error.status,
            message: (error.error && error.error.error && error.error.error.message) ? error.error.error.message : error.message,
            id: (error.error && error.error.error && error.error.error.id) ? error.error.error.id : null,
            originalError: error
          };
          reject(returnedError);
        }
      })
    })
  }

}
