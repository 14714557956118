<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">
            <div *ngIf="authenticatedUser" class="row">
                <div class="col">
                    <h1>Welcome to Hubble</h1>

                    <div class="row g-0" style="margin-top: 20px;">
                        <div class="col">
                            <div class="d-flex flex-wrap">
                        
                                <div class="card m-2" *ngIf="authenticatedUser" style="width: 48%;">
                                    <div class="card-body">
                                        <h5 class="card-title">Dashboards</h5>
                                        <p class="card-text">Access your Meta Ads Dashboards</p>
                                        <a routerLink="/dashboards" class="btn btn-dark">View Dashboards</a>
                                    </div>
                                </div>
    
                                <div class="card m-2" *ngIf="authenticatedUser && authenticatedUser.manageAccounts" style="width: 48%;">
                                    <div class="card-body">
                                        <h5 class="card-title">Ad Accounts</h5>
                                        <p class="card-text">Manage your clients' Meta Ads accounts</p>
                                        <a routerLink="/ad-accounts" class="btn btn-dark">Manage Ad Accounts</a>
                                    </div>
                                </div>
    
                                <div class="card m-2" style="width: 48%;">
                                    <div class="card-body">
                                        <h5 class="card-title">Support</h5>
                                        <p class="card-text">Get support on Hubble</p>
                                        <a routerLink="/support" class="btn btn-dark">Get Support</a>
                                    </div>
                                </div>      
    
                                <div class="card m-2" *ngIf="authenticatedUser" style="width: 48%;">
                                    <div class="card-body">
                                        <h5 class="card-title">Account</h5>
                                        <p class="card-text">Manage your Hubble account</p>
                                        <a routerLink="/account" class="btn btn-dark">Manage Account</a>
                                    </div>
                                </div>      
    
                            </div>

                        </div>
                    </div>

                </div>            
            </div>
            <div *ngIf="!authenticatedUser" class="row justify-content-md-center">
                <div class="col"></div>
                <div class="col-8">
                    <h1 style="text-align: center; margin-top: 50px;">Welcome to Hubble</h1>
                    <div style="text-align: center; margin-top: 20px;">To get started,<br> sign in with your Google account</div>
                    <div style="text-align: center; margin-top: 30px;">
                        <button *ngIf="!signingIn" type="button" class="btn btn-primary" (click)="doSignIn()" style="width: 300px;">
                            <img src="assets/images/google.svg" style="width: 26px; height: 26px; border-radius: 4px; padding: 3px; margin-right: 20px; background-color: white;">
                            Sign in with Google
                        </button>
                        <img *ngIf="signingIn" src="{{ this.authService.LOADING_IMAGE_URL }}">
                    </div>
                </div>
                <div class="col"></div>
            </div>
        </div>
    </div>


</div>