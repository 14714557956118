<div class="container">

    <div class="row" style="margin: 30px;">

        <div class="row justify-content-md-center">
            <div class="col"></div>
            <div class="col-8">
                <h1 style="text-align: center; margin-top: 50px;">Welcome to Hubble!</h1>
                <div style="text-align: center; margin-top: 20px;">
                    <p>Your account is currently pending activation. Once activated, you'll have full access to your dashboards.</p>
                    <p>If you need any help in the meantime, feel free to visit our <a routerLink="/support">support page</a>.</p>
                    <p>We'll notify you as soon as your account is ready.</p>
                </div>
                <div style="text-align: center; margin-top: 30px;">
                    <a routerLink="/" type="button" class="btn btn-dark" style="width: 150px;">Home</a>
                </div>
            </div>
            <div class="col"></div>
        </div>

    </div>

</div>