import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive } from '@angular/router';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';

import { HttpService } from './services/http.service';
import { AuthService } from './services/auth.service';
import { DashboardService } from './services/dashboard.service';
import { SidebarService } from './services/sidebar.service';

import { environment } from '../environments/environment';
import { SplashScreenComponent } from './shared/splash-screen/splash-screen.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NavBarComponent,
    SplashScreenComponent
  ],
  providers: [
    HttpService
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {
  // Dependency Injection
  private authService = inject(AuthService);
  private dashboardService = inject(DashboardService);
  private sidebarService = inject(SidebarService);

  // User Authentication
  private authenticatedUserSubscription: Subscription | undefined;
  authenticatedUser = this.authService.authenticatedUser;
  
  title = 'hubble-genesis-frontend';

  sideBarHidden = false;
  sideBarHideButtonImageHide = '/assets/images/glyphicons/glyphicons-basic-223-chevron-left-white.svg';
  sideBarHideButtonImageShow = '/assets/images/glyphicons/glyphicons-basic-600-menu-white.svg';
  sideBarHideButtonImage = this.sideBarHideButtonImageHide;
  data: any;

  showAuthServiceInitialisingSplashScreen = true;
  authServiceInitialisingMessage = "...";

  constructor(
  ) {
    console.log('AppComponent initialisation');
  }

  ngOnInit(): void {
    console.log(`Initialising AppComponent [production = ${environment.production} | backendUrl = ${environment.backendUrl}]`);

    // Show Splash Screen while FirebaseAuthService is initialising
    let initialisationMessages = [
      "T-minus 10... Powering up performance insights!",
      "Preparing for launch... Fasten your seatbelts for top performance!",
      "Engaging thrusters... Your insights will be light-years ahead!",
      "Clearing the launch pad... Optimising data for liftoff!",
      "Fueling the rockets... Performance boost in 3, 2, 1!"
    ];
    this.authServiceInitialisingMessage = initialisationMessages[Math.floor(Math.random() * initialisationMessages.length)];
    this.showAuthServiceInitialisingSplashScreen = true;

    // User Authentication
    // Subscribe to AuthService's authenticatedUserSubject (event listener) to get notified when Firebase Authentication state changes (user signs in/signs out or idToken is refreshed)
    this.authenticatedUserSubscription = this.authService.authenticatedUserSubject.subscribe(authenticateUser => {
      console.log("AppComponent notified by authenticatedUserSubscription", this.authService.authenticatedUser);
      if(authenticateUser) {
        this.authenticatedUser = authenticateUser;          
        // Get user info from backend
        this.authService.getUserInfo().then((userInfo) => {
          this.showAuthServiceInitialisingSplashScreen = false;
        }).catch((error) => {
          this.showAuthServiceInitialisingSplashScreen = false;
        });
        // Get list of user dashboards
        if(this.dashboardService.loadingDashboards === false && this.dashboardService.countDashboards() == 0) {
          // console.log("[AppComponent] Retrieving dashboards");
          this.dashboardService.getDashboards().then((dashboards) => {
            console.log("[AppComponent] Retrieved dashboards", dashboards.length);
          });
        }
      }  
      else {
        this.authenticatedUser = null;
        this.showAuthServiceInitialisingSplashScreen = false;
      }  
    });   
    
    // Subscribe to SidebarService's toggleSidebarSubject (event listener) to get notified when sidebar is toggled
    this.sidebarService.toggleSidebar$.subscribe(() => {
      this.onToggleSideBar();
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from authenticatedUserSubscription() Subject (event listener)
    this.authenticatedUserSubscription?.unsubscribe();
  }

  onToggleSideBar() {
    this.sideBarHidden = !this.sideBarHidden;
    this.sideBarHideButtonImage = this.sideBarHidden ? this.sideBarHideButtonImageShow : this.sideBarHideButtonImageHide;
  }
}
