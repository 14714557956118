import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpService } from './http.service';
import { ApiService } from './api.service';
import * as _ from 'lodash';
import { CreativeAnalysisImage, CreativeAnalysisVideo } from '../interfaces/creative-analysis';

@Injectable({
  providedIn: 'root'
})
export class CreativeAnalysisService {
    // Dependency Injection
    private apiService: ApiService = inject(ApiService);
    private httpService: HttpService = inject(HttpService);

    s3UploadUrl: string | null = null;

    // Track if the service is currently loading the s3UploadUrl to prevent firing multiple concurrent calls to the API
    loadingS3UploadUrl = false;

    constructor() { 
        console.log("[CreativeService] Initialising CreativeService");
    }

    // List all creatives
    async listCreatives(acc_id: string, type: string) {
        return new Promise<any>((resolve, reject) => {
            console.log("[CreativeService] listCreatives()");
            let requestPath = `/creative-analysis/list-creatives?acc_id=${acc_id}&type=${type}&limit=40`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] List response", response);
                resolve(response.data);
            })
            .catch(error => {
                console.log("[CreativeService] List error", error);
                reject(error.name);
            });
        });
    }

    // Get creative data
    // get-creative?acc_id=life-cykel-aus&id=IMG166
    async getCreative(acc_id: string, id: string) {
        return new Promise<any>((resolve, reject) => {
            console.log("[CreativeService] getCreative()");
            let requestPath = `/creative-analysis/get-creative?acc_id=${acc_id}&id=${id}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] getCreative response", response);
                resolve(response.data);
            })
            .catch(error => {
                console.log("[CreativeService] getCreative error", error);
                reject(error.name);
            });
        });
    }

    // Get creatives by ids
    // get-creatives?acc_id=life-cykel-aus&ids=[IMG166]
    async getCreatives(acc_id: string, ids: string[]) {
        return new Promise<any>((resolve, reject) => {
            console.log("[CreativeService] getCreatives()");
            let requestPath = `/creative-analysis/get-creatives?acc_id=${acc_id}&ids=${ids}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] getCreatives response", response);
                resolve(response.data);
            })
            .catch(error => {
                console.log("[CreativeService] getCreatives error", error);
                reject(error.name);
            });
        });
    }
    
    // Get signed URL for uploading a file to S3 temporary user storage
    async getS3UploadUrl(file: string, type: string) {
        return new Promise<any>((resolve, reject) => {
            console.log("[CreativeService] get()");
            this.loadingS3UploadUrl = true;      
            let requestPath = `/creative-analysis/s3-uploadurl?file=${file}&type=${type}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] Get response", response);   
                this.loadingS3UploadUrl = false;
                this.s3UploadUrl = response.data;
                resolve(this.s3UploadUrl!);
            })
            .catch(error => {
                console.log("[CreativeService] Get error", error.message);
                this.loadingS3UploadUrl = false;
                reject(error.message);
            });
        });
    }

    // Upload file to S3 user temporary storage using the signed URL
    async uploadFileToS3(signedUrl: string, file: File) {
        return new Promise<void>((resolve, reject) => {
            console.log("[CreativeService] uploadFileToS3()", signedUrl, file);
            const headers = {
                'Content-Type': file.type
            };
            this.httpService.put(signedUrl, file, { headers })
            .then(response => {
                console.log("[CreativeService] Upload response", response);
                resolve();
            })
            .catch(error => {
                console.log("[CreativeService] Upload error", error.message);
                reject(error.message);
            });
        });
    }

    // Import the creative from S3 user temporary storage to Firestore & S3 permanent creative analysis storage
    async importCreative(acc_id: string, creative: string, file: string, temp_file: string) {
        return new Promise<{url: string, thumbnail: string}>((resolve, reject) => {
            console.log("[CreativeService] importCreative()", acc_id, creative, file, temp_file);
            // Convert the file name to creative + file extension (IMG001.jpg, IMG002.png...)
            let fileParts = file.split('.');
            file = creative + '.' + fileParts[fileParts.length - 1];
            console.log("[CreativeService] Calling s3-import", acc_id, creative, file, temp_file);
            let requestPath = `/creative-analysis/s3-import?acc_id=${acc_id}&creative=${creative}&file=${file}&temp_file=${temp_file}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] Import response", response);
                let url = response.data?.url;
                let thumbnail = response.data?.thumbnail;
                resolve({
                    url: url,
                    thumbnail: thumbnail
                });
            })
            .catch(error => {
                console.log("[CreativeService] Import error", error.message);
                reject(error.message);
            });
        });
    }

    // Request AI analysis of the creative
    async requestAnalysis(acc_id: string, creative: string, model: string = 'gemini') {
        return new Promise<any>((resolve, reject) => {
            console.log("[CreativeService] requestAnalysis()");
            let requestPath = `/creative-analysis/request-analysis?acc_id=${acc_id}&id=${creative}&model=${model}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] Analysis response", response);
                resolve({
                    status: response.data?.status,
                    job: response.data?.job?.id
                });
            })
            .catch(error => {
                console.log("[CreativeService] Analysis error", error.message);
                reject(error.message);
            });
        });
    }

    // Request AI analysis of the video creative
    async requestVideoAnalysis(acc_id: string, creative: string) {
        return new Promise<any>((resolve, reject) => {
            console.log("[CreativeService] requestVideoAnalysis()");
            let requestPath = `/creative-analysis/request-analysis?acc_id=${acc_id}&id=${creative}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] Analysis response", response);
                resolve({
                    status: response.data?.status,
                    job: response.data?.job?.id
                });
            })
            .catch(error => {
                console.log("[CreativeService] Analysis error", error.message);
                reject(error.message);
            });
        });
    }

    // Check job status
    async checkJobStatus(acc_id: string, creative: string) {
        return new Promise<any>((resolve, reject) => {
            console.log("[CreativeService] checkJobStatus()");
            let requestPath = `/creative-analysis/check-analysis-job?acc_id=${acc_id}&id=${creative}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] Job response", response);
                let job = {
                    status: response.data?.status,
                    job: response.data?.id,
                    error: response.data?.error,
                    thumbnail: response.data?.thumbnail,
                    url: response.data?.url
                };
                resolve(job);
            })
            .catch(error => {
                console.log("[CreativeService] Job error", error.message);
                reject(error.message);
            });
        });
    }

    // Get the creative analysis results for an image
    async getCreativeAnalysisImage(acc_id: string, creative: string) {
        return new Promise<CreativeAnalysisImage>((resolve, reject) => {
            console.log("[CreativeService] getImageAnalysis()");
            let requestPath = `/creative-analysis/get-analysis?acc_id=${acc_id}&id=${creative}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] Analysis response", response);
                const formattedUserPrompt = this.formatNewLinesWithHtmlBreaks(response.data?.classification?.prompt?.user);
                let creativeAnalysis: CreativeAnalysisImage = {
                    id: response.data?.id,
                    thumbnail: response.data?.thumbnail,
                    url: response.data?.url,
                    status: response.data?.status,
                    classified: response.data?.classified,
                    classificationId: response.data?.classification?.id,
                    created: response.data?.classification?.created,
                    callToAction: response.data?.classification?.call_to_action,
                    creativeStyle: response.data?.classification?.creative_style,
                    creativeStyleReasons: response.data?.classification?.meta_reasons?.creative_style?.reasons,
                    creativeStyleAlternatives: response.data?.classification?.meta_reasons?.creative_style?.alternatives,
                    discount: response.data?.classification?.discounts?.discount,
                    discountCode: response.data?.classification?.discounts?.discount_code,
                    headline: response.data?.classification?.headline,
                    mainAngle: response.data?.classification?.main_angle,
                    mainAngleReasons: response.data?.classification?.meta_reasons?.main_angle?.reasons,
                    mainAngleAlternatives: response.data?.classification?.meta_reasons?.main_angle?.alternatives,
                    marketAwareness: response.data?.classification?.market_awareness,
                    marketAwarenessReasons: response.data?.classification?.meta_reasons?.market_awareness?.reasons,
                    model: response.data?.classification?.model,
                    personFeatureVisible: response.data?.classification?.person_feature?.visible,
                    personFeatureDescription: response.data?.classification?.person_feature?.description,
                    targetMarketAgeCategory: response.data?.classification?.target_market.age_category,
                    targetMarketGender: response.data?.classification?.target_market.gender,
                    targetMarketDescription: response.data?.classification?.target_market.description,
                    targetMarketReasons: response.data?.classification?.meta_reasons?.target_market?.reasons,
                    detectedTexts: response.data?.classification?.meta_frames_texts,
                    imageDescription: response.data?.classification?.meta_image_description,
                    reasons: response.data?.classification?.meta_reasons,
                    usageTokensTotal: response.data?.classification?.usage?.total_tokens,
                    prompt: {
                        system: response.data?.classification?.prompt?.system ? this.formatNewLinesWithHtmlBreaks(response.data?.classification?.prompt?.system) : '',
                        user: formattedUserPrompt
                    },
                    executionTime: {
                        model: response.data?.classification?.execution_time?.model,
                    },
                    rawOutput: response.data?.classification
                };
                resolve(creativeAnalysis);
            })
            .catch(error => {
                console.log("[CreativeService] Analysis error", error);
                reject(error.message);
            });
        });
    }
    
    // Get the creative analysis results for a video
    async getCreativeAnalysisVideo(acc_id: string, creative: string) {
        return new Promise<CreativeAnalysisVideo>((resolve, reject) => {
            console.log("[CreativeService] getCreativeAnalysisVideo()");
            let requestPath = `/creative-analysis/get-analysis?acc_id=${acc_id}&id=${creative}`;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[CreativeService] Analysis response", JSON.stringify(response));
                let prompts = [];
                if(response.data?.classification?.prompts) {
                    prompts = response.data?.classification?.prompts.map((prompt: any) => {
                        return {
                            type: prompt.type,
                            system: prompt.system ? this.formatNewLinesWithHtmlBreaks(prompt.system) : '',
                            user: prompt.user ? this.formatNewLinesWithHtmlBreaks(prompt.user) : ''
                        }
                    });
                }
                if(response.data?.prompt) {
                    prompts.push({
                        type: response.data?.prompt.type,
                        system: response.data?.prompt.system ? this.formatNewLinesWithHtmlBreaks(response.data?.prompt.system) : '',
                        user: response.data?.prompt.user ? this.formatNewLinesWithHtmlBreaks(response.data?.prompt.user) : ''
                    });
                }
                let creativeAnalysis: CreativeAnalysisVideo = {
                    id: response.data?.id,
                    thumbnail: response.data?.thumbnail,
                    url: response.data?.url,
                    status: response.data?.status,
                    classified: response.data?.classified,
                    classificationId: response.data?.classification?.id,
                    created: response.data?.classification?.created,
                    creativeStyle: response.data?.classification?.creative_style,
                    discount: response.data?.classification?.discounts?.discount,
                    discountCode: response.data?.classification?.discounts?.discount_code,
                    visualHook: response.data?.classification?.visual_hook,
                    mainAngle: response.data?.classification?.main_angle,
                    marketAwareness: response.data?.classification?.market_awareness,
                    model: response.data?.classification?.model,
                    ageGroup: response.data?.classification?.age_group,
                    gender: response.data?.classification?.gender,
                    length: response.data?.classification?.length,
                    visualEndFrame: response.data?.classification?.visual_end_frame,
                    audioHook: response.data?.classification?.audio_hook,
                    dialogueCTA: response.data?.classification?.dialogue_call_to_action,
                    targetMarketAgeCategory: response.data?.classification?.target_market.age_category,
                    targetMarketGender: response.data?.classification?.target_market.gender,
                    targetMarketDescription: response.data?.classification?.target_market.description,
                    transcript: response.data?.classification?.transcript,
                    detectedTexts: response.data?.classification?.meta_frames_texts,
                    videoDescription: response.data?.classification?.meta_video_description,
                    usageTokensTotal: response.data?.classification?.usage?.total_tokens,
                    prompts: prompts,
                    executionTime: {
                        ffmpeg: response.data?.classification?.execution_time?.ffmpeg,
                        model: response.data?.classification?.execution_time?.model,
                    },
                    rawOutput: response.data?.classification
                };
                resolve(creativeAnalysis);
            })
            .catch(error => {
                console.log("[CreativeService] Analysis error", error);
                reject(error.message);
            });
        });
    }

    // Format new lines with HTML breaks. This is used to format the prompt text in the UI
    formatNewLinesWithHtmlBreaks(text: string): string {
        console.log("[CreativeService] formatNewLinesWithHtmlBreaks()", text);
        if(!text || typeof text !== 'string') {
            return '';
        } else {
            return text.replace(/\\n/g, '<br>');
        }
        
    }
}