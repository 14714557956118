<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ accountId }}">{{ accountId | titlecase }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Creative Uploader</li>
                </ol>
            </nav>

            <h2 style="margin: 50px 0 20px 0;">1 - Select a Creative Type</h2>
            <div>
                <button (click)="onCreativeTypeChange('IMG')" [disabled]="creativeType == 'IMG'" class="btn" [class.btn-creative-type-selected]="creativeType == 'IMG'" [class.btn-secondary]="creativeType != 'IMG'" style="margin-right: 20px;">IMG</button>
                <button (click)="onCreativeTypeChange('VID')" [disabled]="creativeType == 'VID'" class="btn" [class.btn-creative-type-selected]="creativeType == 'VID'" [class.btn-secondary]="creativeType != 'VID'" style="margin-right: 20px;">VID</button>
                <button (click)="onCreativeTypeChange('CAR')" [disabled]="creativeType == 'CAR'" class="btn" [class.btn-creative-type-selected]="creativeType == 'CAR'" [class.btn-secondary]="creativeType != 'CAR'">CAR</button>
            </div>

            <h2 style="margin: 50px 0 20px 0;">2 - Select a Creative ID</h2>
            <div class="row align-items-center" style="margin-bottom: 20px;">
                <div class="col-auto" style="width: 150px; font-weight: bold;">Client Assets</div>
                <div class="col-auto">                    
                    <span *ngIf="loadindAdAccount" style="color: grey;">
                        <span class="spinner-border spinner-border-sm text-dark" role="status" style="margin-right: 5px;"><span class="visually-hidden">Loading...</span></span>
                        Loading Ads Library Matrix<span class="ellipsisAnimated"></span>
                    </span>
                    <a *ngIf="!loadindAdAccount && adAccount?.gs_ads_library_id" class="btn btn-sm btn-secondary" [ngbTooltip]="'Open Ads Library Matrix'" [href]="'https://docs.google.com/spreadsheets/d/'+adAccount?.gs_ads_library_id" target="_blank" style="margin-right: 15px;"><i class="bi bi-table" style="margin-right: 5px;"></i>Ads Library Matrix</a>
                    <a *ngIf="!loadindAdAccount && adAccount?.gd_creatives_folder_id" class="btn btn-sm btn-secondary" [ngbTooltip]="'Open Google Drive folder'" [href]="'https://drive.google.com/drive/folders/'+(adAccount?.gd_creatives_folder_id != '' ? adAccount?.gd_creatives_folder_id : gdCreativeFolderId)" target="_blank" style="margin-right: 15px;"><i class="bi bi-google" style="margin-right: 5px;"></i>Google Drive Folder</a>
                    <a *ngIf="!loadindAdAccount && adAccount?.meta_id" class="btn btn-sm btn-secondary" [ngbTooltip]="'Open Meta Media Library Folder'" href="https://business.facebook.com/asset_library/ad_accounts?ad_account={{ adAccount?.meta_id }}" target="_blank"><i class="bi bi-facebook" style="margin-right: 5px;"></i>Meta Media Library</a>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto" style="width: 150px; font-weight: bold;">Creative ID</div>
                <div class="col-auto">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <input type="text" 
                                #creativeIdInput="ngModel"
                                [(ngModel)]="creativeId" 
                                (input)="onCreativeIdChange()"
                                (blur)="onCreativeIdBlur()"
                                class="form-control" 
                                [placeholder]="creativeType + 'XXX'" 
                                aria-label="Creative ID" 
                                [pattern]="'^('+creativeType+')[0-9]{3}$'" 
                                style="max-width: 120px;"
                                required>

                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-dark" 
                                    style="max-width: 200px;"
                                    type="button" 
                                    [disabled]="creativeIdLoading || !(adAccount?.gs_ads_library_id)"
                                    (click)="getNewCreativeId()">Generate New ID</button>
                        </div>
                    </div>
                </div>
                <div class="col">                    
                    <div class="progress" *ngIf="creativeIdLoading" style="max-width: 150px;">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style.width]="creativeIdLoadingProgress + '%'"></div>
                    </div>
                    <div *ngIf="creativeIdInput.invalid && (creativeIdInput.dirty || creativeIdInput.touched)" class="text-danger" style="font-weight: bold;">
                        <small *ngIf="creativeIdInput.errors?.['required']">Creative ID is required.</small>
                        <small *ngIf="creativeIdInput.errors?.['pattern']">Creative ID must be in the format {{ creativeType }}XXX where XXX are digits.</small>
                    </div>
                </div>                
            </div>                      

            <h2 style="margin: 50px 0 20px 0;">3 - Upload the Creative Files</h2>

            <div *ngFor="let file of creativeFiles;" class="list-group" style="margin-top: 30px;">
                <div class="list-group-item list-group-item" style="background-color: black;">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <h3 style="font-size: 1.5rem; margin-bottom: 0; color: white;">{{ file.id.toUpperCase() }}</h3>
                    </div>
                  </div>
                </div>
                <div class="list-group-item list-group-item">
                    <div class="row gy-2 gx-3 align-items-center" style="max-height: 200px; margin: 10px 0 25px 0;">
                        <div class="col-5">
                            <div>
                                <app-file-upload 
                                    [uploaderId]="file.id!"
                                    (fileReady)="onFileReady($event)" 
                                    (fileError)="onFileError($event)" 
                                    #fileUpload />     
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="row" style="margin-left: 0;">
                                <div class="input-group" style="margin-bottom: 25px;">
                                    <span class="input-group-text" (click)="triggerFileInputClick()" style="cursor: pointer;" [ngbTooltip]="'Select a file'"><i class="bi bi-hdd"></i></span>
                                    <input type="text" class="form-control" aria-label="Local file" disabled [value]="(file.id && file.selected_file) ? file.selected_file.name : ''" >
                                </div>
                                <div class="input-group" style="margin-bottom: 25px;">
                                    <span class="input-group-text"[ngbTooltip]="'File name once uploaded'"><i class="bi bi-file-earmark-arrow-up"></i></span>
                                    <input type="text" class="form-control" aria-label="Upload name" [(ngModel)]="file.name">
                                </div>
                                <div class="row g-0 align-items-center">
                                    <div class="col-auto">
                                        <button (click)="startUpload(file.id || '')" [disabled]="file.status == 'uploaded' || file.status == 'uploading' || !file.selected_file || !creativeId" class="btn btn-sm btn-dark" style="margin-left: 12px">
                                            {{ file.status == 'uploading' ? 'Uploading...' : (file.status == 'uploaded' ? 'Uploaded' : 'Upload') }}
                                        </button>
                                    </div>
                                    <div class="col" style="padding: 0 25px;">
                                        <div class="progress" *ngIf="file.status == 'uploading'" style="max-width: 150px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style.width]="file.upload_progress + '%'"></div>
                                        </div>
                                    </div>
                                    <div class="col-auto" style="text-align: right;">
                                        <a *ngIf="adAccount?.gd_creatives_folder_id" class="btn btn-{{ file.uploaded.gdrive !== null ? 'success' : 'light' }}" [ngbTooltip]="'Open Google Drive Folder'" [href]="'https://drive.google.com/drive/folders/'+gdCreativeFolderId" target="_blank" style="margin-right: 15px;"><i class="bi bi-google"></i></a>
                                        <a *ngIf="adAccount?.gs_ads_library_id" class="btn btn-{{ file.ads_library_updated ? 'success' : 'light' }}" [ngbTooltip]="'Open Ads Library Matrix'" [href]="'https://docs.google.com/spreadsheets/d/'+adAccount?.gs_ads_library_id" target="_blank" style="margin-right: 15px;"><i class="bi bi-table"></i></a>
                                        <a *ngIf="adAccount?.meta_id" class="btn btn-{{ file.uploaded.meta ? 'success' : 'light' }}" [ngbTooltip]="'Open Meta Media Library Folder'" href="https://business.facebook.com/asset_library/ad_accounts?ad_account={{ adAccount?.meta_id }}" target="_blank" style="margin-right: 12px;"><i class="bi bi-facebook"></i></a>
                                    </div>   
                                </div>
                             </div>    
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin: 30px 0;" class="row">
                <div class="col"><button *ngIf="creativeType == 'CAR' && creativeFiles.length < 20" class="btn btn-sm btn-dark" (click)="addCreativeFile()">Add a Creative File</button></div>
                <div class="col" style="text-align: right;"><button class="btn btn-sm btn-dark" (click)="creativeId = ''; resetForm()">Reset</button></div>                
            </div>
            
        </div>    
    </div>
</div>