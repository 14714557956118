<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">

            <h1>Account</h1>
            <p style="margin-bottom: 40px;">Manage your Hubble account</p>
            
            <h2>Your Hubble Account</h2>
            <div class="col-md-6 list-group">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-3" style="font-weight: bold;">Name</div>
                        <div class="col-9">{{ authenticatedUser?.name }}</div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-3" style="font-weight: bold;">Email</div>
                        <div class="col-9">{{ authenticatedUser?.email }}</div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-3" style="font-weight: bold;">Password</div>
                        <div class="col-9"><i>Manage in your <a href="https://myaccount.google.com/signinoptions/password" target="_blank">Google account</a></i></div>
                    </div>
                </div>
                <div *ngIf="authenticatedUser?.email == 'matt.f@webprofits.com.au'" class="list-group-item">
                    <div class="row">
                        <div class="col-3" style="font-weight: bold;">
                            Id Token
                        </div>
                        <div class="col-9" style="font-size: 60%; color: #e83e8c; word-break: break-word; cursor: pointer;" (click)="copyToClipboard(authToken || 'N/A')" [title]="authToken">
                            {{ authToken ? (authToken | shorten: 300) : '-' }}
                        </div>
                    </div>
                </div>
            </div>

            <h2 style="margin-top: 40px;">Connect Your Facebook Account</h2>
            <p>To access and analyse your Meta Ads data, simply connect your business Facebook account. Click the button below to log in to Facebook and grant Hubble permission to retrieve your ad account information.</p>
            <div class="col-md-6 list-group">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-5" style="font-weight: bold;">Connection status</div>
                        <div class="col-7">
                            <span *ngIf="fbConnectionStatusLoading" class="spinner-border  spinner-border-sm text-primary" role="status" aria-hidden="true" style="margin: 5px 0;"></span>
                            <span *ngIf="!fbConnectionStatusLoading" placement="top" ngbTooltip="{{ fbConnectionData.name + ' (' + fbConnectionData.created + ')' }}" [class.text-success]="fbConnectionStatus" [class.text-danger]="!fbConnectionStatus">{{ fbConnectionStatus ? 'Connected' : 'Not connected' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <a href="{{ this.fbLoginUrl }}" class="btn btn-primary" style="margin-top: 15px;">Login with Facebook</a>
            

            <div style="margin-top: 50px;">
                <button *ngIf="!signingOut" type="button" class="btn btn-dark" (click)="doSignOut()">
                    Sign out
                </button>
                <img *ngIf="signingOut" src="{{ this.authService.LOADING_IMAGE_URL }}" style="margin-top: 10px;">
            </div>

        </div>    

    </div>

</div>