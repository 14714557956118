import { Component, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { User } from '../../interfaces/user';
import { Dashboard, DashboardMetrics } from '../../interfaces/dashboard';
import * as _ from 'lodash';
import moment from 'moment';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AdAccount } from '../../interfaces/ad-account';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ad-accounts',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgbTooltip
    // RouterOutlet,
    // RouterLinkActive,
  ],
  templateUrl: './ad-accounts.component.html',
  styleUrl: './ad-accounts.component.css'
})
export class AdAccountsComponent implements OnInit {
  // Dependency Injection  
  router: Router = inject(Router);
  authService = inject(AuthService);
  apiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  loadingAdAccounts = false;
  adAccounts: AdAccount[] = [];


  ngOnInit(): void {
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Check if the user has access to the Ad Accounts section
        // To speed up page display, get pre-loaded ad accounts list from authenticatedUser, and then update it with the latest list from the server
        if(this.authService.authenticatedUser && this.authService.authenticatedUser.manageAccounts) {
          _.forEach(this.authService.authenticatedUser.manageAccounts, (accountId: string) => {
            this.adAccounts.push({id: accountId, name: accountId, logo: `${environment.publicAssetsBucketUrl}/images/${accountId}-logo.png`});
          });
        } else {
          this.loadingAdAccounts = true;
        }
        this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
          if(adAccounts === null) {
            // User does not have access to the Ad Accounts section (has no no ad accounts)
            console.log("User does not have access ad accounts");
            this.toastrService.error("You do not have access to this section", "Access Denied");
            this.router.navigate(['/']);
          } else {
            // User has access to the Ad Accounts section
            console.log("User has access to the Ad Accounts section");

            let requestPath = '/ad-accounts?list=all';
            this.apiService.get(requestPath)
            .then(response => {
              console.log("[AdAccountsComponent] Get response", response);   
              this.loadingAdAccounts = false;
              this.adAccounts = [];
              _.forEach(response.data, (adAccount) => {
                let thisAdAccount: AdAccount = {
                  id: adAccount.id,
                  name: adAccount.name,
                  logo: `${environment.publicAssetsBucketUrl}/images/${adAccount.id}-logo.png`
                };
                this.adAccounts.push(thisAdAccount);
              });
            }).catch(error => {
              console.log("[AdAccountsComponent] Get error", error);
              this.loadingAdAccounts = false;
            });





          }        
        });
      }
    });
  }

}
