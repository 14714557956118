<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">

            <div class="row">
                <div class="col">
                    <h1>Admin - User</h1>
                    <p style="margin-bottom: 40px;"></p>    
                </div>
                <div class="col" style="text-align: right;">
                    <div *ngIf="loadingUser" class="spinner-border text-primary" role="status" style="margin-top: 10px;">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>            

            <table *ngIf="user.email != ''" class="table">
                <tbody>
                    <tr>
                        <td>id</td>
                        <td><span class="badge bg-secondary">{{ user.id }}</span></td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ user.email }}</td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;">Is Active</td>
                        <td>
                            <div class="row">
                                <div class="col">
                                    <div *ngIf="!updatingUser['isActive'].showForm" class="row row-cols-auto align-items-center">
                                        <div class="col">
                                            <svg *ngIf="user?.isActive" style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/></svg>
                                            <svg *ngIf="!user?.isActive" style="color: red;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/></svg>       
                                        </div>
                                        <div class="col">
                                            <button type="button" class="btn btn-sm btn-danger" (click)="updatingUser['isActive'].showForm = true">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggles" viewBox="0 0 16 16">
                                                    <path d="M4.5 9a3.5 3.5 0 1 0 0 7h7a3.5 3.5 0 1 0 0-7zm7 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m-7-14a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m2.45 0A3.5 3.5 0 0 1 8 3.5 3.5 3.5 0 0 1 6.95 6h4.55a2.5 2.5 0 0 0 0-5zM4.5 0h7a3.5 3.5 0 1 1 0 7h-7a3.5 3.5 0 1 1 0-7"/>
                                                  </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="updatingUser['isActive'].showForm && user" class="row row-cols-auto">
                                        <div class="col">
                                            <select class="form-select form-select-sm" [(ngModel)]="user!.isActive" name="isActive" id="isActive" style="width: 100px;">
                                                <option [value]="true">Active</option>
                                                <option [value]="false">Inactive</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <button type="submit" (click)="updateUser('isActive')" [disabled]="updatingUser['isActive'].posting" class="btn btn-sm btn-dark">Save</button>
                                            
                                        </div>                                                             
                                    </div>                                    
                                </div>
                                <div class="col" style="text-align: right;">
                                    <button *ngIf="!sendingActivationNotification" class="btn btn-sm btn-danger" (click)="confirmAndNotifyActivation()">
                                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="16" height="16" fill="currentColor" class="bi bi-envelope-arrow-up" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4.5a.5.5 0 0 1-1 0V5.383l-7 4.2-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h5.5a.5.5 0 0 1 0 1H2a2 2 0 0 1-2-1.99zm1 7.105 4.708-2.897L1 5.383zM1 4v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1"/>
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.354 1.25 1.25a.5.5 0 0 1-.708.708L13 12.207V14a.5.5 0 0 1-1 0v-1.717l-.28.305a.5.5 0 0 1-.737-.676l1.149-1.25a.5.5 0 0 1 .722-.016"/>
                                        </svg>
                                        Notify user
                                    </button>
                                    <div *ngIf="sendingActivationNotification" class="spinner-border text-primary" role="status" style="margin-left: 10px;">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td>Dashboards</td>
                        <td>
                            <table class="table align-middle table-borderless">
                                <tbody>
                                    <tr>
                                        <td *ngIf="loadingDashboards" colspan="2" style="text-align: center;">
                                            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let dashboard of dashboards">
                                        <td style="min-width: 30px;">
                                        <input 
                                            type="checkbox" 
                                            [checked]="isDashboardChecked(dashboard.id)" 
                                            (change)="onDashboardChange(dashboard.id, $event)"
                                            [disabled]="updatingDashboards[dashboard.id]"
                                        />
                                        <span *ngIf="updatingDashboards[dashboard.id]" class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" style="margin-left: 5px;"></span>
                                        </td>
                                        <td>{{ dashboard.name }}<span style="margin-left: 10px;" class="badge rounded-pill bg-light text-dark">{{ dashboard.id }}</span></td>
                                    </tr>
                                </tbody>
                              </table>
                        </td>
                    </tr>
                    <tr>
                        <td>Logs</td>
                        <td>
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td *ngIf="loadingUserActionLogs" colspan="2" style="text-align: center;">
                                            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let log of userActionLogs">
                                        <td style="min-width: 30px;">{{ log.timestamp }}</td>
                                        <td>{{ log.type }}</td>
                                        <td>{{ log.parameters }}</td>
                                        <td>{{ log.ip }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>    

    </div>

</div>