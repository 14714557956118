//
// WARNING: Any changes to this file has to be reflected in the BACKEND as well (meta.js)
//
export const regexPatterns = {
    date: /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/, // Date in format YYYYMMDD
    positiveInteger: /^[1-9]\d*$/, // Positive integers, excluding 0
    uid: /^[A-Za-z0-9\-+&$]{1,20}$/, // Unique ID alphanumeric, min 1 character, max 20 characters
    free_text: /^[A-Za-z0-9\-+&$]{0,20}$/, // free_text: Alphanumeric, max 20 characters
    audience_type: /^(BROAD|MULTIPLELISTS|WARM|INT(\d{3}|XXX)|LAL(\d{3}|XXX)|CUS(\d{3}|XXX)|REM(\d{3}|XXX)|XXX)$/, // audience_type
    exclusion_type: /^EXL(\d{3}|XXX)$/, // exclusion_type: EXLXXX or XXX    
    test_id: /^([A-Z]{3}|XXX)TST(\d{3}|XXX)$/, // test_id: 3 letters or XXX + TST + 3 digits or XXX (i.e. XXXTSTXXX)
    creative: /^(IMG|VID|CAR)(\d{3}|XXX)$/, // creative: Only IMG, VID or CAR + 3 digits or XXX (i.e. IMG001)
    headline: /^HDL(\d{3}|XXX)$/, // headline: HDL + 3 digits or XXX (i.e. HDL001)
    primary_text: /^PRT(\d{3}|XXX)$/, // primary_text: PRT + 3 digits or XXX (i.e. PRT001)
    description: /^DES(\d{3}|XXX)$/, // description: DES + 3 digits or XXX (i.e. DES001)
    creative_url: /^URL(\d{3}|XXX)$/, // creative_url: URL + 3 digits or XXX (i.e. URL001)
    post_id: /^POSTID(\d{5,}|XXX)$/, // post_id: POSTID + at least 5 digits or XXX (i.e. POSTID123456789)
};