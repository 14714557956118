import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { RouterLink, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { CreativeAnalysisService } from '../../../services/creative-analysis.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CreativeAnalysisImage, CreativeAnalysisVideo } from '../../../interfaces/creative-analysis';
import { environment } from '../../../../environments/environment';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { Subscription, interval } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-creative-analysis-single',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    NgbTooltipModule,
    NgxJsonViewerModule
  ],
  templateUrl: './creative-analysis-single.component.html',
  styleUrl: './creative-analysis-single.component.css'
})
export class CreativeAnalysisSingleComponent implements OnInit {
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  creativeAnalysisService: CreativeAnalysisService = inject(CreativeAnalysisService);
  apiService: ApiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  accountId: string = '';

  environment = environment;

  creative: string | null = null;
  fileToUpload: File | null = null;
  s3UploadUrl: string | null = null;
  fileOriginalName: string = '';
  fileTempName: string = '';
  fileType: string = '';
  thumbnail: string | null = null;
  thumbnailUpload = `${environment.publicAssetsBucketUrl}/app-public/images/cloud-upload.gif`;
  url: string | null = null;
  creativeType: 'IMG' | 'VID' | null = null;
  // jobID: string = '';

  loadingCreative = false;
  uploadingCreative = false;
  requestingCreativeAnalysis = false;
  loadingCreativeAnalysis = false;

  creativeAnalysisImage: CreativeAnalysisImage | null = null;
  creativeAnalysisVideo: CreativeAnalysisVideo | null = null;
  creativeAnalysis: CreativeAnalysisImage | CreativeAnalysisVideo | null = null;
  
  selectedModel: 'openai' | 'gemini' = 'gemini';
  dropdownModelOpen = false;

  private pollingSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get Ad Account ID from URL params
        this.activatedRoute.params.subscribe((params: Params) => {
          this.accountId = params['id'];
          this.creative = params['creative'].toUpperCase();
          if(this.creative && (this.creative.length < 6 || this.creative.length > 6)) {
            console.error('Invalid Creative ID');
            this.toastrService.error('Invalid Creative ID');
            this.router.navigate(['/']);
          }
          if(this.creative) {
            console.log(`Loading Creative ID: ${this.creative}`);
            this.getCreative(this.accountId, this.creative);
          }
          // Get the Creative Type from the Creative ID
          if(this.creative) {
            this.creativeType = this.creative.substring(0, 3) as 'IMG' | 'VID';
            if(this.creativeType === 'VID') {
              console.log('Video creative detected. Setting model to:', this.selectedModel);
            }
          }
          console.log(`Loading Ad Account#: ${this.accountId}`);
          // Check if the user has access to the Ad Accounts section & this specific ad account
          this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
            if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.accountId)) {
              // User does not have access to the account (or the user has no ad accounts at all)
              console.log("User does not have access to this ad account");
              this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
              this.router.navigate(['/']);
            } else {
              // User has access to the account
              console.log("User has access to this ad account");

            }        
          });
        });
      }  
    });
  }

  fileChangeEvent(event: any): void {
    this.fileToUpload = event.target.files[0];
    if(this.fileToUpload) {
      console.log('File selected:', this.fileToUpload.name);
      // Regex to match "IMG", "VID" or "CAR" followed by exactly three digits
      const pattern = /^(IMG|VID|CAR)(\d{3})/;
      const matches = this.fileToUpload.name.match(pattern);
      console.log('Matches:', matches);
      if(this.creative) {
        // Creative ID is provided
        // Check if the file name matches the creative
        if(matches && matches[0] === this.creative) {
          console.log('File name matches the creative ID');
        } else {
          console.error('File name does not match the creative ID');
          this.toastrService.warning('File name does not match the creative ID', 'Warning!!', { disableTimeOut: true });
        }
      } else {
        // Creative ID is not provided
        // Set the creative ID to the first match
        if(matches) {
          console.log('Creative ID set to:', this.creative);
        } else {
          console.error('Creative ID not found in the file name');
          this.toastrService.warning('Creative ID not found in the file name', 'Warning!!', { disableTimeOut: true });
        }
      }
    }
  }

  uploadCreative(event: Event): void {
    event.preventDefault();
    if (!this.fileToUpload) {
      console.error('No file selected for upload.');
      this.toastrService.error('No file selected for upload.');
      return;
    }

    this.fileOriginalName = this.fileToUpload.name;
    this.fileType = this.fileToUpload.type;
    console.log('Uploading file:', this.fileOriginalName, 'of type:', this.fileType);

    // Get S3 temporary user storage Upload URL
    this.s3UploadUrl = null;
    this.uploadingCreative = true;
    this.creativeAnalysisService.getS3UploadUrl(this.fileOriginalName, this.fileType).then(response => {
      console.log('S3 Upload URL', response);
      this.s3UploadUrl = response.url;
      this.fileTempName = response.t_name;
      // Upload file to S3 temporary user storage
      return this.creativeAnalysisService.uploadFileToS3(this.s3UploadUrl!, this.fileToUpload!);
    }).then(() => {
      console.log('File uploaded to S3');
      // Import the creative from S3 temporary user storage to Firestore & S3 permanent creative analysis storage
      return this.creativeAnalysisService.importCreative(this.accountId, this.creative!, this.fileOriginalName, this.fileTempName);
    }).then((importedCreative) => {
      console.log('Creative imported successfully', importedCreative);
      this.thumbnail = importedCreative.thumbnail ?? `${environment.publicAssetsBucketUrl}/app-public/images/cloud-upload.gif`;
      this.toastrService.success('Creative imported successfully');
      // Request analysis
      return this.creativeAnalysisService.requestAnalysis(this.accountId, this.creative!, this.selectedModel);
    }).then((requestAnalysisResponse) => {
      console.log('Analysis requested', requestAnalysisResponse);
      this.uploadingCreative = false;

      // Reset form
      this.fileToUpload = null;
      this.s3UploadUrl = null;
      this.fileOriginalName = '';
      this.fileTempName = '';
      this.fileType = '';


      console.log('Starting polling for job status...');
      this.startPolling(this.accountId, this.creative!);


    }).catch(error => {
      console.error('Error getting S3 Upload URL', error);
      this.uploadingCreative = false;
    });

  }

  startPolling(accountId: string, creativeId: string): void {
    const pollingInterval = 2000; // 2 seconds

    this.requestingCreativeAnalysis = true;

    this.pollingSubscription = interval(pollingInterval).pipe(
      switchMap(() => this.creativeAnalysisService.checkJobStatus(accountId, creativeId)),
      takeWhile(response => response.status !== 'completed' && response.status !== 'failed' && response.status !== 'error', true)
    ).subscribe(response => {
      console.log('Polling response:', response);
      if(response.thumbnail) {
        this.thumbnail = response.thumbnail;
      }
      if(response.url) {
        this.url = response.url;
      }
      if(response.status === 'completed' || response.status === 'failed' || response.status === 'error') {        
        this.pollingSubscription.unsubscribe(); // Stop polling
        this.requestingCreativeAnalysis = false;
        if(response.status === 'completed') {
          console.log('Creative processing completed successfully');
          this.loadCreativeAnalysis(accountId, creativeId);
          
        }
        if(response.status === 'failed' || response.status === 'error') {
          console.error('Creative processing failed', response);
          this.toastrService.error(`Creative processing failed [${response.error}]`);
        }
      }
    }, error => {
      console.error('Polling error:', error);
      this.toastrService.error('Error during status check.');
      this.pollingSubscription.unsubscribe(); // Stop polling on error
    });
  }

  loadCreativeAnalysis(accountId: string, creativeId: string): void {
    this.creativeAnalysisImage = null;
    this.creativeAnalysisVideo = null;
    this.creativeAnalysis = null;
    this.loadingCreativeAnalysis = true;
    // Get type of creative from the creative ID
    const creativeType = creativeId.substring(0, 3) as 'IMG' | 'VID';
    if(creativeType === 'IMG') {
      this.creativeAnalysisService.getCreativeAnalysisImage(accountId, creativeId).then(analysis => {
        console.log('Creative analysis:', analysis);
        this.loadingCreativeAnalysis = false;
        this.creativeAnalysisImage = analysis;
        this.creativeAnalysis = analysis;
  
      }).catch(error => {
        console.error('Error fetching creative analysis', error);
        this.loadingCreativeAnalysis = false;
        this.toastrService.error('Error fetching creative analysis');
      });
    } else if(creativeType === 'VID') {
      this.creativeAnalysisService.getCreativeAnalysisVideo(accountId, creativeId).then(analysis => {
        console.log('Creative analysis:', analysis);
        this.loadingCreativeAnalysis = false;
        this.creativeAnalysisVideo = analysis;
        this.creativeAnalysis = analysis;
  
      }).catch(error => {
        console.error('Error fetching creative analysis', error);
        this.loadingCreativeAnalysis = false;
        this.toastrService.error('Error fetching creative analysis');
      });
    }
    
  }

  requestAnalysis(): void {
    this.requestingCreativeAnalysis = true;
    this.creativeAnalysisService.requestAnalysis(this.accountId, this.creative!, this.selectedModel).then(response => {
      console.log('Creative analysis requested', response);
      this.requestingCreativeAnalysis = false;
      // this.jobID = response.job;
      this.startPolling(this.accountId, this.creative!);
    }).catch(error => {
      console.error('Error requesting creative analysis', error);
      this.requestingCreativeAnalysis = false;
      this.toastrService.error('Error requesting creative analysis');
    });
  }

  getCreative(accountId: string, creativeId: string): void {
    try {
      this.loadingCreative = true;
      this.creativeAnalysisService.getCreative(accountId, creativeId)
      .then(creative => {
        console.log('Creative:', creative);
        this.loadingCreative = false;
        this.thumbnail = creative.thumbnail;
        this.url = creative.url;
        this.loadCreativeAnalysis(accountId, creativeId);
      })
      .catch(error => {
        console.error('Error fetching creative', error);
        this.loadingCreative = false;
        if(error == 'CreativeNotFound') {
          this.toastrService.warning('Creative not found. Upload a file to get started.');
          this.thumbnail = `${environment.publicAssetsBucketUrl}/app-public/images/cloud-upload.gif`
        } else {
          this.toastrService.error(`Error fetching creative [${error}]`);
        }
      });
    } catch (error) {
      console.error('Error fetching creative', error);
      this.loadingCreative = false;
    }
  }

  toggleDropdownModel() {
    this.dropdownModelOpen = !this.dropdownModelOpen;
  }

  closeDropdownModel(model: 'openai' | 'gemini') {
    this.dropdownModelOpen = false;
    this.selectedModel = model;
    console.log('Refreshing AI classification using:', this.selectedModel);
    this.requestAnalysis();
  }

}
