<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">

            <h4 style="text-align: center; margin-top: 20px;">Select a dashboard:</h4>      
            <div style="text-align: center;"><img *ngIf="loadingDashboards" src="{{ this.authService.LOADING_IMAGE_URL }}"></div>

            <div class="container d-flex justify-content-center" style="margin-top: 30px;">
                <div class="row text-center">
                  <div class="col-12 col-md-4 mb-4" *ngFor="let dashboard of dashboards">
                    <div style="margin: 30px auto 0 auto; border: 1px solid lightgray; border-radius: 5px; max-width: 300px;">
                        <a routerLink="/dashboards/{{ dashboard.id }}">
                            <img *ngIf="(dashboard.logo != ''); else noLogoBlock" src="/assets/images/accounts/{{ dashboard.logo }}" [alt]="dashboard.name" style="width: 160px; height: 80px;">
                            <ng-template #noLogoBlock>{{ dashboard.name }}</ng-template>
                        </a>
                    </div>
                  </div>
                </div>
            </div>

        </div>
    </div>

</div>