<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">

            <h1>Support</h1>

            <p style="margin-bottom: 10px;">
                Need help with Hubble? We're here for you!
            </p>

            <p style="margin-bottom: 40px;">
                If you have any questions, run into issues, or just want to send us feedback, simply fill out our support form below.<br>
                Our team will get back to you as soon as possible.
            </p>

            <iframe height="400" width="900" src="https://xehqmvk61ij.typeform.com/to/SSmLy5vK" allow="fullscreen"></iframe>

        </div>    

    </div>

</div>