<div class="container">

    <div class="row" style="margin: 30px;">

        <div class="col">
            <h1>Sorry, page not found (404)</h1>
            <a routerLink="/" type="button" class="btn btn-dark" style="width: 150px; margin-top: 20px;">Home</a>
        </div>

    </div>

</div>