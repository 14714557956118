import { Component, OnInit, inject, ElementRef, Renderer2 } from '@angular/core';
import { RouterLink, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { NamingConventionService } from '../../../services/naming-convention.service';
import { NamingConvention } from '../../../interfaces/naming-convention';
import { NamingConventionField } from '../../../interfaces/naming-convention';
import { AdAccount, Campaign, Adset, Ad } from '../../../interfaces/ad-account';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { regexPatterns } from '../regex-patterns';

@Component({
  selector: 'app-nc-auditor',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    NgbTooltipModule
  ],
  templateUrl: './nc-auditor.component.html',
  styleUrl: './nc-auditor.component.css'
})
export class NcAuditorComponent  implements OnInit {
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  apiService: ApiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);
  namingConventionService: NamingConventionService = inject(NamingConventionService);
  renderer: Renderer2 = inject(Renderer2);
  el: ElementRef = inject(ElementRef);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  googleCredentialsEmail: string | null = null;

  accountId: string = '';
  namingConvention: NamingConvention = {
    id: this.accountId
  };

  namingConventionLoading = true;
  namingConventionLoaded = false;

  regexPatterns: { [key: string]: RegExp } = regexPatterns;

  fields: { [key: string]: any } = {};

  loadingCampaigns = false;

  campaigns: Campaign[] = [];

  showCompliantAds = true;
  showNonCompliantAds = true;
  showActiveAds = true;
  showNonActiveAds = true;
  showActiveAdsets = true;
  showNonActiveAdsets = true;
  showActiveCampaigns = true;
  showNonActiveCampaigns = true;
  
  ngOnInit(): void {
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get Ad Account ID from URL params
        this.activatedRoute.params.subscribe((params: Params) => {
          this.accountId = params['id'];
          console.log(`Loading Ad Account#: ${this.accountId}`);
          // Check if the user has access to the Ad Accounts section & this specific ad account
          this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
            if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.accountId)) {
              // User does not have access to the account (or the user has no ad accounts at all)
              console.log("User does not have access to this ad account");
              this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
              this.router.navigate(['/']);
            } else {
              // User has access to the account
              console.log("User has access to this ad account");

              // Load the Naming Convention for the Ad Account
              this.namingConventionService.setId(this.accountId);
              this.namingConvention.id = this.accountId;
              this.loadNamingConvention()
              .then(() => {
                // Naming Convention loaded successfully
                console.log("Naming Convention loaded successfully", this.namingConvention);
                // The Meta account ID has been injected into the Naming Convention object
                this.loadCampaigns();
              })
              .catch((error) => {
                // Naming Convention failed to load (network error or no Naming Convention)
                console.error('Error fetching Naming Convention', error, this.namingConvention);
                // Get the Meta account ID from the API
                let request = '/ad-accounts?get='+this.accountId;
                this.apiService.get(request).then((resp) => {
                  console.log('Get Ad Account Data', resp);                  
                  this.namingConvention.meta_id = resp.data.meta_id;
                  this.namingConventionLoaded = true;
                  this.loadCampaigns();
                })
                .catch((error) => {
                  console.error('Error fetching Ad Account', error);
                  this.toastrService.error("Failed to load the Ad Account", "Error");
                });
              });


            }        
          });
        });
      }  
    });
  }  


  loadCampaigns() {
    this.getCampaigns()
    .then((campaigns) => {
      // Process each campaign
      for (const campaign of campaigns) {
        // If campaign is NC compliant and active, load campaign's adsets
        if (campaign.nc_compliant && campaign.status === 'ACTIVE') {
          this.getCampaignAdsets(campaign.id)
          .then(() => {
            // Process each adset
            for (const adset of campaign.adSets) {
              // Load adset's ads
              this.getAdsetsAds(adset.id);
            }
          })
          .catch((error) => {
            console.error('Error fetching adsets', error);
          });
        }
      }
    })
    .catch((error) => {
      console.error('Error fetching campaigns', error);
    });  
  }


  toggleCompliantAds() {
    // Toggle the visibility of compliant ads
    this.showCompliantAds = !this.showCompliantAds;
    const elements = this.el.nativeElement.querySelectorAll(`.ad-compliant`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showCompliantAds ? 'block' : 'none');
    }); 
  }

  toggleNonCompliantAds() {
    // Toggle the visibility of non-compliant ads
    this.showNonCompliantAds = !this.showNonCompliantAds;
    const elements = this.el.nativeElement.querySelectorAll(`.ad-non-compliant`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showNonCompliantAds ? 'block' : 'none');
    }); 
  }

  toggleActiveAds() {
    // Toggle the visibility of active ads
    this.showActiveAds = !this.showActiveAds;
    const elements = this.el.nativeElement.querySelectorAll(`.ad-active`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showActiveAds ? 'block' : 'none');
    }); 
  }

  toggleNonActiveAds() {
    // Toggle the visibility of non-active ads
    this.showNonActiveAds = !this.showNonActiveAds;
    const elements = this.el.nativeElement.querySelectorAll(`.ad-non-active`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showNonActiveAds ? 'block' : 'none');
    }); 
  }

  toggleActiveAdsets() {
    // Toggle the visibility of active adsets
    this.showActiveAdsets = !this.showActiveAdsets;
    const elements = this.el.nativeElement.querySelectorAll(`.adset-active`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showActiveAdsets ? 'block' : 'none');
    }); 
  }

  toggleNonActiveAdsets() {
    // Toggle the visibility of non-active adsets
    this.showNonActiveAdsets = !this.showNonActiveAdsets;
    const elements = this.el.nativeElement.querySelectorAll(`.adset-non-active`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showNonActiveAdsets ? 'block' : 'none');
    }); 
  }

  toggleActiveCampaigns() {
    // Toggle the visibility of active campaigns
    this.showActiveCampaigns = !this.showActiveCampaigns;
    const elements = this.el.nativeElement.querySelectorAll(`.campaign-active`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showActiveCampaigns ? 'block' : 'none');
    }); 
  }

  toggleNonActiveCampaigns() {
    // Toggle the visibility of non-active campaigns
    this.showNonActiveCampaigns = !this.showNonActiveCampaigns;
    const elements = this.el.nativeElement.querySelectorAll(`.campaign-non-active`);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'display', this.showNonActiveCampaigns ? 'block' : 'none');
    });
  }


  loadNamingConvention(): Promise<NamingConvention> {
    // Load the Naming Convention for the Ad Account
    return new Promise<NamingConvention>((resolve, reject) => {
      console.log("["+this.constructor.name+"] Loading Naming Convention for Ad Account", this.namingConvention.id);
      this.namingConventionLoading = true;
      this.namingConventionLoaded = false;
      this.namingConventionService.get().then(response => {
        // console.log("["+this.constructor.name+"] Naming Convention retrieved", response);
        this.namingConvention = response;
        this.namingConvention.id = this.accountId; // Re-inject the account ID
        _.forEach(this.namingConvention.fields, (field: NamingConventionField) => {
          // console.log("["+this.constructor.name+"] Field", field.id, field.name, field.validation);
          this.fields[field.id] = {
            name: field.name,
            error: field.error,
            placeholder: field.placeholder,
            description: field.description
          };
          if(field.validation.regex) {
            // If field.validation.regex) starts with "regexPatterns.", replace it with the actual regex pattern from regexPatterns.ts
            if(field.validation.regex.startsWith('regexPatterns.')) {
              // Standard regex pattern from regexPatterns.ts 
              _.forEach(this.regexPatterns, (value, key) => {
                if(field.validation.regex === 'regexPatterns.' + key) {                        
                  this.fields[field.id].regex = new RegExp(value);
                }
              });
            } else {
              // Custom regex pattern
              this.fields[field.id].regex = new RegExp(field.validation.regex);
            }
          } 
          if(field.allowed_values) this.fields[field.id].allowed_values = field.allowed_values;
          if(field.allowed_values_title) this.fields[field.id].allowed_values_title = field.allowed_values_title;
        });
        console.log("["+this.constructor.name+"] Naming Convention loaded", this.namingConvention.version);
  
        this.namingConventionLoading = false;
        this.namingConventionLoaded = true;

        resolve(this.namingConvention);
        
      }).catch(error => {
        console.log("["+this.constructor.name+"] Get Naming Convention error", error.message);
        this.namingConventionLoading = false;
        this.namingConventionLoaded = false;
        this.toastrService.warning("Failed to load Naming Convention. Syntax check only.", "Warning");
        reject(error.message);
      });
  
    });
  }


  getCampaigns(): Promise<Campaign[]> {
    return new Promise<Campaign[]>((resolve, reject) => {
      this.loadingCampaigns = true;
      let request = '/meta?get=account-campaigns&acc_id='+this.namingConvention.meta_id;
      this.apiService.get(request).then((resp) => {
        console.log('GetCampaigns Data', resp);
        this.loadingCampaigns = false;
        _.forEach(resp.data, (campaign) => {
          this.campaigns.push({
            id: campaign.id,
            name: campaign.name,
            nc_compliant: campaign.nc_compliant,
            created_time: campaign.created_time,
            status: campaign.status,
            adSets: [],
            adsSetsLoading: false
          });
        });
        this.campaigns = _.orderBy(this.campaigns, [(campaign) => campaign.status === 'ACTIVE'], ['desc']);
        resolve(this.campaigns);
      })
      .catch((error) => {
        console.log('Error', error);
        this.toastrService.error(error.message, 'Meta API error', { disableTimeOut: true });
        this.loadingCampaigns = false;
        reject(error.message);
      })   
    });
  }


  getCampaignAdsets(campaign_id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Find the campaign in the campaigns array
      const targetCampaign = this.campaigns.find(c => c.id === campaign_id);
      if (!targetCampaign) {
        reject(`Campaign with ID ${campaign_id} not found`);
        return;
      }

      // Set adsSetsLoading to true
      targetCampaign.adsSetsLoading = true;

      let request = `/meta?get=campaign-adsets&acc_id=${this.namingConvention.meta_id}&camp_id=${campaign_id}`;
      this.apiService.get(request).then((resp) => {
        targetCampaign.adSets = resp.data.map((adset: any) => ({
          id: adset.id,
          campaign_id: adset.campaign_id,
          name: adset.name,
          nc_compliant: adset.nc_compliant,
          created_time: adset.created_time,
          status: adset.status,
          ads: [],
          adsLoading: false
        }));
        targetCampaign.adsSetsLoading = false;
        resolve();
      }).catch((error) => {
        console.log('Error', error);
        this.toastrService.error(error.message, 'Meta API error', { disableTimeOut: true });
        targetCampaign.adsSetsLoading = false;
        reject(error.message);
      });
    });
  }


  getAdsetsAds(adset_id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Find the campaign in the adsets array
      const targetAdset = this.campaigns.flatMap(campaign => campaign.adSets).find(adset => adset.id === adset_id);
      if (!targetAdset) {
        reject(`Ad set with ID ${adset_id} not found`);
        return;
      }

      // Set adsLoading to true
      targetAdset.adsLoading = true;

      let request = `/meta?get=adset-ads&acc_id=${this.namingConvention.meta_id}&adset_id=${adset_id}`;
      this.apiService.get(request).then((resp) => {
        console.log('getAdsetsAds Data', resp);

        // Prepare ads array
        targetAdset.ads = resp.data.map((ad: any) => {
          console.log('ad', ad);
          let display_name = ad.name;
          // if(display_name.startsWith(targetCampaign.name)) {
          //   display_name = '...' + display_name.substring(targetCampaign.name.length).trim();
          // }
          let nc_compliance = ad.nc_compliant;
          nc_compliance['general_errors'] = [];
          _.forEach(ad.nc_compliant.errors, (error) => {
            if(Object.keys(error)[0] == 'general') nc_compliance['general_errors'].push(Object.values(error)[0]);
          });
          nc_compliance.semantic_valid = true;
          _.forEach(ad.nc_compliant.parsed_name, (name) => {
            if(!name.valid) nc_compliance.semantic_valid = false;
          });
          return {
            id: ad.id,
            name: ad.name,
            campaign_id: ad.campaign_id,
            adset_id: ad.adset_id,
            display_name: display_name,
            nc_compliance: nc_compliance,
            created_time: ad.created_time,
            status: ad.status
          };
        });
        targetAdset.adsLoading = false;
        resolve();
      }).catch((error) => {
        console.log('Error', error);
        this.toastrService.error(error.message, 'Meta API error', { disableTimeOut: true });
        targetAdset.adsLoading = false;
        reject(error.message);
      });
    });
  }


  async loadCampaign(campaign_id: string) {
    await this.getCampaignAdsets(campaign_id).then(() => {
      const targetCampaign = this.campaigns.find(c => c.id === campaign_id);
      if (targetCampaign) {
        for (const adset of targetCampaign.adSets) {
          this.getAdsetsAds(adset.id);
        }
      }
    })

  }  
  
}
