<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">

            <span *ngIf="loadingAdAccounts" class="spinner-border" style="position: fixed; top: 13px; right: 20px;" role="status">
                <span class="visually-hidden">Loading...</span>
            </span>

            <h1>Ad Accounts</h1>

            <p style="margin-bottom: 10px;">
                Manage your clients' Meta Ads accounts.
            </p>

            <div class="container d-flex justify-content-center" style="margin-top: 30px;">
                <div class="row text-center">
                  <div class="col" *ngFor="let adAccount of adAccounts">
                    <div style="margin: 30px auto 0 auto; border: 1px solid lightgray; border-radius: 5px; min-width: 300px; max-width: 300px;">
                        <a routerLink="/ad-accounts/{{ adAccount.id }}" [ngbTooltip]="adAccount.id">
                            <img [src]="adAccount.logo ?? '/assets/images/pix.gif'" [alt]="adAccount.name" [title]="adAccount.name" style="width: 160px; height: 80px;">
                        </a>
                    </div>
                  </div>
                </div>
            </div>

        </div>    

    </div>

</div>