import { Component, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ShortenPipe } from '../../pipes/shorten.pipe';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-support',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    ShortenPipe
  ],
  templateUrl: './support.component.html',
  styleUrl: './support.component.css'
})
export class SupportComponent implements OnInit {
  // Dependency Injection  
  router: Router = inject(Router);
  toastrService: ToastrService = inject(ToastrService);
  authService = inject(AuthService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;
  authToken?: string;

  ngOnInit(): void {
    
  }
}
