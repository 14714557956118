import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { AsyncPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit, OnDestroy {
  // Dependency Injection
  authService = inject(AuthService);
  router: Router = inject(Router);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  private authenticatedUserSubscription: Subscription | undefined;
  authenticatedUser = this.authService.authenticatedUser;

  signingIn = false;

  doSignIn() {
    this.signingIn = true;
    this.authService.signin()
    .then(() => {
      console.log("Sign in succeeded");
      this.signingIn = false;
      this.router.navigate(['/', '/']);
    })
    .catch(error => {
      console.error("Sign in failed", error);
      this.signingIn = false;
      if(error && error.originalError && error.originalError.error && error.originalError.error.error && error.originalError.error.error.name == 'InactiveAccount') {
        // Inactive Account
        this.router.navigate(['/401']);
      } else {
        // Other sign in error
        this.toastrService.error(error.message, 'Sign in error');
        this.router.navigate(['/', '/']);
      }
    });
  }

  ngOnInit(): void {
    // User Authentication
    // Subscribe to AuthService's authenticatedUserSubject (event listener) to get notified when Firebase Authentication state changes (user signs in/signs out or idToken is refreshed)
    this.authenticatedUserSubscription = this.authService.authenticatedUserSubject.subscribe(authenticateUser => {
      console.log("HomeComponent notified by authenticatedUserSubscription", this.authService.authenticatedUser);
      if(authenticateUser) this.authenticatedUser = authenticateUser;
      else this.authenticatedUser = null;
    });
  }

  ngOnDestroy(): void {
    // User Authentication
    // Unsubscribe from authenticatedUserSubscription() Subject (event listener)
    this.authenticatedUserSubscription?.unsubscribe();
  }

}
