// file-upload.component.ts
import { Component, signal, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div 
      [attr.data-uploader-id]="uploaderId"
      class="drop-zone" 
      [class.dragover]="isDragging()"
      (dragover)="onDragOver($event)" 
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="fileInput.click()">
      
      <input 
        #fileInput
        type="file" 
        (change)="onFileSelected($event)" 
        [accept]="acceptedFileTypes"
        [multiple]="false"
        style="display: none">
      
      <div class="upload-prompt" *ngIf="!imagePreview()">
        <p>Drop file here or click to browse</p>
        <p class="supported-formats">JPG, PNG, GIF, MP4 (max 200MB)</p>
      </div>

      @if (imagePreview()) {
        <div class="preview-container">
          <img [src]="imagePreview()" alt="File preview" class="preview-image">
          <button class="remove-button" (click)="removeFile($event)">×</button>
        </div>
      }

      <!-- @if (validationError()) {
        <div class="error-message">{{ validationError() }}</div>
      } -->

    </div>
  `,
  styles: [`
    .drop-zone {
      border: 2px dashed #ccc;
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      background: #fafafa;
      cursor: pointer;
      transition: all 0.3s ease;
      min-height: 200px;
      max-height: 200px;
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .drop-zone.dragover {
      border-color: #2196f3;
      background: rgba(33, 150, 243, 0.1);
    }

    .upload-prompt {
      color: #666;
    }

    .supported-formats {
      font-size: 0.8rem;
      color: #888;
      margin-top: 0.5rem;
    }

    .preview-container {
      position: relative;
      max-width: 200px;
      max-height: 200px;
      width: 100%;
      margin: 20px auto;
    }

    .preview-image {
      height: auto;
      max-height: 160px;
      max-width: 100%;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    .remove-button {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: black;
      color: white;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    }

    .error-message {
      color: #d32f2f;
      font-size: 0.875rem;
      margin-top: 1rem;
      padding: 0.5rem;
      background: #ffebee;
      border-radius: 4px;
      width: 100%;
    }
  `]
})
export class FileUploadComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() uploaderId: string = '';

  @Output() fileReady = new EventEmitter<{id: string, file: File | null}>();
  @Output() fileError = new EventEmitter<{id: string, error: string | null}>();

  private readonly maxFileSize = 200 * 1024 * 1024; // 200MB
  private readonly allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'application/pdf'];
  
  isDragging = signal(false);
  imagePreview = signal<string | null>(null);
  validationError = signal<string | null>(null);
  acceptedFileTypes = '.jpg,.png,.gif,.mp4,.pdf';

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging.set(true);
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging.set(false);
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging.set(false);
    
    const files = event.dataTransfer?.files;
    if (files?.length) {
      this.handleFile(files[0]);
    }
  }

  onFileSelected(event: Event): void {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    if (files?.length) {
      this.handleFile(files[0]);
    }
  }

  private async handleFile(file: File): Promise<void> {
    this.validationError.set(null);
    
    if (file.size === 0) {
      this.validationError.set('File is empty');
      this.fileError.emit({
        id: this.uploaderId,
        error: this.validationError()
      });
      return;
    }

    if (file.size > this.maxFileSize) {
      this.validationError.set(`File size exceeds 200MB limit (${(file.size / 1024 / 1024).toFixed(1)}MB)`);
      this.fileError.emit({
        id: this.uploaderId,
        error: this.validationError()
      });      
      return;
    }

    if (!this.allowedTypes.includes(file.type)) {
      this.validationError.set('Unsupported file type. Please use JPG, PNG, GIF, or MP4.');
      this.fileError.emit({
        id: this.uploaderId,
        error: this.validationError()
      });      
      return;
    }

    if (file.type.startsWith('image/')) {
      await this.createPreview(file);
    } else if (file.type.startsWith('video/')) {
      this.imagePreview.set('assets/images/video.png');
    } else {
      this.imagePreview.set(null);
    }

    this.fileReady.emit({
      id: this.uploaderId,
      file: file
    });
  }

  private createPreview(file: File): Promise<void> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result as string;
        if (result) {
          const img = new Image();
          img.onload = () => {
            if (img.width > 4096 || img.height > 4096) {
              this.validationError.set('Image dimensions exceed maximum size (4096x4096)');
              this.imagePreview.set(null);
            } else {
              this.imagePreview.set(result);
            }
            resolve();
          };
          img.src = result;
        }
      };

      reader.readAsDataURL(file);
    });
  }

  removeFile(event: Event): void {
    event.stopPropagation();
    this.imagePreview.set(null);
    this.validationError.set(null);
    this.fileReady.emit({
      id: this.uploaderId,
      file: null
    });
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  triggerFileInputClick(): void {
    this.fileInput.nativeElement.click();
  }

}