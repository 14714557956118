<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">

            <h1>Privacy Policy</h1>

            <p>This Privacy Policy applies to all services provided by Hubble App, accessible via <a href="https://hubbleapp.ai" target="_blank">https://hubbleapp.ai</a>, including any subdomains ("we," "us," or "Hubble App"). By using our website and services, you agree to the terms outlined in this Privacy Policy.</p>

            <h2>Information We Collect</h2>
            <p>We collect various types of information to provide and improve our services. This includes:</p>
            <ul>
                <li><b>Personal Information:</b> When you register or interact with our services, we may collect information such as your name, email address, and any other details you provide.</li>
                <li><b>Automatically Collected Information:</b> We automatically collect data such as your IP address, browser type, device type, and usage patterns when you interact with our website.</li>
                <li><b>Cookies:</b> We use cookies to enhance your experience on our site. These small data files are stored on your device and help us remember your preferences and track your interactions with our services.</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>The information we collect is used to:</p>
            <ul>
                <li>Provide and improve our Service.</li>
                <li>Process transactions and send you related information.</li>
                <li>Communicate with you, including responding to your requests and providing customer support.</li>
                <li>Analyse usage and trends to enhance the user experience.</li>
                <li>Enforce our Terms of Service and other agreements.</li>
            </ul>

            <h2>Information Sharing and Disclosure</h2>
            <p>We do not share, sell, or disclose your personal information with third parties except under the following circumstances:</p>
            <ul>

            </ul>
            <li><b>With Your Consent:</b> We may share information when we have your explicit consent to do so.</li>
            <li><b>Service Providers:</b> We engage trusted third-party service providers to perform functions such as hosting, maintenance, customer relationship management, and data storage. These providers are granted access to your personal information only to the extent necessary to perform their services.</li>
            <li><b>Legal Requirements:</b> We may disclose your information if required by law, such as to comply with a subpoena or similar legal process, or if we believe in good faith that such action is necessary to protect the rights, property, or safety of our users or others.</li>
            <li><b>Business Transfers:</b> In the event that Hubbleapp.ai is acquired or merged with another company, your information may be transferred as part of that transaction. You will be notified via email and/or a prominent notice on our Website of any change in ownership or use of your personal information.</li>

            <h2>Data Security</h2>
            <p>We take the security of your data seriously and use industry-standard practices to protect it. However, no method of transmission over the Internet or electronic storage is completely secure, so we cannot guarantee its absolute security.</p>

            <h2>Data Storage</h2>
            <p>Your information may be stored and processed in data centers located in various countries, including the United States and other locations where Hubbleapp.ai, its affiliates, or service providers maintain facilities.</p>

            <h2>Children's Privacy</h2>
            <p>Hubbleapp.ai does not knowingly collect personal information from children under the age of 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information from our files. If you are a parent or guardian and believe your child has provided us with personal information, please contact us at <a href="mailto:support@hubbleapp.ai">support&#64;hubbleapp.ai</a>.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on our Website and updating the "Effective Date" at the top of this document. Your continued use of the Service after any changes to this Privacy Policy constitutes your acceptance of the revised terms.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@hubbleapp.ai">support&#64;hubbleapp.ai</a>.</p>

            <h2>Address</h2>
            <p>GPO Box 856<br>
            Sydney, Australia 2001</p>

        </div>    

    </div>

</div>