<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <div class="row">
                <div class="col">
                    <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                            <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ accountId }}">{{ accountId | titlecase }}</a></li>
                            <li class="breadcrumb-item"><a routerLink="/ad-accounts/creative-assistant/{{ accountId }}">Creative Analysis Assistant</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ creative | uppercase }}</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-auto">
                    <div *ngIf="loadingCreative" style="text-align: right;">
                        <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>
                    </div>
                    <div *ngIf="requestingCreativeAnalysis" style="text-align: right;">
                        <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>Processing AI Analysis
                    </div>
                    <div *ngIf="loadingCreativeAnalysis" style="text-align: right;">
                        <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>Loading AI Analysis
                    </div>
                    <div *ngIf="!requestingCreativeAnalysis && !loadingCreativeAnalysis && !uploadingCreative" style="text-align: right;">
                        <div class="dropdown">
                            <button class="dropdown-toggle" (click)="toggleDropdownModel()">
                              Refresh AI Analysis
                            </button>
                            <div class="dropdown-menu" [class.show]="dropdownModelOpen">
                              <a class="dropdown-item" (click)="closeDropdownModel('openai')">
                                <img src="{{ environment.publicAssetsBucketUrl }}/app-public/images/openai.png" style="width: 20px; height: 20px; margin-right: 10px;">OpenAI
                              </a>
                              <a class="dropdown-item" (click)="closeDropdownModel('gemini')">
                                <img src="{{ environment.publicAssetsBucketUrl }}/app-public/images/gemini.png" style="width: 20px; height: 20px; margin-right: 10px;">Google Gemini
                              </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 30px; margin-bottom: 50px;">
                <div class="col-7">
                    <div>
                        <div class="list-group">
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Main Angle</div>
                                    <div class="col-9">{{ creativeAnalysis?.mainAngle ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Creative Style</div>
                                    <div class="col-9">{{ creativeAnalysis?.creativeStyle ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Target Market</div>
                                    <div class="col-9">
                                        {{ creativeAnalysis?.targetMarketDescription?? '-' }}
                                        <div style="display: none; margin-top: 5px; color: grey; font-size: 90%;"><b>Gender:</b> {{ creativeAnalysis?.targetMarketGender ?? '-' }}</div>
                                        <div style="display: none; margin-top: 5px; color: grey; font-size: 90%;"><b>Age Category:</b> {{ creativeAnalysis?.targetMarketAgeCategory ?? '-' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Market Awareness</div>
                                    <div class="col-9">{{ creativeAnalysis?.marketAwareness ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Discounts</div>
                                    <div class="col-9">
                                        {{ creativeAnalysis?.discountCode != 'N/A' ? creativeAnalysis?.discountCode : creativeAnalysis?.discount }}
                                        <div style="margin-top: 5px; color: grey; font-size: 90%;"><b>Discount:</b> {{ creativeAnalysis?.discount ?? '-' }}</div>
                                        <div style="margin-top: 5px; color: grey; font-size: 90%;"><b>Discount Code:</b> {{ creativeAnalysis?.discountCode ?? '-' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="creativeType == 'IMG'" class="list-group-item">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Headline</div>
                                    <div class="col-9">{{ creativeAnalysisImage?.headline ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'IMG'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Person Feature</div>
                                    <div class="col-9">
                                        {{ creativeAnalysisImage?.personFeatureVisible ?? '-' }}
                                        <div *ngIf="creativeAnalysisImage?.personFeatureDescription && creativeAnalysisImage?.personFeatureDescription != 'N/A'" style="margin-top: 5px; color: grey; font-size: 90%;">{{ creativeAnalysisImage?.personFeatureDescription }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'IMG'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Call To Action</div>
                                    <div class="col-9">{{ creativeAnalysisImage?.callToAction ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'VID'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Audio Hook</div>
                                    <div class="col-9">{{ creativeAnalysisVideo?.audioHook ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'VID'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Visual Hook</div>
                                    <div class="col-9">{{ creativeAnalysisVideo?.visualHook ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'VID'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Age Group</div>
                                    <div class="col-9">{{ creativeAnalysisVideo?.ageGroup ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'VID'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Gender</div>
                                    <div class="col-9">{{ creativeAnalysisVideo?.gender ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'VID'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Length</div>
                                    <div class="col-9">{{ creativeAnalysisVideo?.length ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'VID'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Dialogue CTA</div>
                                    <div class="col-9">{{ creativeAnalysisVideo?.dialogueCTA ?? '-' }}</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngIf="creativeType == 'VID'">
                                <div class="row">
                                    <div class="col-3" style="font-weight: bold;">Visual End Frame</div>
                                    <div class="col-9">{{ creativeAnalysisVideo?.visualEndFrame ?? '-' }}</div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="col-5">
                    <div *ngIf="(creativeType == 'IMG' && thumbnail) || creativeType == 'VID'" style="margin-bottom: 30px; text-align: center; max-width: 488px; border: 1px dashed #ccc; background-color: #f5f5f5; border-radius: 4px;">
                        <img *ngIf="creativeType == 'IMG' && thumbnail" [src]="thumbnail" class="img-fluid" alt="Creative Image Preview" />
                        <a *ngIf="creativeType == 'VID' && thumbnail" [href]="url" target="_blank"><img [src]="thumbnail" class="img-fluid" alt="Creative Image Preview" /></a>
                        <!-- <video *ngIf="creativeType == 'VID'" controls class="img-fluid">
                            <source [src]="thumbnail" type="video/mp4">
                            Your browser does not support the video tag.
                        </video> -->
                    </div>
                    <form *ngIf="!loadingCreativeAnalysis" (submit)="uploadCreative($event)">                        
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <input type="file" id="file" name="file" (change)="fileChangeEvent($event)" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-auto" style="text-align: right;">
                                <button type="submit" class="btn btn-dark" [disabled]="uploadingCreative">
                                    Upload
                                    <span *ngIf="uploadingCreative" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>                                
                            </div>
                        </div>                    
                    </form>
                </div>
            </div>

            <div *ngIf="creativeAnalysis" class="list-group" style="margin-top: 30px; margin-bottom: 50px;">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col" style="font-weight: bold; color: grey; font-size: 80%;"><span class="badge bg-secondary" style="font-weight: normal;">{{ creativeAnalysis.classificationId }}</span></div>
                        <div class="col-auto" style="color: grey; font-size: 80%;">
                            {{ creativeAnalysis.classified | date:'medium' }}
                        </div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div style="font-weight: bold; color: grey; font-size: 80%; margin-bottom: 5px;">Detected Text</div>
                        <div>
                            <span *ngFor="let text of creativeAnalysis.detectedTexts" class="badge bg-light text-dark" style="margin-right: 5px; font-weight: normal; white-space: normal;">{{ text }}</span>
                        </div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div style="font-weight: bold; color: grey; font-size: 80%; margin-bottom: 5px;">Image/Video Description</div>
                        <div style="color: grey; font-size: 80%;">{{ creativeAnalysisImage?.imageDescription ?? creativeAnalysisVideo?.videoDescription }}</div>
                    </div>
                </div>
                <div *ngIf="creativeAnalysisVideo?.transcript" class="list-group-item">
                    <div class="row">
                        <div style="font-weight: bold; color: grey; font-size: 80%; margin-bottom: 5px;">Transcript</div>
                        <div style="color: grey; font-size: 80%;">{{ creativeAnalysisVideo?.transcript }}</div>
                    </div>
                </div>
                <div *ngIf="creativeAnalysisImage?.prompt" class="list-group-item">
                    <div class="row">
                        <div style="font-weight: bold; color: grey; font-size: 80%; margin-bottom: 5px;">Prompt</div>
                        <div style="color: grey; font-size: 80%;">
                            <div style="font-style: italic;">System:</div>
                            <div [innerHTML]="creativeAnalysisImage?.prompt?.system"></div>
                            <div style="font-style: italic; margin-top: 5px;">User:</div>
                            <div [innerHTML]="creativeAnalysisImage?.prompt?.user"></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="creativeAnalysisVideo?.prompts" class="list-group-item">
                    <div class="row">
                        <div style="font-weight: bold; color: grey; font-size: 80%; margin-bottom: 5px;">Prompts</div>
                        <div *ngFor="let prompt of creativeAnalysisVideo?.prompts" style="color: grey; font-size: 80%; margin-bottom: 10px;">
                            <div style="font-style: italic;">{{ prompt.type | uppercase }} - System:</div>
                            <div [innerHTML]="prompt.system"></div>
                            <div style="font-style: italic; margin-top: 5px;">{{ prompt.type | uppercase }} - User:</div>
                            <div [innerHTML]="prompt.user"></div>
                        </div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div style="font-weight: bold; color: grey; font-size: 80%; margin-bottom: 5px;">Raw Output</div>
                        <ngx-json-viewer [json]="creativeAnalysis.rawOutput" style="font-size: 10px;"></ngx-json-viewer>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="row">
                        <div class="col" style="color: grey; font-size: 80%;">
                            <span style="margin-right: 10px; font-weight: bold;">Model</span>{{ creativeAnalysis.model }}
                        </div>
                        <div class="col-auto" *ngIf="creativeAnalysis.executionTime?.model" style="color: grey; font-size: 80%;">
                            <span style="margin-right: 10px; font-weight: bold;">AiExec</span>{{ creativeAnalysis.executionTime?.model | number }}
                        </div>
                        <div class="col-auto" *ngIf="creativeAnalysisVideo?.executionTime?.ffmpeg" style="color: grey; font-size: 80%;">
                            <span style="margin-right: 10px; font-weight: bold;">FFmpeg</span>{{ creativeAnalysisVideo?.executionTime?.ffmpeg | number }}
                        </div>
                        <div class="col-auto" style="color: grey; font-size: 80%; text-align: right;">
                            <span style="margin-right: 10px; font-weight: bold;">Tokens</span>{{ creativeAnalysis.usageTokensTotal | number }}
                        </div>
                    </div>
                </div>
            </div>

        </div>    
    </div>
</div>