<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <div class="row g-0">
                <div class="col">
                    <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ accountId | titlecase }}</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-auto" *ngIf="!adAccountLoading" style="text-align: right;">
                    <a *ngIf="adAccount?.gs_ads_library_id" class="btn btn-sm btn-dark" [ngbTooltip]="'Open Ads Library Matrix'" [href]="'https://docs.google.com/spreadsheets/d/'+adAccount?.gs_ads_library_id" target="_blank" style="margin-right: 15px;"><i class="bi bi-table" style="margin-right: 5px;"></i>Ads Library Matrix</a>
                    <a *ngIf="adAccount?.gd_creatives_folder_id" class="btn btn-sm btn-dark" [ngbTooltip]="'Open Google Drive folder'" [href]="'https://drive.google.com/drive/folders/'+adAccount?.gd_creatives_folder_id" target="_blank" style="margin-right: 15px;"><i class="bi bi-google" style="margin-right: 5px;"></i>Google Drive Folder</a>
                    <a *ngIf="adAccount?.meta_id" class="btn btn-sm btn-dark" [ngbTooltip]="'Open Meta Media Library Folder'" href="https://business.facebook.com/asset_library/ad_accounts?ad_account={{ adAccount?.meta_id }}" target="_blank"><i class="bi bi-facebook" style="margin-right: 5px;"></i>Meta Media Library</a>
                </div>
            </div>            

            <span *ngIf="adAccountLoading" class="spinner-border" style="position: fixed; top: 13px; right: 20px;" role="status">
                <span class="visually-hidden">Loading...</span>
            </span>

            <div class="row g-0" style="margin-top: 8px;">
                <div class="col">
                    <div class="d-flex flex-wrap">
                
                        <div class="card m-2" style="width: 48%;">
                            <div class="card-body">
                                <h5 class="card-title"><i class="bi bi-clipboard-data" style="margin-right: 10px;"></i>Meta Performance Dashboard</h5>
                                <p class="card-text">Monitor campaign performance</p>
                                <a routerLink="/dashboards/{{ adAccount?.dashboard_id }}" [style.cursor]="adAccount?.dashboard_id ? 'pointer' : 'wait'" class="btn btn-dark">View</a>
                            </div>
                        </div>   

                        <div class="card m-2" style="width: 48%;">
                            <div class="card-body">
                                <h5 class="card-title"><i class="bi bi-file-earmark-code" style="margin-right: 10px;"></i>Naming Convention</h5>
                                <p class="card-text">Manage the Naming Convention</p>
                                <a routerLink="/ad-accounts/naming-convention/{{ accountId }}" class="btn btn-dark">Manage</a>
                            </div>
                        </div>

                        <div class="card m-2" style="width: 48%;">
                            <div class="card-body">
                                <h5 class="card-title"><i class="bi bi-card-checklist" style="margin-right: 10px;"></i>Naming Convention Auditor</h5>
                                <p class="card-text">Check that ads comply with the Naming Convention</p>
                                <a routerLink="/ad-accounts/nc-auditor/{{ accountId }}" class="btn btn-dark">Audit</a>
                            </div>
                        </div>

                        <div class="card m-2" style="width: 48%;">
                            <div class="card-body">
                                <h5 class="card-title"><i class="bi bi-stars" style="margin-right: 10px;"></i>Naming Convention Generator</h5>
                                <p class="card-text">Create and edit ads that comply with the Naming Convention</p>
                                <a routerLink="/ad-accounts/nc-generator/{{ accountId }}" class="btn btn-dark">Generate</a>
                            </div>
                        </div>

                        <div class="card m-2" style="width: 48%;">
                            <div class="card-body">
                                <h5 class="card-title"><i class="bi bi-cloud-arrow-up" style="margin-right: 10px;"></i>Ads Library Matrix Uploader</h5>
                                <p class="card-text">Upload creatives to Google Drive + the Meta Media Library</p>
                                <a routerLink="/ad-accounts/creative-uploader/{{ accountId }}" class="btn btn-dark">Upload</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>    
    </div>
</div>