import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  // Dependency Injection
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);


  constructor(
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot, 
    router: RouterStateSnapshot
  ): boolean | Promise<boolean> | Observable<boolean> {
    if (this.authService.authenticatedUser) {
      console.log('AuthGuardService user is authenticated');
      return true;
    } else {
      console.log('AuthGuardService user is NOT authenticated');
      this.router.navigate(['/']);
      return false;
    }
  }
}
