import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  standalone: true,
  imports: [],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.css'
})
export class SplashScreenComponent implements OnInit {
  @Input() message: string = "Loading Hubble...";

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
