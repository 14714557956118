import { Component, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { User, UserActionLog } from '../../../interfaces/user';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    FormsModule
  ],
  templateUrl: './user.component.html',
  styleUrl: './user.component.css'
})
export class AdminUserComponent implements OnInit {
  // Dependency Injection  
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService = inject(AuthService);
  apiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);
  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;
  authToken?: string;
  loadingUser = false;
  user: User = {
    id: '',
    email: '',
    isActive: false,
    dashboards: []
  };
  userActionLogs: UserActionLog[] = [];
  loadingUserActionLogs = false;
  updatingUser: { [key: string]: {showForm: boolean, posting: boolean} } = {
    isActive: {
      showForm: false,
      posting: false
    }
  };
  dashboards: any[] = []; // Store all available dashboards
  loadingDashboards = false;
  userDashboards: string[] = []; // Store user's selected dashboards
  updatingDashboards: { [key: string]: boolean } = {}; // Track loading state for dashboards
  sendingActivationNotification = false;

  ngOnInit(): void {
    // Check that user is authenticated & ADMIN (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else if(!this.authService.isAdmin) {
        // User is NOT Admin => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // ADMIN is signed in
        this.authService.getIdToken()
        .then(token => {
              console.log("authService getIdToken()", token);
              this.authToken = token;
              // Implement page logic
              this.activatedRoute.params.subscribe((params: Params) => {
                this.user.id = params['id'];
                console.log(`Loading User #${this.user.id}`);
                this.getUser();
                this.getDashboards();
                this.getUserActionLogs();
              });


        }).catch(error => {
          console.log("getIdToken error", error);
        });
      }
    });
  }


  getUser() {
    return new Promise<User>((resolve, reject) => {
      this.loadingUser = true;
      let request = '/admin?get=user&id='+this.user.id;
      this.apiService.get(request).then((data) => {
        console.log('getUser Data', data);
        this.loadingUser = false;
        this.user = {
          id: data.data.uid,
          email: data.data.email,
          name: data.data.name,
          photo: data.data.photo,
          isActive: data.data.isActive,
          signedIn: data.data.signedIn,
          updated: data.data.updated,
          created: data.data.created,
          dashboards: [],
          manageAccounts: data.data.manageAccounts
        };
        _.forEach(data.data.dashboards, (dashboard) => {
          this.user.dashboards?.push({
            id: dashboard.id,
            name: dashboard.name
          });

          this.userDashboards.push(dashboard.id);
        });
        console.log('this.user', this.user);
        resolve(this.user);
      })
      .catch((error) => {
        console.log('Error', error);
        this.loadingUser = false;
        reject(error.message);
      })   
    });
  }


  getUserActionLogs() {
    return new Promise<UserActionLog[]>((resolve, reject) => {
      this.loadingUserActionLogs = true;
      let request = '/admin?get=user-logs&id='+this.user.id;
      this.apiService.get(request).then((data) => {
        console.log('getUserActionLogs Data', data);
        this.loadingUserActionLogs = false;
        data.data.forEach((log: UserActionLog) => {
          this.userActionLogs.push({
            timestamp: log.timestamp,
            type: log.type,
            parameters: log.parameters,
            ip: log.ip
          });
        });
        console.log('this.userActionLogs', this.userActionLogs);
        resolve(this.userActionLogs);
      })
      .catch((error) => {
        console.log('Error', error);
        this.loadingUserActionLogs = false;
        reject(error.message);
      })   
    });
  }


  updateUser(field: string) {
    return new Promise<User>((resolve, reject) => {      
      _.forEach(this.user, (value, index) => {
        if(index == field) {
          console.log(index, value);
          this.updatingUser[index].posting = true;
          if(index == 'isActive') {
            // IsActive is boolean, cast string if necessary
            if(value === false || value === 'false') value = false;
            if(value === true || value === 'true') value = true;
          }
          let path = '/admin';
          let body = {
            "act": "update",
            "id": this.user.id,
            "data": {
              [index]: value
            }
          }
          this.apiService.post(path, body)
          .then((data) => {
            this.updatingUser[index].posting = false;
            console.log('Post response', data);
            // Load latest version of the user object in the db
            return this.getUser();
          })
          .then((user) => {
            console.log('Updated user', user);
            this.user = user;
            this.updatingUser[index].showForm = false;
            resolve(user);
          })
          .catch((error) => {
            console.log('Error', error);
            this.updatingUser[index].posting = false;
            this.toastrService.error(error.message, 'Update error');
            reject(error.message);
          })
        } 
      })
    });
  }


  getDashboards() {
    return new Promise<User>((resolve, reject) => {
      this.loadingDashboards = true;
      let request = '/admin?get=dashboards';
      this.apiService.get(request).then((data) => {
        console.log('getDashboards Data', data);
        this.loadingDashboards = false;
        this.dashboards = [];
        _.forEach(data.data, (dashboard) => {
          if(dashboard) {
            this.dashboards.push({
              id: dashboard.id,
              name: dashboard.name
            });
          }
        });
        console.log('this.dashboards', this.dashboards);
        resolve(this.user);
      })
      .catch((error) => {
        console.log('Error', error);
        this.loadingDashboards = false;
        reject(error.message);
      })   
    });
  }


  isDashboardChecked(dashboardId: string): boolean {
    return this.userDashboards.includes(dashboardId); // Check if dashboard is in user's list
  }


  // Handle checking/unchecking a dashboard
  onDashboardChange(dashboardId: string, event: Event): void {
    const target = event.target as HTMLInputElement
    const isChecked = target.checked;

    // Mark the dashboard as loading
    this.updatingDashboards[dashboardId] = true;

    let path = '/admin';
    let body = {
      "act": isChecked ? "add-dash" : "remove-dash",
      "id": this.user.id,
      "data": dashboardId
    }
    this.apiService.post(path, body)
    .then((data) => {
      console.log('Post response', data);
      this.updatingDashboards[dashboardId] = false;
      this.toastrService.success('User updated');
    })
    .catch((error) => {
      console.log('Error', error);
      this.updatingDashboards[dashboardId] = false;
      this.toastrService.error(error.message, 'Update error');
    })   
  }


  // Function to confirm before sending the activation email
  confirmAndNotifyActivation(): void {
    if (confirm(`Are you sure you want to notify ${this.user.email} that their account has been activated?`)) {
      this.sendActivationNotification();
    }
  }

  // Function to send activation notification
  sendActivationNotification(): void {
    // Send POST request to the backend
    this.sendingActivationNotification = true;
    let path = '/admin';
    let body = {
      act: 'notify-activation',
      id: this.user.id,
      data: this.user.email
    }
    this.apiService.post(path, body)
    .then((data) => {
      console.log('Post response', data);
      this.sendingActivationNotification = false;
      this.toastrService.success('Notification sent');
    })
    .catch((error) => {
      console.log('Error', error);
      this.sendingActivationNotification = false;
      this.toastrService.error(error.message, 'Error sending notification');
    })   
  }
  
  

}
