import { Injectable, inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HttpService } from '../services/http.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // Dependency Injection
  private httpService: HttpService = inject(HttpService);
  private authService: AuthService = inject(AuthService);

  async get(path: string, options?: {}) {
    return new Promise<any>((resolve, reject) => {
      // Add path to base URL
      let requestUrl = environment.backendUrl + path;
      console.log('[ApiService] get() sending HTTP GET request to ' + requestUrl, options);
      // this.loggingService.logDebug('ApiService sending HTTP GET request to ' + url, options);
      // Get Firebase IdToken for authentication
      this.authService.getIdToken()
        .then(idToken => {
          // Add Firebase IdToken to headers
          let headers = {
            'Authorization': `Bearer ${idToken}`,
          };            
          console.log("[ApiService] get() sending GET request", requestUrl);
          this.httpService.get(requestUrl, {headers})
          .then((data) => {
            console.log('[ApiService] get() HttpService Data', data);
            resolve(data);
          })
          .catch((error) => {
            console.log('[ApiService] get() HttpService Error', error);
            let returnedError = {
              status: error.status,
              message: (error.error && error.error.error && error.error.error.message) ? error.error.error.message : error.message,
              id: (error.error && error.error.error && error.error.error.id) ? error.error.error.id : null,
              originalError: error
            };
            reject(returnedError);
          }) 
        })
        .catch(error => {
          console.log("getIdToken error", error);
          let returnedError = {
            status: 401,
            message: 'Authentication error',
            id: 'no_token',
            originalError: error
          };  
          reject(returnedError);
        });
    });
  }

  
  async post(path: string, body: any, options?: {}) {
    return new Promise<any>((resolve, reject) => {
      // Add path to base URL
      let requestUrl = environment.backendUrl + path;
      console.log('[ApiService] post() sending HTTP POST request to ' + requestUrl, options);
      // this.loggingService.logDebug('ApiService sending HTTP GET request to ' + url, options);
      // Get Firebase IdToken for authentication
      this.authService.getIdToken()
        .then(idToken => {
          // Send GET request
          this.authService.getIdToken().then((idToken) => {
            // Add Firebase IdToken to headers
            let headers = {
              'Authorization': `Bearer ${idToken}`,
            };            
            console.log("[ApiService] post() sending POST request", requestUrl, body);
            this.httpService.post(requestUrl, body, {headers})
            .then((data) => {
              console.log('[ApiService] post() HttpService Data', data);
              resolve(data);
            })
            .catch((error) => {
              console.log('[ApiService] post() HttpService Error', error);
              let returnedError = {
                status: error.status,
                message: (error.error && error.error.error && error.error.error.message) ? error.error.error.message : error.message,
                id: (error.error && error.error.error && error.error.error.id) ? error.error.error.id : null,
                originalError: error
              };
              reject(returnedError);
            })
          }) 
        })
        .catch(error => {
          console.log("getIdToken error", error);
          let returnedError = {
            status: 401,
            message: 'Authentication error',
            id: 'no_token',
            originalError: error
          };  
          reject(returnedError);
        });
    });
  }

}
