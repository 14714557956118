import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RouterLink, RouterOutlet, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { DashboardService } from '../../services/dashboard.service';
import { Subscription } from 'rxjs';
import { Dashboard } from '../../interfaces/dashboard';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
  ],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.css'
})
export class NavBarComponent implements OnInit, OnDestroy {
  // Dependency Injection
  authService = inject(AuthService);
  dashboardService = inject(DashboardService);

  // User Authentication
  private authenticatedUserSubscription: Subscription | undefined;
  authenticatedUser = this.authService.authenticatedUser;
  userIsAdmin = this.authService.isAdmin;

  // Dashboards
  private dashboardsUpdatedSubscription: Subscription | undefined;
  private dashboardsLoadingSubscription: Subscription | undefined;
  dashboards: Dashboard[] = [];
  dashboardsCount = 0;
  dashboardLink = "/dashboards";
  loadingDashboards = false;
  dropdownOpen = false;

  env = environment;

  constructor(
  ) { }
  
  ngOnInit(): void {
    // User Authentication
    // Subscribe to AuthService's authenticatedUserSubject (event listener) to get notified when Firebase Authentication state changes (user signs in/signs out or idToken is refreshed)
    this.authenticatedUserSubscription = this.authService.authenticatedUserSubject.subscribe(authenticateUser => {
      console.log("NavBarComponent notified by authenticatedUserSubscription", this.authService.authenticatedUser);
      if(authenticateUser) {
        this.authenticatedUser = authenticateUser;
        this.userIsAdmin = this.authService.isAdmin;
      }  
      else this.authenticatedUser = null;
    });

    // Subscribe to DashboardService's dashboardsLoading() Subject (event listener) to be notified when list of dashboards is being loaded
    this.dashboardsLoadingSubscription = this.dashboardService.dashboardsLoading.subscribe((isLoading) => {
      console.log('NavBarComponent notified of dashboard list loading');
      this.loadingDashboards = isLoading;
    });
    // Subscribe to DashboardService's dashboardsUpdated() Subject (event listener) to be notified when list of dashboards is changed
    this.dashboardsUpdatedSubscription = this.dashboardService.dashboardsUpdated.subscribe(() => {
      console.log('NavBarComponent notified of dashboard list modified');
      this.dashboards = this.dashboardService.dashboards;
      this.dashboardsCount = this.dashboardService.countDashboards();
      if(this.dashboardService.countDashboards() == 1 && this.dashboardService.dashboards[0].id) {
        // If only one dashboard, use it as direct link instead of Dashboards page
        this.dashboardLink = `/dashboards/${this.dashboardService.dashboards[0].id}`;
      }
    });
    
  }

  ngOnDestroy(): void {
    // User Authentication
    // Unsubscribe from authenticatedUserSubscription() Subject (event listener)
    this.authenticatedUserSubscription?.unsubscribe();

    // Unsubscribe from dashboardsUpdated() Subject (event listener)
    console.log("Unsubscribing to dashboardService.dashboardsUpdated Subject...");
    this.dashboardsUpdatedSubscription?.unsubscribe();
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  closeDropdown() {
    this.dropdownOpen = false;
  }

}
