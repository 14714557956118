export class AuthenticatedUser {
    public uid: string;
    public email: string;
    public name: string;
    public photo?: string;
    public created?: Date;
    public isActive?: boolean;
    public manageAccounts?: string[];

    constructor(uid: string, email: string, name: string, photo?: string, created?: Date, isActive?: boolean, manageAccounts?: string[]) {
        this.uid = uid;
        this.email = email;
        this.name = name;
        if(photo) this.photo = photo;
        if(created) this.created = created;
        if(isActive) this.isActive = isActive;
        if(manageAccounts) this.manageAccounts = manageAccounts;
    }
}