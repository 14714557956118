import { Component, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { User } from '../../interfaces/user';
import { Dashboard, DashboardMetrics } from '../../interfaces/dashboard';
import * as _ from 'lodash';
import moment from 'moment';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    // RouterOutlet,
    // RouterLinkActive,
    NgbTooltip
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent implements OnInit {
  // Dependency Injection  
  router: Router = inject(Router);
  authService = inject(AuthService);
  apiService = inject(ApiService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  authToken?: string;

  loadingUsers = false;
  users: User[] = [];

  loadingDashboards = false;
  dashboards: Dashboard[] = [];
  dashboardMetrics: {[key: string]: DashboardMetrics} = {};
  dashboardAdsManagerSpend: {[key: string]: {date: string, spend: number}} = {};

  accountAssetsCache: {account_id: string, date: string, size: number, etag: string}[] = [];
  loadingAccountAssetsCache = false;

  ngOnInit(): void {
    // Check that user is authenticated & ADMIN (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT sign in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else if(!this.authService.isAdmin) {
        // User is NOT Admin => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // ADMIN is signed in
        this.authService.getIdToken()
        .then(token => {
              console.log("authService getIdToken()", token);
              this.authToken = token;
              // Implement page logic
              this.getUsers();
              this.getDashboards();

        }).catch(error => {
          console.log("getIdToken error", error);
        });
      }
    });
  }

  getUsers() {
    return new Promise<User[]>((resolve, reject) => {
      this.loadingUsers = true;
      let request = '/admin?get=users';
      this.apiService.get(request).then((data) => {
        console.log('getUsers Data', data);
        this.loadingUsers = false;
        _.forEach(data.data, (user) => {
          this.users.push({
            id: user.id,
            email: user.email,
            name: user.name,
            photo: user.photo,
            isActive: user.isActive,
            signedIn: user.signedIn,
            updated: user.updated,
            created: user.created,
            dashboards: user.dashboards
          });
        });
        resolve(this.users);
      })
      .catch((error) => {
        console.log('Error', error);
        this.loadingUsers = false;
        reject(error.message);
      })   
    });
  }

  getDashboards() {
    return new Promise<Dashboard[]>((resolve, reject) => {
      this.loadingDashboards = true;
      let request = '/admin?get=dashboards';
      this.apiService.get(request).then((data) => {
        console.log('getDashboards Data', data);
        this.loadingDashboards = false;
        _.forEach(data.data, (dashboard) => {
          this.dashboards.push({
            id: dashboard.id,
            name: dashboard.name,
            logo: dashboard.logo,            
            account_dataset: dashboard.account_dataset,
            account_id: dashboard.account_id
          });
          if(dashboard.account_dataset) {
            let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
            // Get Dashboard Metrics for yesterday
            this.getDashboardMetricsForDate(dashboard.account_dataset, yesterday)
            .then((metrics) => {
              console.log('getDashboardMetricsForDate', metrics);
              this.dashboardMetrics[dashboard.account_dataset] = {
                date: yesterday
              };
              if(metrics.data && metrics.data.scanned) {
                this.dashboardMetrics[dashboard.account_dataset] = {
                  date: metrics.data.date,
                  ads: metrics.data.ads,
                  spend: metrics.data.spend,
                  scanned: metrics.data.scanned,
                  scanned_ago: moment.utc(metrics.data.scanned).fromNow()
                };
              }
            });
            // Get Meta Ads Manager Spend for yesterday
            this.getAccountSpendForDate(dashboard.account_id, yesterday)
            .then((meta_data) => {
              console.log('getAccountSpendForDate meta_data', meta_data);
              if(meta_data) {
                this.dashboardAdsManagerSpend[dashboard.account_dataset] = {
                  date: yesterday,
                  spend: meta_data.data
                };
              }
            });
            // Check Account Cache
            this.getAccountCache(dashboard.account_id)
            .then((cache_data) => {
              console.log('getAccountCache cache_data', dashboard.account_id, cache_data);
              if(cache_data) {
                this.accountAssetsCache.push({
                  account_id: dashboard.account_id,
                  date: cache_data.data.last_modified,
                  size: cache_data.data.size,
                  etag: cache_data.data.etag
                });
              }
            });
          }
        });
        resolve(this.dashboards);
      })
      .catch((error) => {
        console.log('Error', error);
        this.loadingDashboards = false;
        reject(error.message);
      })   
    });
  }

  getDashboardMetricsForDate(dashboardDataset: string, date: string) {
    return new Promise<any>((resolve, reject) => {
      let request = '/admin?get=dashboard-date-metrics&dataset=' + dashboardDataset + '&date=' + date;
      this.apiService.get(request).then((data) => {
        console.log('getDashboardMetricsForDate Data', data);
        resolve(data);
      })
      .catch((error) => {
        console.log('Error', error);
        reject(error.message);
      })   
    });
  }

  getAccountSpendForDate(accountId: string, date: string) {
    return new Promise<any>((resolve, reject) => {
      let request = '/admin?get=account-spend&account=' + accountId + '&date=' + date;
      this.apiService.get(request).then((data) => {
        console.log('getAccountSpendForDate Data', data);
        resolve(data);
      })
      .catch((error) => {
        console.log('Error', error);
        reject(error.message);
      })   
    });
  }

  getAccountCache(accountId: string) {
    return new Promise<any>((resolve, reject) => {
      this.loadingAccountAssetsCache = true;
      let request = '/admin?get=cache-account-check&account=' + accountId;
      this.apiService.get(request).then((data) => {
        this.loadingAccountAssetsCache = false;
        console.log('getAccountCache Data', data);
        resolve(data);
      })
      .catch((error) => {
        console.log('Error', error);
        this.loadingAccountAssetsCache = false;
        reject(error.message);
      })   
    });
  }

  humanFileSize(size: number): string {
    // Convert file size to human readable format
    if(size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];   
  }

}
