<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <nav style="margin-bottom: 50px; --bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ accountId }}">{{ accountId | titlecase }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Naming Convention Auditor</li>
                </ol>
            </nav>

            <div *ngIf="namingConventionLoading" style="color: grey;">
              <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>Loading the naming convention<span class="ellipsisAnimated"></span>
            </div>

            <div *ngIf="namingConventionLoaded">
                <div *ngIf="!namingConvention || !namingConvention.fields" class="alert alert-warning d-flex align-items-center" style="margin-bottom: 20px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <div>No Naming Convention found. Syntax check only.</div>
                </div>
                <div *ngIf="loadingCampaigns" style="color: grey;">
                    <span class="spinner-border text-dark" role="status" style="margin-right: 10px; vertical-align: middle;"><span class="visually-hidden">Loading...</span></span>
                    Loading campaigns<span class="ellipsisAnimated"></span>
                </div>
                <div *ngIf="!loadingCampaigns">
                    <div class="row align-items-center" style="text-align: right; margin-bottom: 20px;">
                        <div class="col">
                        </div>
                        <div class="col-auto">
                            <div class="input-group">
                                <div class="input-group-text">Campaigns</div>
                                <div class="form-control border d-flex align-items-center">
                                    <a class="filter-link" (click)="toggleActiveCampaigns()" [ngbTooltip]="'Show/Hide Active Campaigns'">
                                        <span class="filter-toggles">
                                            <span *ngIf="showActiveCampaigns"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showActiveCampaigns"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Active</span>
                                    </a>
                                    <a class="filter-link" (click)="toggleNonActiveCampaigns()" [ngbTooltip]="'Show/Hide Inactive Campaigns'" style="margin-left: 10px;">
                                        <span class="filter-toggles">
                                            <span *ngIf="showNonActiveCampaigns"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showNonActiveCampaigns"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Inactive</span>
                                    </a>                        
                                </div>                            
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="input-group">
                                <div class="input-group-text">Ad sets</div>
                                <div class="form-control border d-flex align-items-center">
                                    <a class="filter-link" (click)="toggleActiveAdsets()" [ngbTooltip]="'Show/Hide Active Ad Sets'">
                                        <span class="filter-toggles">
                                            <span *ngIf="showActiveAdsets"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showActiveAdsets"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Active</span>
                                    </a>
                                    <a class="filter-link" (click)="toggleNonActiveAdsets()" [ngbTooltip]="'Show/Hide Inactive Ad Sets'" style="margin-left: 10px;">
                                        <span class="filter-toggles">
                                            <span *ngIf="showNonActiveAdsets"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showNonActiveAdsets"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Inactive</span>
                                    </a>                        
                                </div>                            
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="input-group">
                                <div class="input-group-text">Ads</div>
                                <div class="form-control border d-flex align-items-center">
                                    <a class="filter-link" (click)="toggleCompliantAds()" [ngbTooltip]="'Show/Hide Compliant Ads'">
                                        <span class="filter-toggles">
                                            <span *ngIf="showCompliantAds"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showCompliantAds"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Compliant</span>
                                    </a>
                                    <a class="filter-link" (click)="toggleNonCompliantAds()" [ngbTooltip]="'Show/Hide Non Compliant Ads'" style="margin-left: 10px;">
                                        <span class="filter-toggles">
                                            <span *ngIf="showNonCompliantAds"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showNonCompliantAds"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Non-Compliant</span>  
                                    </a>  
                                    <a class="filter-link" (click)="toggleActiveAds()" [ngbTooltip]="'Show/Hide Active Ads'" style="margin-left: 10px;">
                                        <span class="filter-toggles">
                                            <span *ngIf="showActiveAds"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showActiveAds"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Active</span>
                                    </a>
                                    <a class="filter-link" (click)="toggleNonActiveAds()" [ngbTooltip]="'Show/Hide Inactive Ads'" style="margin-left: 10px;">
                                        <span class="filter-toggles">
                                            <span *ngIf="showNonActiveAds"><i class="bi bi-toggle-on"></i></span>
                                            <span *ngIf="!showNonActiveAds"><i class="bi bi-toggle-off"></i></span>
                                        </span>
                                        <span>Inactive</span>
                                    </a>                        
                                </div>
                            </div>                            
                        </div>
                    </div>
                    
                    <div *ngFor="let campaign of campaigns;" [class.campaign-active]="campaign.status === 'ACTIVE'"  [class.campaign-non-active]="campaign.status !== 'ACTIVE'" class="list-group" style="margin-bottom: 30px;">
                        <div class="list-group-item" style="background-color: black;">
                            <div class="row align-items-center">
                                <div class="col" [style.color]="campaign.nc_compliant ? (campaign.status === 'ACTIVE' ? 'white' : 'grey') : (campaign.status === 'ACTIVE' ? 'pink' : 'grey')" style="font-weight: bold; word-break: break-all;">
                                    <span style="margin-right: 5px;" [ngbTooltip]="(campaign.nc_compliant ? 'Compliant' : 'Non Compliant')+' Campaign Name'">{{ campaign.nc_compliant ? '&#9989;' : '&#10060;'}}</span>
                                    <a href="https://adsmanager.facebook.com/adsmanager/manage/campaigns?act={{ namingConvention.meta_id }}&filter_set=SEARCH_BY_CAMPAIGN_GROUP_ID-STRING%1EEQUAL%1E%22{{ campaign.id }}%22&breakdown_regrouping=true&nav_source=no_referrer" style="margin-left: 5px; cursor: pointer;" target="_blank">
                                        <span *ngIf="campaign.status === 'ACTIVE'" style="vertical-align: bottom; color: #FFF; margin-right: 5px;" [ngbTooltip]="'Campaign status: '+campaign.status+' (Open in Ads Manager)'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16"><path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/><path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/></svg></span>
                                        <span *ngIf="campaign.status !== 'ACTIVE'" style="vertical-align: bottom; color: grey; margin-right: 5px;" [ngbTooltip]="'Campaign status: '+campaign.status+' (Open in Ads Manager)'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16"><path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/><path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/></svg></span>
                                    </a>
                                    {{ campaign.name }}
                                </div>
                                <div class="col" style="text-align: right; max-width: 70px;">
                                    <span *ngIf="!campaign.adsSetsLoading" (click)="loadCampaign(campaign.id)" [ngbTooltip]="'Analyse campaign'" class="btn btn-sm btn-light" style="cursor: pointer; border: 1px solid #FFF; border-radius: 3px; padding: 1px 4px;"><i class="bi bi-arrow-clockwise"></i></span>
                                    <span *ngIf="campaign.adsSetsLoading" class="spinner-border spinner-border-sm text-white" role="status"><span class="visually-hidden">Loading...</span></span>
                                </div>
                            </div>                            
                        </div>
                        <div class="list-group-item" style="padding-bottom: 0;">
                            <div *ngFor="let adset of campaign.adSets;" [style.color]="adset.nc_compliant ? (adset.status === 'ACTIVE' ? 'black' : 'grey') : (adset.status === 'ACTIVE' ? 'red' : 'pink')" [class.adset-active]="adset.status === 'ACTIVE'"  [class.adset-non-active]="adset.status !== 'ACTIVE'" style="margin: 10px 5px 20px 0; font-size: 0.9em; font-weight: normal; word-break: break-all;">
                                <div style="margin-bottom: 10px;">
                                    <div class="row g-0">
                                        <div class="col" style="max-width: 60px;">
                                            <span style="margin-right: 10px;" [ngbTooltip]="(adset.nc_compliant ? 'Compliant' : 'Non Compliant')+' Ad Set Name'">{{ adset.nc_compliant ? '&#9989;' : '&#10060;'}}</span>
                                            <a href="https://adsmanager.facebook.com/adsmanager/manage/adsets?act={{ namingConvention.meta_id }}&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E%22{{ adset.id }}%22&breakdown_regrouping=true&nav_source=no_referrer" style="margin-left: 0px; cursor: pointer;" target="_blank">
                                                <span *ngIf="adset.status === 'ACTIVE'" style="vertical-align: bottom; color: #2474ff; margin-right: 5px;" [ngbTooltip]="'Ad set status: '+adset.status+' (Open in Ads Manager)'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16"><path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/><path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/></svg></span>
                                                <span *ngIf="adset.status !== 'ACTIVE'" style="vertical-align: bottom; color: grey; margin-right: 5px;" [ngbTooltip]="'Ad set status: '+adset.status+' (Open in Ads Manager)'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16"><path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/><path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/></svg></span>
                                            </a>
                                        </div>
                                        <div class="col" style="font-weight: bold;">
                                            {{ adset.name }}
                                        </div>
                                        <div class="col" style="text-align: right; max-width: 55px;">
                                            <a [routerLink]="'/ad-accounts/nc-generator/' + namingConvention.id" [queryParams]="{ name: adset.name, adset: adset.id, adset_name: adset.name }" [ngbTooltip]="'Open in Naming Convention Generator'"><i class="bi bi-card-heading"></i></a>
                                            <a href="https://adsmanager.facebook.com/adsmanager/manage/adsets?act={{ namingConvention.meta_id }}&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E%22{{ adset.id }}%22&breakdown_regrouping=true&nav_source=no_referrer" [ngbTooltip]="'Open in Ads Manager'" style="margin-left: 10px; cursor: pointer;" target="_blank"><i class="bi bi-meta"></i></a>
                                        </div>
                                    </div>                               
                                </div>
        
                                <div *ngFor="let ad of adset.ads;" [style.background-color]="ad.nc_compliance.valid ? '#f1fff1' : '#fffbfb'" [class.ad-compliant]="ad.nc_compliance.valid"  [class.ad-non-compliant]="!ad.nc_compliance.valid" [class.ad-active]="ad.status === 'ACTIVE'"  [class.ad-non-active]="ad.status !== 'ACTIVE'" [style.color]="ad.nc_compliance.valid ? (ad.status === 'ACTIVE' ? 'black' : 'grey') : (ad.status === 'ACTIVE' ? 'red' : 'pink')" [style.border-color]="ad.nc_compliance.valid ? '#48c448' : '#ffc5c5'" style="border-width: 1px; border-style: solid; border-radius: 3px; padding: 3px 7px; margin-bottom: 8px; margin-left: 15px; margin-right: 5px; font-size: 0.9em; font-weight: normal; word-break: break-all;">
                                    <div class="row g-0">
                                        <div class="col" style="max-width: 60px;">
                                            <span style="margin-right: 5px;" [ngbTooltip]="(ad.nc_compliance.valid ? 'Compliant' : 'Non Compliant')+' Ad Name'">{{ ad.nc_compliance.valid ? '&#9989;' : '&#10060;'}}</span>
                                            <a href="https://adsmanager.facebook.com/adsmanager/manage/ads?act={{ namingConvention.meta_id }}&filter_set=SEARCH_BY_ADGROUP_ID-STRING%1EEQUAL%1E%22{{ ad.id }}%22&breakdown_regrouping=true&nav_source=no_referrer" style="margin-left: 5px; cursor: pointer;" target="_blank">
                                                <span *ngIf="ad.status === 'ACTIVE'" style="vertical-align: bottom; color: #2474ff; margin-right: 3px;" [ngbTooltip]="'Ad status: '+ad.status+' (Open in Ads Manager)'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16"><path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/><path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/></svg></span>
                                                <span *ngIf="ad.status !== 'ACTIVE'" style="vertical-align: bottom; color: grey; margin-right: 3px;" [ngbTooltip]="'Ad status: '+ad.status+' (Open in Ads Manager)'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16"><path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/><path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/></svg></span>
                                            </a>
                                        </div>
                                        <div class="col">
                                            <span *ngIf="ad.nc_compliance.parsed_name && ad.nc_compliance.parsed_name.length > 0; else fallbackAdName">
                                                <span *ngFor="let field of ad.nc_compliance.parsed_name; let i = index" [ngbTooltip]="field.valid ? '' : field.error" style="cursor: help;">
                                                    <span *ngIf="i > 0">_</span>
                                                    <span 
                                                        [style.font-weight]="field.valid ? 'normal' : 'bold'"
                                                        [style.border]="field.valid ? 'none' : '1px solid #ff6363'"
                                                        [style.border-radius]="field.valid ? '0' : '4px'"
                                                        [style.background-color]="field.valid ? 'transparent' : '#dc3545'"
                                                        [style.color]="ad.nc_compliance.valid ? 'black' : (field.valid ? 'red' : 'white')"
                                                        [style.padding]="field.valid ? '0px' : '0 4px'"
                                                    >{{ field.value_detected }}</span>
                                                </span>
                                            </span>
                                            <ng-template #fallbackAdName>
                                                {{ ad.name }}
                                            </ng-template>
                                            <div *ngIf="!ad.nc_compliance.valid">
                                                <div>
                                                    <span *ngFor="let error of ad.nc_compliance.general_errors" class="badge rounded-pill bg-danger" style="margin-right: 5px;">{{ error }}</span>
                                                    <span *ngIf="!ad.nc_compliance.semantic_valid" class="badge rounded-pill bg-danger">Semantic error detected:</span>
                                                </div>
                                                <div>
                                                    <span *ngFor="let field of ad.nc_compliance.parsed_name" class="badge rounded-pill bg-light text-dark" [ngbTooltip]="field.valid ? '' : field.error" style="margin-right: 3px; cursor: help;">
                                                        <span [style.font-weight]="field.valid ? 'normal' : 'bold'">{{ field.field_name }}:</span><span style="margin-left: 3px; margin-right: 3px;" [style.color]="field.valid ? 'black' : 'red'" [style.font-weight]="field.valid ? 'normal' : 'bold'">{{ (field.value_detected && field.value_detected.length > 0) ? field.value_detected : '[EMPTY]' }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col" style="text-align: right; max-width: 55px;">
                                            <a [routerLink]="'/ad-accounts/nc-generator/' + namingConvention.id" [queryParams]="{ name: ad.name, ad: ad.id, adset: adset.id, adset_name: adset.name }" [ngbTooltip]="'Open in Naming Convention Generator'"><i class="bi bi-card-heading"></i></a>
                                            <a href="https://adsmanager.facebook.com/adsmanager/manage/ads?act={{ namingConvention.meta_id }}&filter_set=SEARCH_BY_ADGROUP_ID-STRING%1EEQUAL%1E%22{{ ad.id }}%22&breakdown_regrouping=true&nav_source=no_referrer" [ngbTooltip]="'Open in Ads Manager'" style="margin-left: 10px; cursor: pointer;" target="_blank"><i class="bi bi-meta"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="adset.adsLoading" style="color: grey;">
                                    <span class="spinner-border spinner-border-sm text-dark" role="status" style="margin-right: 5px;"><span class="visually-hidden">Loading...</span></span>                            
                                    Loading ads<span class="ellipsisAnimated"></span>
                                </div>
                            </div>
                            <div *ngIf="campaign.adsSetsLoading" style="color: grey; margin: 10px 10px 20px 10px;">
                                <span class="spinner-border spinner-border-sm text-dark" role="status" style="margin-right: 5px;"><span class="visually-hidden">Loading...</span></span>                            
                                Loading ad sets<span class="ellipsisAnimated"></span>
                            </div>                    
                        </div>
                        
                    </div>
                </div>
                

            </div>




        </div>    
    </div>
</div>