import { Component, HostListener, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { SidebarService } from '../../services/sidebar.service';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  // Dependency Injection  
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  apiService: ApiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);
  sanitizer: DomSanitizer = inject(DomSanitizer);
  sidebarService: SidebarService = inject(SidebarService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;
  authToken?: string;

  loadingDashboard = false;
  backgroundColor = '#EEEEEE';
  iframeUrl: SafeResourceUrl | null = null;
  iframeHeight: string = '730px';  // Default height

  fullScreenMode = false; // Full screen mode

  ngOnInit(): void {

    this.adjustIframeHeight();  // Adjust height on init
    this.loadingDashboard = true;

    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT sign in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        this.authService.getIdToken().then(token => {
          console.log("authService getIdToken()", token);
          this.authToken = token;

          this.activatedRoute.params.subscribe((params: Params) => {
            let dashboardId = params['id'];
            console.log(`Loading Dashboard #${dashboardId}`);

            let requestPath = '/dashboards/?get='+dashboardId;
            let requestParams = { params:
              new HttpParams()
                // .set('get', dashboardId)
            }
            this.loadingDashboard = true;
            this.apiService.get(requestPath, requestParams)
            .then(response => {
              console.log("apiService Get response", response);        
              this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.data.lookerUrl);

              this.authService.sleep(3000).then(() => {
                this.loadingDashboard = false;
              });

            })
            .catch(error => {
              console.log("apiService Get error", error.message);
              this.backgroundColor = "transparent";
              this.loadingDashboard = false;
              this.toastrService.error(error.message, 'Dashboard error');
            })
            
          })
        }).catch(error => {
          console.log("getIdToken error", error);
        });        
      }  
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.adjustIframeHeight();
  }  

  // Adjust the iframe height based on the window size
  adjustIframeHeight(): void {
    console.log('adjustIframeHeight()', window.innerHeight);
    this.iframeHeight = `${window.innerHeight - 60}px`;
  }

  triggerSidebarToggle(): void {
    this.sidebarService.toggleSidebar();
    this.fullScreenMode = !this.fullScreenMode;
  }
  
}

