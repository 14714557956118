import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { RouterLink, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { CreativeAnalysisService } from '../../../services/creative-analysis.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CreativeAnalysisImage, CreativeAnalysisVideo } from '../../../interfaces/creative-analysis';


@Component({
  selector: 'app-creative-analysis',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    NgbTooltipModule
  ],
  templateUrl: './creative-analysis.component.html',
  styleUrl: './creative-analysis.component.css'
})
export class CreativeAnalysisComponent implements OnInit {
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  creativeAnalysisService: CreativeAnalysisService = inject(CreativeAnalysisService);
  apiService: ApiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  accountId: string = '';
  creativeId: string | null = null; // User supplied creative name
  images: CreativeAnalysisImage[] = [];
  // images: {
  //   id: string,
  //   file: string,
  //   status: string,
  //   thumbnail: string
  // }[] = [];
  loadingImages = false;
  videos: CreativeAnalysisVideo[] = [];
  loadingVideos = false;

  ngOnInit(): void {
    
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get Ad Account ID from URL params
        this.activatedRoute.params.subscribe((params: Params) => {
          this.accountId = params['id'];
          console.log(`Loading Ad Account#: ${this.accountId}`);

          this.loadingImages = true;
          this.creativeAnalysisService.listCreatives(this.accountId, 'img').then(images => {
            console.log("Images", images);
            this.images = images;
            this.loadingImages = false;
          });

          this.loadingVideos = true;
          this.creativeAnalysisService.listCreatives(this.accountId, 'vid').then(videos => {
            console.log("Videos", videos);
            this.videos = videos;
            this.loadingVideos = false;
          });

          // Check if the user has access to the Ad Accounts section & this specific ad account
          this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
            if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.accountId)) {
              // User does not have access to the account (or the user has no ad accounts at all)
              console.log("User does not have access to this ad account");
              this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
              this.router.navigate(['/']);
            } else {
              // User has access to the account
              console.log("User has access to this ad account");

            }        
          });
        });
      }  
    });
  }


  onCreativeIdEnter() {
    console.log('onCreativeIdEnter() triggered', this.creativeId);
    // Validate the creative ID as IMGXXX, VIDXXX or CARXXX
    if(this.creativeId === null || this.creativeId === undefined || this.creativeId === '') {
      this.toastrService.error("Creative ID cannot be empty", "Invalid Creative ID");
      return;
    }
    if(!this.creativeId.match(/^(IMG|VID|CAR)\d{3}$/)) {
      this.toastrService.error("Creative ID must be in the format IMGXXX, VIDXXX or CARXXX", "Invalid Creative ID");
      return;
    }
    // Navigate to the single creative analysis page
    this.router.navigate(['/ad-accounts/creative-assistant', this.accountId, this.creativeId]);

  }










}
