//
// WARNING: Any changes to this file must come ONLY from the "Hubble Pioneer Naming Conventions Master Regex Patterns" document and be reflected in the BACKEND as well (meta.js)
// https://www.notion.so/webprofits/Hubble-Pioneer-Naming-Conventions-Master-Regex-Patterns-16235e677f078094a3bdd2d82c6d8930?pvs=4
//
export const regexPatterns = {
    date: /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/, // Date in format YYYYMMDD
    positiveInteger: /^[1-9]\d*$/, // Positive integers, excluding 0
    phase: /^PH[1-5]$/, // Phase: PH1, PH2, PH3, PH4, PH5
    uid: /^[A-Za-z0-9\-+&$]{1,50}$/, // Unique ID alphanumeric, min 1 character, max 50 characters
    free_text: /^[A-Za-z0-9\-+&$]{0,50}$/, // free_text: Alphanumeric, max 50 characters
    audience_type: /^(BROAD|MULTIPLELISTS|WARM|INT(\d{3}|XXX)|LAL(\d{3}|XXX)|CUS(\d{3}|XXX)|REM(\d{3}|XXX)|XXX)$/, // audience_type
    exclusion_type: /^EXL(\d{3}|XXX)$/, // exclusion_type: EXLXXX or XXX    
    test_id: /^([A-Z]{3}|XXX)TST(\d{3}|XXX)$/, // test_id: 3 letters or XXX + TST + 3 digits or XXX (i.e. XXXTSTXXX)
    creative: /^(IMG|VID|CAR|PDS|DYN)(\d{3}|XXX)$/, // creative: Only IMG, VID or CAR + 3 digits or XXX (i.e. IMG001)
    headline: /^(HDL|MXD|DYN)(\d{3}|XXX)$/, // headline: HDL + 3 digits or XXX (i.e. HDL001)
    primary_text: /^(PRT|MXD|DYN)(\d{3}|XXX)$/, // primary_text: PRT + 3 digits or XXX (i.e. PRT001)
    description: /^(DES|MXD|DYN)(\d{3}|XXX)$/, // description: DES + 3 digits or XXX (i.e. DES001)
    creative_url: /^URL(\d{3}|XXX)$/, // creative_url: URL + 3 digits or XXX (i.e. URL001)
    post_id: /^POSTID(\d{5,}|XXX)$/, // post_id: POSTID + at least 5 digits or XXX (i.e. POSTID123456789)
    ugc_round: /^R(0?[1-9]|[1-9][0-9]|X)$/, // ugc_round: R1, R2, …, R9, R01, R02, …, R09, R10, R11, …, R99, RX
};