import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Dashboard } from '../interfaces/dashboard';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  // Dependency Injection
  private apiService: ApiService = inject(ApiService);  

  dashboards: Dashboard[] = [];

  // Track if the service is currently loading dashboards from the database to prevent firing multiple concurrent calls to the API
  loadingDashboards = false;

  // Create Subject (event listener) used to notify other components when the list of Dashboards is updated
  // Don't forget to implement unsubscribe() in ngOnDestroy() in components subscribing to this Subject
  dashboardsUpdated = new Subject<boolean>();

  // Create Subject (event listener) used to notify other components when the list of Dashboards is being loaded
  // Don't forget to implement unsubscribe() in ngOnDestroy() in components subscribing to this Subject
  dashboardsLoading = new Subject<boolean>();

  constructor() { 
    console.log("[DashboardService] Initialising DashboardService");
  }

  clearDashboards() {
    this.dashboards = [];
  }

  countDashboards() {
    return this.dashboards.length;
  }

  async getDashboards() {
    return new Promise<Dashboard[]>((resolve, reject) => {
      console.log("[DashboardService] getDashboards()");   
      // Notify components listening to list of Dashboards being loaded from the server
      this.dashboardsLoading.next(true);
      this.loadingDashboards = true;
      let requestPath = '/dashboards/?list=all';
        let requestParams = { params:
          new HttpParams()
        }
        this.apiService.get(requestPath, requestParams)
        .then(response => {
          // console.log("[DashboardService] Get response", response);   
          // Notify components listening to list of Dashboards being loaded from the server
          this.dashboardsLoading.next(false);
          this.loadingDashboards = false;
          let dashboards: Dashboard[] = [];   
          _.forEach(response.data, (dashboard) => {
            let thisDashboard: Dashboard = {
              id: dashboard.id,
              name: dashboard.name,
              lookerUrl: dashboard.lookerUrl,
              account_id: dashboard.account,
            };
            if(dashboard.account) thisDashboard.logo = `${dashboard.account}-logo.png`;
            dashboards.push(thisDashboard);
          });
          console.log("[DashboardService] Dashboards retrieved", dashboards);   
          this.dashboards = dashboards;
          // Notify components listening to list of Dashboards being updated (new dashboard created or existing dashboard deleted)
          this.dashboardsUpdated.next(true);
          resolve(dashboards);
        })
        .catch(error => {
          console.log("[DashboardService]  Get error", error.message);
          // Notify components listening to list of Dashboards being loaded from the server
          this.dashboardsLoading.next(false);
          this.loadingDashboards = false;
          reject(error.message);
        })
    });
  }

}
