<div class="container">

    <div class="row" style="margin: 30px;">
        <div class="col">

            <h1>Admin</h1>
            <p style="margin-bottom: 40px;">Admin interface</p>

            <h2>Dashboards</h2>
            <table class="table table-bordered table-striped align-middle" style="margin-bottom: 40px;">
              <thead>
                <tr>
                  <th scope="col" style="text-align: center;"></th>
                  <th scope="col" style="text-align: center;">Date</th>
                  <th scope="col" style="text-align: center;">Ads</th>
                  <th scope="col" style="text-align: center;">Spend (Dashboard)</th>
                  <th scope="col" style="text-align: center;">Spend (Ads Manager)</th>
                  <th scope="col" style="text-align: center;">Scanned</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="loadingDashboards">
                  <td colspan="6" style="text-align: center;"><span class="spinner-border text-primary" role="status" aria-hidden="true" style="margin: 5px 0;"></span></td>
                </tr>
                <tr *ngFor="let dashboard of dashboards;">
                  <td>
                    <a routerLink="/dashboards/{{ dashboard.id }}">{{ dashboard.id }}</a>                    
                  </td>
                  <td style="text-align: center;">
                    <span *ngIf="dashboard.account_dataset && dashboardMetrics[dashboard.account_dataset] &&  dashboardMetrics[dashboard.account_dataset].date; else elseBlockDate">{{ dashboardMetrics[dashboard.account_dataset].date }}</span>
                    <ng-template #elseBlockDate>-</ng-template>
                  </td>
                  <td style="text-align: center;">
                    <span class="badge rounded-pill bg-secondary" *ngIf="dashboard.account_dataset && dashboardMetrics[dashboard.account_dataset] &&  dashboardMetrics[dashboard.account_dataset].ads; else elseBlockAds">{{ dashboardMetrics[dashboard.account_dataset].ads | number:'1.0-0' }}</span>
                    <ng-template #elseBlockAds>-</ng-template>
                  </td>
                  <td style="text-align: center;">
                    <span class="badge rounded-pill bg-dark" *ngIf="dashboard.account_dataset && dashboardMetrics[dashboard.account_dataset] &&  dashboardMetrics[dashboard.account_dataset].spend; else elseBlockSpend">{{ dashboardMetrics[dashboard.account_dataset].spend | number:'1.0-0' }}</span>
                    <ng-template #elseBlockSpend>-</ng-template>
                  </td>
                  <td style="text-align: center;">
                    <span class="badge rounded-pill bg-primary" *ngIf="dashboard.account_dataset && dashboardAdsManagerSpend[dashboard.account_dataset] &&  dashboardAdsManagerSpend[dashboard.account_dataset].spend; else elseBlockSpendMeta">{{ dashboardAdsManagerSpend[dashboard.account_dataset].spend | number:'1.0-0' }}</span>
                    <ng-template #elseBlockSpendMeta>-</ng-template>
                  </td>
                  <td style="text-align: center;">
                    <span *ngIf="dashboard.account_dataset && dashboardMetrics[dashboard.account_dataset] &&  dashboardMetrics[dashboard.account_dataset].date; else elseBlockScanned" ngbTooltip="{{ dashboardMetrics[dashboard.account_dataset].scanned }}">{{ dashboardMetrics[dashboard.account_dataset].scanned_ago }}</span>
                    <ng-template #elseBlockScanned>-</ng-template>
                  </td>
                </tr>
              </tbody>
            </table>

            <h2>Account Assets Cache</h2>
            <table class="table table-bordered table-striped align-middle" style="margin-bottom: 40px;">
              <thead>
                <tr>
                  <th scope="col" style="text-align: center;">Account</th>
                  <th scope="col" style="text-align: center;">Date</th>
                  <th scope="col" style="text-align: center;">Size</th>
                  <th scope="col" style="text-align: center;">ETag</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="loadingAccountAssetsCache">
                  <td colspan="4" style="text-align: center;"><span class="spinner-border text-primary" role="status" aria-hidden="true" style="margin: 5px 0;"></span></td>
                </tr>
                <tr *ngFor="let accountAssets of this.accountAssetsCache">
                  <td>{{ accountAssets.account_id }}</td>
                  <td style="text-align: center;">
                    {{ accountAssets.date | date:'medium'  }}
                  </td>
                  <td style="text-align: center;">
                    <span class="badge rounded-pill bg-secondary">{{ this.humanFileSize(accountAssets.size) }}</span>                    
                  </td>
                  <td style="text-align: center;">
                    <span class="badge rounded-pill bg-dark">{{ accountAssets.etag }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <h2>Users</h2>
            <table class="table table-bordered table-striped align-middle">
                <thead>
                  <tr>
                    <th scope="col" style="text-align: center;"></th>
                    <th scope="col" style="text-align: center;">Active</th>
                    <th scope="col" style="text-align: center;">Signed In</th>
                    <th scope="col" style="text-align: center;">Dashboards</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="loadingUsers">
                    <td colspan="4" style="text-align: center;"><span class="spinner-border text-primary" role="status" aria-hidden="true" style="margin: 5px 0;"></span></td>
                  </tr>
                  <tr *ngFor="let user of users;">
                    <td>
                      <a routerLink="/admin/user/{{ user.id }}">
                        <div>{{ user.email }}</div>
                        <div class="badge bg-secondary" style="margin-right: 20px;">{{ user.id }}</div>
                      </a>
                    </td>
                    <td style="text-align: center;">
                      <svg *ngIf="user.isActive" style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/></svg>
                      <svg *ngIf="!user.isActive" style="color: red;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/></svg>
                    </td>
                    <td>{{ user.signedIn | date:'medium' }}</td>
                    <td>
                        <span *ngFor="let dashboard of user.dashboards;" class="badge rounded-pill bg-dark" style="margin-right: 10px;">{{ dashboard }}</span>
                    </td>
                  </tr>
                </tbody>
            </table>
            
        </div>    

    </div>

</div>