<div style="text-align: center; margin: 35px 0 15px 0;">
    <a routerLink="/"><img src="assets/images/hubble-logo-white.svg" style="width: 115px; border: 0;"></a>
</div>

<div class="container" style="margin-top: 35px;">

    <div style="margin: 0 0 35px 0px; text-align: center;">
        <!-- <a routerLink="/account"><img src="{{ authenticatedUser?.photo ?? '/assets/images/avatar.png' }}" referrerpolicy="no-referrer" style="max-width: 60px; max-height: 60px; border-radius: 50%;" alt="{{ authenticatedUser?.email }}" title="{{ authenticatedUser?.email }}"></a> -->
        <!-- Profile image with loading ring -->
        <div class="profile-image">
            <a routerLink="/account">
                <img src="{{ authenticatedUser?.photo ?? '/assets/images/avatar.png' }}" 
                     referrerpolicy="no-referrer" 
                     style="max-width: 100%; border-radius: 50%;" 
                     alt="{{ authenticatedUser?.email }}" 
                     title="{{ authenticatedUser?.email }}">
            </a>
            <!-- Show ring while dashboards are being loaded -->
            <div *ngIf="loadingDashboards" class="profile-loading-ring"></div>
        </div>

    </div>

    <div *ngIf="dashboards.length >= 2" class="dropdown" style="margin-bottom: 25px;">
        <button class="dropdown-toggle" (click)="toggleDropdown()">
          Quick access
        </button>
        <div class="dropdown-menu" [class.show]="dropdownOpen">
          <a class="dropdown-item" *ngFor="let dashboard of dashboards" [routerLink]="['/dashboards', dashboard.id]" (click)="closeDropdown()">
            {{ dashboard.name }}
          </a>
        </div>
    </div>

    <ul class="list-group" style="font-family: 'Gelica', serif; font-size: 1.25em;">

        <li class="list-group-item">
            <a routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"><img src="assets/images/navbar-home.svg" class="icon">Home</a>
        </li>
        <li *ngIf="authenticatedUser" class="list-group-item">
            <a [routerLink]="dashboardLink" routerLinkActive="active-link"><img src="assets/images/navbar-dashboards.svg" class="icon">Dashboard<span *ngIf="dashboardsCount>1">s</span></a>
        </li>
        <li *ngIf="authenticatedUser && authenticatedUser.manageAccounts" class="list-group-item">
            <a routerLink="/ad-accounts" routerLinkActive="active-link"><img src="assets/images/meta-white.svg" class="icon">Ad Accounts</a>
        </li>
        <li class="list-group-item">
            <a routerLink="/support" routerLinkActive="active-link"><img src="assets/images/info-circle.svg" class="icon">Support</a>
        </li>
        <li *ngIf="authenticatedUser" class="list-group-item">
            <a routerLink="/account" routerLinkActive="active-link"><img src="assets/images/navbar-account.svg" class="icon">Account</a>
        </li>
        <li *ngIf="authenticatedUser && userIsAdmin" class="list-group-item">
            <a routerLink="/admin" routerLinkActive="active-link"><img src="assets/images/fingerprint-white.svg" class="icon">Admin</a>
        </li>
    </ul>    

</div>

<div style="margin-top: 20px; text-align: center; font-size: 75%; color: #000000;">
    --- {{ env.production ? 'prod' : 'dev' }}/{{ env.version }} ---
</div>

<a href="https://www.webprofits.com.au" target="_blank"><img src="assets/images/webprofits.svg" class="fixed-bottom-logo"></a>