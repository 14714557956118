import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { RouterLink, Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../services/api.service';
import { CreativeAnalysisService } from '../../../services/creative-analysis.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CreativeAnalysisImage, CreativeAnalysisVideo } from '../../../interfaces/creative-analysis';
import { environment } from '../../../../environments/environment';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { Subscription, interval } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-creative-analysis-batch',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    NgbTooltipModule,
    NgxJsonViewerModule
  ],
  templateUrl: './creative-analysis-batch.component.html',
  styleUrl: './creative-analysis-batch.component.css'
})
export class CreativeAnalysisBatchComponent implements OnInit {
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);
  authService: AuthService = inject(AuthService);
  creativeAnalysisService: CreativeAnalysisService = inject(CreativeAnalysisService);
  apiService: ApiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  // Component-specific variables
  accountId: string = '';

  environment = environment;

  requestedCreativeIds: string = '';
  maxFilesPerBatch = 10;

  numberOfCreatives: number | null = null;
  showSelectCreatives = true;

  selectedModel: 'openai' | 'gemini' = 'gemini';
  dropdownModelOpen = false;

  // Create a list of creatives to hold the creative analysis data (images and videos)
  creatives: (CreativeAnalysisImage | CreativeAnalysisVideo)[] = [];
  loadingCreatives = false;

  // A map to hold the polling state and subscription for each creative
  private pollingMap: { [creativeId: string]: { subscription: Subscription, isActive: boolean } } = {};

  ngOnInit(): void {    
    // Check that user is authenticated (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT signed in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // User is signed in
        // Get Ad Account ID from URL params
        this.activatedRoute.params.subscribe((params: Params) => {
          this.accountId = params['id'];          

          console.log(`Loading Ad Account#: ${this.accountId}`);
          // Check if the user has access to the Ad Accounts section & this specific ad account
          this.authService.checkUserHasAccessToAdAccounts().then(adAccounts => {
            if(adAccounts === null || !adAccounts.some(adAccount => adAccount.id === this.accountId)) {
              // User does not have access to the account (or the user has no ad accounts at all)
              console.log("User does not have access to this ad account");
              this.toastrService.error("You do not have access to this Ad Account", "Access Denied");
              this.router.navigate(['/']);
            } else {
              // User has access to the account
              console.log("User has access to this ad account");

            }        
          });
        });
      }  
    });
  }

  getCreatives(creativeIds: string[]): void {
    try {
      creativeIds.forEach((creativeId: string) => {
        console.log('forEach creative:', creativeId);
        const creativeIndex = this.creatives.findIndex(c => c.id === creativeId);
        this.creatives[creativeIndex].loading = true;
      });

      this.creativeAnalysisService.getCreatives(this.accountId, creativeIds)
      .then(creatives => {
        console.log('getCreatives:', creatives);
        this.loadingCreatives = false;
        // For each creative, update the thumbnail
        creatives.forEach((creative: any) => {
          console.log('Loaded creative:', creative);
          const creativeIndex = this.creatives.findIndex(c => c.id === creative.id);
          this.creatives[creativeIndex].thumbnail = creative.thumbnail;
          this.creatives[creativeIndex].loading = false;
          this.creatives[creativeIndex].analysing = false;
          this.creatives[creativeIndex].analysisStatus = '';
          this.creatives[creativeIndex].classified = creative.classified;
          this.creatives[creativeIndex].classificationId = creative.classification;
          if(creative.job && creative.job.result && creative.job.result.classification) {
            this.creatives[creativeIndex].mainAngle = creative.job.result.classification.main_angle;
            this.creatives[creativeIndex].mainAngleReasons = creative.job.result.classification.main_angle_reasons;
            this.creatives[creativeIndex].mainAngleAlternatives = creative.job.result.classification.main_angle_alternatives;
            this.creatives[creativeIndex].creativeStyle = creative.job.result.classification.creative_style;
            this.creatives[creativeIndex].creativeStyleAlternatives = creative.job.result.classification.creative_style_alternatives;
            this.creatives[creativeIndex].creativeStyleReasons = creative.job.result.classification.creative_style_reasons;
            this.creatives[creativeIndex].targetMarketDescription = creative.job.result.classification.target_market.description;
            this.creatives[creativeIndex].targetMarketReasons = creative.job.result.classification.target_market.reasons;
            this.creatives[creativeIndex].marketAwareness = creative.job.result.classification.market_awareness;
            this.creatives[creativeIndex].marketAwarenessReasons = creative.job.result.classification.market_awareness_reason
            this.creatives[creativeIndex].discount = creative.job.result.classification.discounts.discount;
            this.creatives[creativeIndex].discountCode = creative.job.result.classification.discounts.discount_code;
            this.creatives[creativeIndex].headline = creative.job.result.classification.headline;
            this.creatives[creativeIndex].personFeatureVisible = creative.job.result.classification.person_feature.visible;
            this.creatives[creativeIndex].personFeatureDescription = creative.job.result.classification.person_feature.description;
            this.creatives[creativeIndex].callToAction = creative.job.result.classification.call_to_action;
            this.creatives[creativeIndex].model = creative.job.result.classification.model;
            this.creatives[creativeIndex].detectedTexts = creative.job.result.classification.detected_texts;
            this.creatives[creativeIndex].usageTokensTotal = creative.job.result.classification.usage_tokens_total;
            this.creatives[creativeIndex].prompt = creative.job.result.classification.prompt;
          }
        });        
      })
      .catch(error => {
        console.error('Error fetching creative', error);
        this.loadingCreatives = false;
        if(error == 'CreativeNotFound') {
          this.toastrService.warning('Creative not found. Upload a file to get started.');
        } else {
          this.toastrService.error(`Error fetching creative [${error}]`);
        }
      });
    } catch (error) {
      console.error('Error fetching creative', error);
      this.loadingCreatives = false;
    }
  }

  updateRequestedCreativeIds(): void {
    if(this.requestedCreativeIds.length > 0) {
      this.creatives = this.requestedCreativeIds.split(',').map((id: string) => {
        return {
          id: id,
          thumbnail: '/assets/images/pix.gif'
        };
      });
      this.numberOfCreatives = this.creatives.length;

      if(this.numberOfCreatives !== null && this.numberOfCreatives > 0) {
        this.showSelectCreatives = false;
        this.getCreatives(this.creatives.map(creative => creative.id));
      }
    }
  }

  classifyCreative(creativeId: string) {
    // Get the index of the creative in the creatives array based on the creativeId
    const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
    this.creatives[creativeIndex].analysing = true;
    this.creatives[creativeIndex].analysisStatus = 'Processing...';
    this.creatives[creativeIndex].error = '';
    console.log('Classifying creative...', creativeId, creativeIndex);

    this.creativeAnalysisService.requestAnalysis(this.accountId, creativeId, this.selectedModel)
    .then((requestAnalysisResponse) => {
      console.log('Creative analysis requested', creativeId, requestAnalysisResponse);
      const jobID = requestAnalysisResponse.job;
      // this.toastrService.info('Creative analysis requested. Job ID: ' + jobID);
      this.creatives[creativeIndex].analysing = false;
      this.creatives[creativeIndex].analysisStatus = '';

      // Poll for job status
      console.log('Starting polling for job status...', creativeId, creativeId);
      this.startPolling(creativeId);

    }).catch(error => {
      console.error('Error getting S3 Upload URL', creativeId, error);
      this.creatives[creativeIndex].analysing = false;
      this.creatives[creativeIndex].analysisStatus = '';
    });
  }


  startPolling(creativeId: string): void {
    const pollingInterval = 2000; // 2 seconds
    const maxPollingTime = 120000; // 120 seconds

    // If there's already a polling process for this ID, stop it first
    if (this.pollingMap[creativeId]) {
      this.stopPolling(creativeId);
    }

    const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
    this.creatives[creativeIndex].analysing = true;
    this.creatives[creativeIndex].analysisStatus = 'Processing...';

    // Setup polling
    this.pollingMap[creativeId] = {
      subscription: new Subscription(),
      isActive: true
    }; // Store the polling info

    this.pollingMap[creativeId].subscription = interval(pollingInterval).pipe(
      switchMap(() => this.creativeAnalysisService.checkJobStatus(this.accountId, creativeId)),
      takeWhile(() => this.pollingMap[creativeId].isActive)  // Check the isActive flag specific to this creativeId
    ).subscribe(response => {
      console.log('Polling response', creativeId, response.status);
      if(response.status === 'completed') {
        console.log('Creative analysis completed', creativeId);
        this.loadCreativeAnalysis(creativeId);
        this.creatives[creativeIndex].analysing = false;
        this.creatives[creativeIndex].analysisStatus = '';
        this.stopPolling(creativeId);
      } else if(response.status === 'failed' || response.status === 'error') {
        console.error('Creative analysis failed', response);
        this.creatives[creativeIndex].analysing = false;
        this.creatives[creativeIndex].analysisStatus = '';
        this.creatives[creativeIndex].error = response.error;
        this.toastrService.error(response.error, `Creative analysis failed for ${creativeId}`);
        this.stopPolling(creativeId);
      }
    }, error => {
      console.error('Polling error:', error);
      this.creatives[creativeIndex].analysing = false;
      this.creatives[creativeIndex].analysisStatus = '';
      this.creatives[creativeIndex].error = error.message ?? error;
      this.toastrService.error(error.message ?? error, `Creative analysis failed for ${creativeId}`);
      this.stopPolling(creativeId);
    });

    // Stop polling after maxPollingTime
    setTimeout(() => {
      console.log('Polling timed out', creativeId);
      if(this.pollingMap[creativeId].isActive) {
        console.error(`Polling timed out after ${maxPollingTime / 1000} seconds`);
        this.creatives[creativeIndex].analysing = false;
        this.creatives[creativeIndex].analysisStatus = '';
        this.stopPolling(creativeId);
      }
    }, maxPollingTime);
  }

  stopPolling(creativeId: string) {
    if (this.pollingMap[creativeId] && this.pollingMap[creativeId].isActive) {
      this.pollingMap[creativeId].isActive = false;
      if (this.pollingMap[creativeId].subscription) {
        this.pollingMap[creativeId].subscription.unsubscribe();
      }
      console.log(`Polling stopped for ${creativeId}`);
      const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
      this.creatives[creativeIndex].analysisStatus = '';
    }
  }

  loadCreativeAnalysis(creativeId: string): void {
    console.log('loadCreativeAnalysis', creativeId);

    // Get the index of the creative in the creatives array based on the creativeId
    const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
    this.creatives[creativeIndex].analysing = true;
    this.creatives[creativeIndex].analysisStatus = 'Loading...';

    if(creativeId.startsWith('IMG')) {
      this.creativeAnalysisService.getCreativeAnalysisImage(this.accountId, creativeId).then(analysis => {
        console.log('Creative analysis:', analysis);
        // Get the index of the creative in the creatives array based on the creativeId
        const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
        if(creativeIndex !== -1) {
          this.creatives[creativeIndex].analysing = false;
          this.creatives[creativeIndex].classified = analysis.classified;
          this.creatives[creativeIndex].classificationId = analysis.classificationId;
          this.creatives[creativeIndex].analysisStatus = '';
          this.creatives[creativeIndex].mainAngle = analysis.mainAngle;
          this.creatives[creativeIndex].mainAngleReasons = analysis.mainAngleReasons;
          this.creatives[creativeIndex].mainAngleAlternatives = analysis.mainAngleAlternatives;
          this.creatives[creativeIndex].creativeStyle = analysis.creativeStyle;
          this.creatives[creativeIndex].creativeStyleAlternatives = analysis.creativeStyleAlternatives;
          this.creatives[creativeIndex].creativeStyleReasons = analysis.creativeStyleReasons;
          this.creatives[creativeIndex].targetMarketAgeCategory = analysis.targetMarketAgeCategory;
          this.creatives[creativeIndex].targetMarketGender = analysis.targetMarketGender;
          this.creatives[creativeIndex].targetMarketDescription = analysis.targetMarketDescription;
          this.creatives[creativeIndex].targetMarketReasons = analysis.targetMarketReasons;
          this.creatives[creativeIndex].marketAwareness = analysis.marketAwareness;
          this.creatives[creativeIndex].marketAwarenessReasons = analysis.marketAwarenessReasons;
          this.creatives[creativeIndex].discount = analysis.discount;
          this.creatives[creativeIndex].discountCode = analysis.discountCode;
          this.creatives[creativeIndex].headline = analysis.headline;
          this.creatives[creativeIndex].personFeatureVisible = analysis.personFeatureVisible;
          this.creatives[creativeIndex].personFeatureDescription = analysis.personFeatureDescription;
          this.creatives[creativeIndex].callToAction = analysis.callToAction;
          this.creatives[creativeIndex].model = analysis.model;
          this.creatives[creativeIndex].detectedTexts = analysis.detectedTexts;
          this.creatives[creativeIndex].usageTokensTotal = analysis.usageTokensTotal;
          this.creatives[creativeIndex].prompt = analysis.prompt;
          this.creatives[creativeIndex].reasons = analysis.reasons;
          this.creatives[creativeIndex].rawOutput = analysis.rawOutput;
          this.creatives[creativeIndex].showDebug = false;
        } else {
          console.error('Creative not found in creatives array:', creativeId);
        }
      }).catch(error => {
        console.error('Error fetching creative analysis', error);
        const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
        if(creativeIndex !== -1) {
          this.creatives[creativeIndex].analysisStatus = '';
          this.creatives[creativeIndex].analysing = false;
        }
        this.toastrService.error('Error fetching creative analysis');
      });
    } else if(creativeId.startsWith('VID')) {
      this.creativeAnalysisService.getCreativeAnalysisVideo(this.accountId, creativeId).then(analysis => {
        console.log('Creative analysis:', analysis);
        // Get the index of the creative in the creatives array based on the creativeId
        const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
        if(creativeIndex !== -1) {
          this.creatives[creativeIndex].analysing = false;
          this.creatives[creativeIndex].classified = analysis.classified;
          this.creatives[creativeIndex].classificationId = analysis.classificationId;
          this.creatives[creativeIndex].analysisStatus = '';
          this.creatives[creativeIndex].mainAngle = analysis.mainAngle;
          this.creatives[creativeIndex].mainAngleReasons = analysis.mainAngleReasons;
          this.creatives[creativeIndex].mainAngleAlternatives = analysis.mainAngleAlternatives;
          this.creatives[creativeIndex].creativeStyle = analysis.creativeStyle;
          this.creatives[creativeIndex].creativeStyleAlternatives = analysis.creativeStyleAlternatives;
          this.creatives[creativeIndex].creativeStyleReasons = analysis.creativeStyleReasons;
          this.creatives[creativeIndex].targetMarketAgeCategory = analysis.targetMarketAgeCategory;
          this.creatives[creativeIndex].targetMarketGender = analysis.targetMarketGender;
          this.creatives[creativeIndex].targetMarketDescription = analysis.targetMarketDescription;
          this.creatives[creativeIndex].targetMarketReasons = analysis.targetMarketReasons;
          this.creatives[creativeIndex].marketAwareness = analysis.marketAwareness;
          this.creatives[creativeIndex].marketAwarenessReasons = analysis.marketAwarenessReasons;
          this.creatives[creativeIndex].discount = analysis.discount;
          this.creatives[creativeIndex].discountCode = analysis.discountCode;
          this.creatives[creativeIndex].audioHook = analysis.audioHook;
          this.creatives[creativeIndex].visualHook = analysis.visualHook;
          this.creatives[creativeIndex].ageGroup = analysis.ageGroup;
          this.creatives[creativeIndex].gender = analysis.gender;
          this.creatives[creativeIndex].length = analysis.length;
          this.creatives[creativeIndex].dialogueCTA = analysis.dialogueCTA;
          this.creatives[creativeIndex].visualEndFrame = analysis.visualEndFrame;
          this.creatives[creativeIndex].model = analysis.model;
          this.creatives[creativeIndex].detectedTexts = analysis.detectedTexts;
          this.creatives[creativeIndex].usageTokensTotal = analysis.usageTokensTotal;
          this.creatives[creativeIndex].prompt = analysis.prompt;
          this.creatives[creativeIndex].reasons = analysis.reasons;
          this.creatives[creativeIndex].rawOutput = analysis.rawOutput;
          this.creatives[creativeIndex].showDebug = false;
        } else {
          console.error('Creative not found in creatives array:', creativeId);
        }
      }).catch(error => {
        console.error('Error fetching creative analysis', error);
        const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
        if(creativeIndex !== -1) {
          this.creatives[creativeIndex].analysisStatus = '';
          this.creatives[creativeIndex].analysing = false;
        }
        this.toastrService.error('Error fetching creative analysis');
      });

    }


  }

  requestAnalysis(creativeId: string): void {
    this.creativeAnalysisService.requestAnalysis(this.accountId, creativeId, this.selectedModel).then(response => {
      console.log('Creative analysis requested', response);
      // this.jobID = response.job;
      this.startPolling(creativeId);
    }).catch(error => {
      console.error('Error requesting creative analysis', error);
      this.toastrService.error('Error requesting creative analysis');
    });
  }

  onDragOver(event: DragEvent): void {
    console.log('onDragOver', event);
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    console.log('onDragLeave', event);
    event.preventDefault();
    event.stopPropagation();
  }

  onDroppedFiles(event: DragEvent): void {
    console.log('onDroppedFiles', event);
    event.preventDefault();
    event.stopPropagation();
    if(event.dataTransfer) {
      const files = event.dataTransfer.files;
      console.log('onDrop files:', files);
      if(files.length > this.maxFilesPerBatch) {
        this.toastrService.error(`Maximum ${this.maxFilesPerBatch} files allowed in a batch`);
        return;
      }
      this.handleFileInput(files);
    } else {
      this.toastrService.error('No files found in the drop');
    }    
  }

  onDropZoneClicked(): void {
    console.log('onDropZoneClicked');
    const fileInput = document.getElementById('filesSelector');
    if(fileInput) {
      fileInput.click();
    }
  }

  onFilesSelected(event: any): void {
    console.log('onFileChange', event);
    event.preventDefault();
    event.stopPropagation();
    if(event.target.files) {
      const files = event.target.files;
      console.log('onFilesSelected files:', files);
      if(files.length > this.maxFilesPerBatch) {
        this.toastrService.error(`Maximum ${this.maxFilesPerBatch} files allowed in a batch`);
        return;
      }
      this.handleFileInput(files);
    } else {
      this.toastrService.error('No files found in the selection');
    }
  }

  handleFileInput(files: FileList): void {
    if(!files || files.length === 0) {
      console.error('No files selected for upload');
      this.toastrService.error('No files selected for upload.');
      return;
    }
    if(files.length > this.maxFilesPerBatch) {
      this.toastrService.error(`Maximum ${this.maxFilesPerBatch} files allowed in a batch`);
      return;
    }
    this.showSelectCreatives = false;
    const fileArray = Array.from(files);
    fileArray.forEach((file, index) => {
      if(file) {
        const creativeId = file.name.substring(0, 6).toUpperCase();
        console.log('handleFileInput:', creativeId, file);
        this.creatives.push({
          id: creativeId
        });
        this.uploadFile(file, index);
      }
    });
  }

  uploadFile(file: File, creativeIndex: number) {
    if (!file) {
      console.error('No file selected for upload.');
      this.toastrService.error('No file selected for upload.');
      return;
    }
    console.log('uploadFile()', file);

    this.creatives[creativeIndex].loading = true;
    this.creatives[creativeIndex].analysing = false;
    this.creatives[creativeIndex].analysisStatus = 'Uploading...';

    const fileOriginalName = file.name;
    const fileType = file.type;
    const creativeId = fileOriginalName.substring(0, 6).toUpperCase();
    this.creatives[creativeIndex].id = creativeId;
    console.log('Uploading file', creativeId, fileOriginalName, fileType);

    // If creativeId does not start with IMG or VID, return error
    if (!creativeId.startsWith('IMG') && !creativeId.startsWith('VID')) {
      console.error('Invalid file name. Creative ID must start with IMG or VID');
      this.toastrService.error('Invalid file name. Creative ID must start with IMGXXX or VIDXXX');
      this.creatives[creativeIndex].loading = false;
      this.creatives[creativeIndex].analysisStatus = '';
      return;
    }

    // Get S3 temporary user storage Upload URL
    let s3UploadUrl = null;
    let fileTempName = '';
    console.log('Getting S3 Upload URL...', creativeId);
    this.creativeAnalysisService.getS3UploadUrl(fileOriginalName, fileType).then(response => {
      console.log('S3 Upload URL', response);
      s3UploadUrl = response.url;
      fileTempName = response.t_name;
      // Upload file to S3 temporary user storage
      return this.creativeAnalysisService.uploadFileToS3(s3UploadUrl!, file!);
    }).then(() => {
      console.log('File uploaded to S3', creativeId);
      // Import the creative from S3 temporary user storage to Firestore & S3 permanent creative analysis storage
      console.log('Importing creative...', creativeId);
      return this.creativeAnalysisService.importCreative(this.accountId, creativeId, fileOriginalName, fileTempName);
    }).then((importedCreative) => {
      console.log('Creative imported successfully', creativeId, importedCreative);
      this.creatives[creativeIndex].thumbnail = importedCreative.thumbnail;
      this.creatives[creativeIndex].url = importedCreative.url;
      this.creatives[creativeIndex].loading = false;
      this.creatives[creativeIndex].analysisStatus = '';

      // Request creative classification      
      this.classifyCreative(creativeId);

    }).catch(error => {
      console.error('Error getting S3 Upload URL', creativeId, error);
      this.creatives[creativeIndex].loading = false;
      this.creatives[creativeIndex].analysisStatus = '';
    });

  }

  toggleDropdownModel() {
    this.dropdownModelOpen = !this.dropdownModelOpen;
  }

  closeDropdownModel(model: 'openai' | 'gemini') {
    this.dropdownModelOpen = false;
    this.selectedModel = model;
    console.log('Selected model:', this.selectedModel);
  }

  showCreativeDebug(creativeId: string) {
    console.log('showCreativeDebug', creativeId);
    const creativeIndex = this.creatives.findIndex(creative => creative.id === creativeId);
    this.creatives[creativeIndex].showDebug = !this.creatives[creativeIndex].showDebug;
  }

}
