import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdAccountsAuthGuardService implements CanActivate {
  // Dependency Injection
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);


  constructor(
  ) {
    console.log("Initialising AdAccountsAuthGuardService");
   }

  canActivate(
    route: ActivatedRouteSnapshot, 
    router: RouterStateSnapshot
  ): boolean | Promise<boolean> | Observable<boolean> {
    console.log('AdAccountsAuthGuardService authenticatedUser', this.authService.authenticatedUser);
    if(this.authService.authenticatedUser && this.authService.authenticatedUser.manageAccounts && this.authService.authenticatedUser.manageAccounts.length > 0) {
        console.log('AdAccountsAuthGuardService user is managing accounts', this.authService.authenticatedUser.manageAccounts);
        return true;
    } else {
        console.log('AdAccountsAuthGuardService user is NOT managing accounts', this.authService.authenticatedUser);
        this.router.navigate(['/']);
        return false;
    }
  }
}
