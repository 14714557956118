<div class="container-fluid" style="height: 100%;">

  <div class="row" style="height: 100%;">

    <div class="col-pixel-width-fixed" [class.hideSideBar]="sideBarHidden" style="background-color: black;">
      <app-nav-bar></app-nav-bar>
    </div>

    <div class="col" style="background-color: #FFFFFF;">
      <button class="btn btn-sm btn-dark" (click)="onToggleSideBar()" style="margin: 10px 0 10px -15px; background-color: #262D3F; border-color: #262D3F; border-top-left-radius: 0; border-bottom-left-radius: 0;">
        <img src="/assets/images/pix.gif" [class.toggleSideBarImageHidden]="!sideBarHidden" style="width: 1px; margin-right: 0px;"><img [src]="sideBarHideButtonImage" style="width: 20px; height: 20px;">
      </button>
      <app-splash-screen 
        [message]="authServiceInitialisingMessage" 
        *ngIf="showAuthServiceInitialisingSplashScreen">
      </app-splash-screen> 
      <router-outlet />
    </div>

  </div>

</div>