<div class="container">

    <div class="row" style="margin: 0 30px;">
        <div class="col">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-bottom: 30px;" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts">Ad Accounts</a></li>
                    <li class="breadcrumb-item"><a routerLink="/ad-accounts/{{ accountId }}">{{ accountId | titlecase }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Naming Convention</li>
                </ol>
            </nav>

            <div *ngIf="namingConventionLoading" style="color: grey; margin-bottom: 20px;">
                <span class="spinner-border text-dark" role="status" aria-hidden="true" style="vertical-align: middle; margin-right: 10px;"></span>Loading the naming convention<span class="ellipsisAnimated"></span>
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="namingConventionLoadingError != null">
                <i class="bi bi-exclamation-triangle-fill" style="margin-right: 10px;"></i>{{ namingConventionLoadingError }}
            </div>

            <div class="col-md-6 alert alert-warning" style="margin-bottom: 40px;" role="alert">
                <div>Campaign, ad set and ad names must follow these rules:</div>
                <ul style="margin-bottom: 0;">
                    <li>Use "_" (underscore) as a separator</li>
                    <li>Start with a date YYYYMMDD (ie. 20240806)</li>
                    <li>Be composed of substrings using only the following characters:</li>
                    <ul>
                        <li>A to Z letters (upper and lower case)</li>
                        <li>0 to 9 numbers</li>
                        <li>- (minus sign)</li>
                        <li>+ (plus sign)</li>
                        <li>& (ampersand sign)</li>
                        <li>$ (dollar sign)</li>
                    </ul>
                </ul>
            </div>

            <div *ngIf="namingConventionLoaded" style="max-width: 1000px; background-color: black; border-radius: 10px; padding: 5px 10px;">
                <ngx-json-viewer [json]="namingConvention" style="font-size: 12px;"></ngx-json-viewer>
            </div>

            
        </div>    
    </div>
</div>