import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { NamingConvention } from '../interfaces/naming-convention';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NamingConventionService {
  // Dependency Injection
  private apiService: ApiService = inject(ApiService);  

  namingConvention: NamingConvention | null = null;

  // Track if the service is currently loading the Naming Convention from the database to prevent firing multiple concurrent calls to the API
  loadingNamingConvention = false;


  constructor() { 
    console.log("[NamingConventionService] Initialising NamingConventionService");
  }

  setId(id: string) {
    console.log("[NamingConventionService] setId()", id);
    this.namingConvention = {
        id: id
    };
}

  async get() {
    return new Promise<NamingConvention>((resolve, reject) => {
        console.log("[NamingConventionService] get()");
        if(!this.namingConvention) {
            reject("Naming Convention not loaded");
        } else {
            this.loadingNamingConvention = true;      
            let requestPath = '/naming-conventions?get=current&id=' + this.namingConvention.id;
            let requestParams = { params:
                new HttpParams()
            };
            this.apiService.get(requestPath, requestParams)
            .then(response => {
                console.log("[NamingConventionService] Get response", response);   
                this.loadingNamingConvention = false;
                this.namingConvention = response.data;
                resolve(this.namingConvention!);
            })
            .catch(error => {
                console.log("[NamingConventionService] Get error", error.message);
                this.loadingNamingConvention = false;
                reject(error.message);
            });
        }
    });
  }

}
