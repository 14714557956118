// This file can be replaced during build by using the `fileReplacements` array.

// `ng build` replaces `environment.ts` with `environment.dev.ts`.

// The list of file replacements can be found in `angular.json`.

export const environment = {

    production: false,
    appName: "Hubble Genesis",
    version: 0.13,
    backendUrl: "https://rlm7m8xzhg.execute-api.us-west-2.amazonaws.com/Prod",
    firebaseConfig: {
        apiKey: "AIzaSyDOeM253sYnfGgVh85Ga8dxTZ8IkYfetSI",
        authDomain: "hubble-genesis-frontend-dev.firebaseapp.com",
        projectId: "hubble-genesis-frontend-dev",
        storageBucket: "hubble-genesis-frontend-dev.appspot.com",
        messagingSenderId: "557642744346",
        appId: "1:557642744346:web:3ebb389be5a5aaf9fd87a3",
        measurementId: "G-CQCDRRDZDC"
    },
    publicAssetsBucketUrl: "https://hubble-genesis-assets-dev.s3.us-west-2.amazonaws.com/app-public",
};