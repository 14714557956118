import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService implements CanActivate {
  // Dependency Injection
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);


  constructor(
  ) {
    console.log("Initialising AdminAuthGuardService");
   }

  canActivate(
    route: ActivatedRouteSnapshot, 
    router: RouterStateSnapshot
  ): boolean | Promise<boolean> | Observable<boolean> {
    let isAdmin = this.authService.isAdmin;    
    if (isAdmin) {
      console.log('AdminAuthGuardService user is admin');
      return true;
    } else {
      console.log('AdminAuthGuardService user is NOT authenticated');
      this.router.navigate(['/not-found']);
      return false;
    }
  }
}
