import { Injectable, OnDestroy, inject } from '@angular/core';
import { Auth, signInWithPopup, GoogleAuthProvider, signOut, user, User, UserCredential, getAdditionalUserInfo } from '@angular/fire/auth';
import { Subscription, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticatedUser } from '../models/authenticatedUser.model';

import { HttpService } from '../services/http.service';

import { environment } from '../../environments/environment';

import * as _ from 'lodash';
import { AdAccount } from '../interfaces/ad-account';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  // Dependency Injection
  router: Router = inject(Router);
  httpService: HttpService = inject(HttpService);
  auth: Auth = inject(Auth);

  LOADING_IMAGE_URL = 'https://www.google.com/images/spin-32.gif?a';

  private provider = new GoogleAuthProvider();
  isAuthenticationLoading: boolean = false;
  authenticatedUser: AuthenticatedUser | null = null;
  isAdmin: boolean = false;
  // The user observable streams events triggered by sign-in, sign-out, and idToken refresh events (i.e. is updated when the auth state changes or the idToken refreshes)
  user$ = user(this.auth);
  userSubscription: Subscription;
  // Create authenticatedUser state as a Subject to notify components when user auth state changes (user is signing in/signing out or the idToken refreshes)
  authenticatedUserSubject = new Subject<AuthenticatedUser|null>();
 
  constructor(
  ) {
      console.log("Initialising AuthService [production = "+environment.production+"]");
      // Set isAuthenticationLoading to true while the app is authenticating with Firebase
      this.isAuthenticationLoading = true;
      // Try to get autenticated user data from Local Storage
      const localStorageItemUser = localStorage.getItem('user');
      console.log('localStorageItemUser', localStorageItemUser)
      if(localStorageItemUser) {
        const userLocalStorage: {
          uid: string,
          email: string,
          name: string,
          photo: string
        } = JSON.parse(localStorageItemUser);
        let authedUser = new AuthenticatedUser(
          userLocalStorage.uid,
          userLocalStorage.email,
          userLocalStorage.name,
          userLocalStorage.photo
        );
        this.updateAuthenticatedUser(authedUser);
        console.log("FirebaseAuthService initialised. LocalStorage user data = " + authedUser.uid + " [" + authedUser.email + "].", authedUser);     
        // Retrieve admin status from localStorage
        const localStorageItemAdmin = localStorage.getItem('admin');
        if(localStorageItemAdmin) {
          const adminLocalStorage = JSON.parse(localStorageItemAdmin);
          if (adminLocalStorage && adminLocalStorage == userLocalStorage.uid) {
            // Admin user
            console.log("LocalStorage user type: ADMIN");
            this.isAdmin = true;
          }
        } else {
          console.log("LocalStorage user type: STANDARD");
        }         
        // Retrieve manageAccounts from localStorage
        const localStorageItemManageAccounts = localStorage.getItem('manageAccounts');
        if(localStorageItemManageAccounts) {
          const manageAccountsLocalStorage = JSON.parse(localStorageItemManageAccounts);
          if (manageAccountsLocalStorage && manageAccountsLocalStorage.length > 0) {
            // ManageAccounts exist
            console.log("LocalStorage manageAccounts", manageAccountsLocalStorage);
            authedUser.manageAccounts = manageAccountsLocalStorage;
            console.log("LocalStorage authedUser updated with manageAccounts", authedUser);
          }
        }          
      } else {
        console.log("FirebaseAuthService initialised. NO LocalStorage user data.");
      }

        // Subscribe to get notified when Firebase Authentication state changes (user sign in/sign out or app check sign in at startup)
        this.userSubscription = this.user$.subscribe((aUser: User | null) => {
            // Handle user state changes here. Note, that user will be null if there is no currently logged in user.
            console.log('[AuthService] UserSubscription Triggered', aUser);
            // Set isAuthenticationLoading to false as authenticating with Firebase would be now over (on app startup)
            this.isAuthenticationLoading = false;
            // Check if user is signed in (authenticated) or not
            if(aUser) {
              // User is signed in
              console.log("[AuthService] Firebase Authentication state notification received: Signing in as " + aUser.uid + " [" + aUser.email + "]", aUser);
              this.handelFirebaseAuthenticatedUser(aUser)
              .then(() => {
                console.log('[AuthService] userSubscription updated', aUser?.toJSON());
                // Notify other components that info on the authenticated user has changed (partial info from Firebase Auth user object)
                this.authenticatedUserSubject.next(this.authenticatedUser);
              })
              .then(() => {
                this.getUserInfo().then((userInfo) => {
                  console.log('AppComponent [AuthService] UserInfo', userInfo);
                  // Notify other components that info on the authenticated user has changed
                  this.authenticatedUser = userInfo;
                  this.authenticatedUserSubject.next(this.authenticatedUser);
                }).catch((error) => {
                  console.log('AppComponent [AuthService] Failed retrieving UserInfo', error);
                  // Notify other components that info on the authenticated user has changed
                  this.authenticatedUser = null;
                  this.authenticatedUserSubject.next(this.authenticatedUser);
                })
              });
            } else {
              // User is NOT signed in
              console.log("[AuthService] Firebase Authentication state notification received: NOT Signed in");
              this.authenticatedUser = null;
              this.isAdmin = false;
              this.deleteFBUserFromLocalStorage();
              // Notify components listening to user authenticated state that user is NOT signed in
              this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
            }
        });
  }

  ngOnDestroy(): void {
    // Unsubscribe from observables
    this.userSubscription.unsubscribe();
  }

  async signin() {
    let userCredential: UserCredential;
    let isNewUser = false;
    let idToken: string;

    return new Promise<{authenticatedUser:AuthenticatedUser}>((resolve, reject) => {
      // 1. Signing in with Google Auth PopUp
      console.log("[AuthService] [SIGN IN] Signing in to Firebase with Google...");
      signInWithPopup(this.auth, this.provider)
      .then((result) => {
        // 2. User signed in with Google. Extract user credential and info.
        return new Promise<UserCredential>((resolve, reject) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          console.log('[AuthService] [SIGN IN] Signed in as ', credential?.toJSON());
          this.handelFirebaseAuthenticatedUser(result.user).then(() => {
              userCredential = result;
              const additionalUserInfo = getAdditionalUserInfo(result);
              isNewUser = (additionalUserInfo && additionalUserInfo.isNewUser) ? true : false;
              console.log('[AuthService] [SIGN IN] isNewUser ', isNewUser);
              resolve(userCredential);
          });          
        });        
      })
      .then((userCredential) => {
          // 3. User credential retrieved. Get Firebase Id Token for use with our backend.
          console.log("[AuthService] [SIGN IN] Getting Id Token...");
          return userCredential.user.getIdToken();
      })
      .then((token) => {
        // 4. Firebase Id Token retrieved. Does user need to be signed up?
        console.log("[AuthService] [SIGN IN] Id Token", token);
        idToken = token;
        return new Promise<void>((resolve, reject) => {
          if(isNewUser) {
            // New Google User => Process to sign up
            console.log("[AuthService] [SIGN IN] New user => Sign up...");
            // Send sign up request to our backend
            console.log("[AuthService] [SIGN UP] Sending sign up request to our backend...");
            let requestUrl = environment.backendUrl + '/sign?up=1';
            let headers = {
              'Authorization': `Bearer ${idToken}`,
            };            
            console.log("[AuthService] [SIGN UP] Sending GET request", requestUrl);
            this.httpService.get(requestUrl, {headers})
            .then((data) => {
              if (data) {
                // Return data
                console.log("[AuthService] [SIGN UP] Data in GET request response", data);
                resolve();
              } else {
                // No data, return error
                console.log("[AuthService] [SIGN UP] Error, NO data in GET request response", data);
                console.log("[AuthService] Failed to sign up to backend [bck]");
                reject("No sign in data [bck]");
              }                
            })
            .catch((error) => {
              console.log('[AuthService] [SIGN UP] GET Error', error);
              signOut(this.auth).then(() => {
                console.log('[AuthService] [SIGN UP] Signed out');
                this.authenticatedUser = null;
                // Notify components listening to user authenticated state that user is NOT signed in
                this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
                reject(error);
              }).catch((error) => {
                  console.log('[AuthService] [SIGN UP] Sign out error: ' + error);
                  this.authenticatedUser = null;
                  // Notify components listening to user authenticated state that user is NOT signed in
                  this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
                  reject(error);
              })
            });

          } else {
            // Existing user => No need to sign up, proceed with sign in.
            resolve();
          }
        });
      })
      .then(() => {
        // 5. Sign up handled if necessary. Sign in to our backend.
        console.log("[AuthService] [SIGN IN] Sending sign in request to our backend...");
        return new Promise<void>((resolve, reject) => {
          let requestUrl = environment.backendUrl + '/sign?in=1';
          let headers = {
            'Authorization': `Bearer ${idToken}`,
          };            
          console.log("[AuthService] [SIGN IN] Sending GET request", requestUrl);
          this.httpService.get(requestUrl, {headers})
          .then((data) => {
            console.log("[AuthService] [SIGN IN] GET response", data);
            if(data && data.data.isAdmin == true) {
              console.log("[SIGN IN] ADMINuser");
              this.isAdmin = true;
              // Notify components listening to user authenticated state that user is NOT signed in
              this.authenticatedUserSubject.next(this.authenticatedUser);                           
              // Admin => Save Admin status to localStorage for use on app restart (on browser refresh/restart)
              if(this.authenticatedUser && this.authenticatedUser.uid) localStorage.setItem('admin', JSON.stringify(this.authenticatedUser.uid));
            } else {
              // Not Admin => Clear Admin status in localStorage
              console.log("[SIGN IN] STANDARDuser");
              localStorage.removeItem('admin');
            }
            if(data && data.data && data.data.manageAccounts && data.data.manageAccounts.length > 0) {
              // Save manageAccounts to localStorage for use on app restart (on browser refresh/restart)
              console.log("[SIGN IN] manageAccounts", data.data.manageAccounts);
              if(this.authenticatedUser && this.authenticatedUser.uid) localStorage.setItem('manageAccounts', JSON.stringify(data.data.manageAccounts));
            } else {
              // No manageAccounts => Clear manageAccounts in localStorage
              localStorage.removeItem('manageAccounts');
            }
            resolve();
          })
          .catch((error) => {
            console.log('[AuthService] [SIGN IN] GET Error', error);
            signOut(this.auth).then(() => {
              console.log('[AuthService] [SIGN IN] Signed out');
              this.authenticatedUser = null;
              // Notify components listening to user authenticated state that user is NOT signed in
              this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
              reject(error);
            }).catch((error) => {
                console.log('[AuthService] [SIGN IN] Sign out error: ' + error);
                this.authenticatedUser = null;
                // Notify components listening to user authenticated state that user is NOT signed in
                this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
                reject(error);
            })
          });
        });
      })
      .then(() => {
        // 6. All good. Return authenticated user object.
        console.log("[AuthService] [SIGN IN] All good, returning successful sign in", this.authenticatedUser);
        resolve({authenticatedUser: this.authenticatedUser!});
      })
      .catch((error) => {
        // Catch errors
        console.log("[AuthService] [SIGN IN] FAILED ", error);
        reject(error);
      });
    });
  }


   // Sign out 
   async signOut() {
    console.log("[AuthService] [SIGN OUT] Signing out... ");
    this.deleteFBUserFromLocalStorage();             
    return new Promise<void>((resolve, reject) => {
      this.getIdToken()
        .then(idToken => {
          // Send sign out notification request to our backend
          return new Promise<void>((resolve, reject) => {
            let requestUrl = environment.backendUrl + '/sign?out=1';
            let headers = {
              'Authorization': `Bearer ${idToken}`,
            };            
            console.log("[AuthService] [SIGN OUT] Sending GET request", requestUrl);
            this.httpService.get(requestUrl, {headers})
            .then((data) => {
              console.log("[AuthService] [SIGN OUT] GET response", data);
              resolve();
            })
            .catch((error) => {
              console.log('[AuthService] [SIGN OUT] GET Error', error);
              signOut(this.auth).then(() => {
                console.log('[AuthService] [SIGN OUT] Signed out');
                this.authenticatedUser = null;
                // Notify components listening to user authenticated state that user is NOT signed in
                this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
                reject(error);
              }).catch((error) => {
                  console.log('[AuthService] [SIGN OUT] Sign out error: ' + error);
                  this.authenticatedUser = null;
                  // Notify components listening to user authenticated state that user is NOT signed in
                  this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
                  reject(error);
              })
            });
          });              
        })
        .then(() => {
          // Sign out from Firebase Auth
          return signOut(this.auth);
        })
        .then(() => {
          console.log("[AuthService] [SIGN OUT] Signed out from Firebase Auth");
          this.authenticatedUser = null;     
          this.isAdmin = false;
          this.router.navigate(['/']);
          // Notify components listening to user authenticated state that user has signed OUT
          this.authenticatedUserSubject.next(null); // Send null to indicate user is NOT signed in
          resolve();
        })
        .catch(error => {
          console.log("[AuthService] [SIGN OUT] Error", error);
          // Sign out from Firebase Auth
          signOut(this.auth)
          .then(() => {
            this.router.navigate(['/']);
            // Silent error
            resolve();
          })
        })
    });
  }

  // Get Firebase idToken for the authenticated user
  async getIdToken() {
    return new Promise<string>((resolve, reject) => {
      //console.log("[AuthService] getIdToken() Getting Firebase user idToken...");
      if (!this.auth.currentUser) {
        reject("Unauthenticated");
      }
      this.auth.currentUser?.getIdToken().then(idToken => {
        // currentUser.getIdToken() returns the current idToken if it has not expired. Otherwise, this will refresh the idToken and return a new one.
        // It will never return an error, even if the net connection is down and it can't refresh the idToken (it will then hang)   
        console.log("[AuthService] getIdToken() idToken", idToken);
        resolve(idToken);          
      });   
    });
  }

  // Get authenticated user from backend
  async getUserInfo() {
    return new Promise<AuthenticatedUser>((resolve, reject) => {
      console.log("[AuthService] getUserInfo() Getting user info from backend...");
      this.getIdToken().then((idToken) => {
        let requestUrl = environment.backendUrl + '/users?get=me';
        let headers = {
          'Authorization': `Bearer ${idToken}`,
        };            
        console.log("[AuthService] getUserInfo() Sending GET request", requestUrl);
        this.httpService.get(requestUrl, {headers})
        .then((resp) => {
          if (resp) {
            // Return data
            console.log("[AuthService] getUserInfo() Data in GET request response", resp);
            let authedUser = new AuthenticatedUser(
              resp.data.uid,
              resp.data.email || '',
              resp.data.name || ''
            );
            if(resp.data.photo) {
              // A photo exists in the database => Use it
              authedUser.photo = resp.data.photo;
            } else {
              // No photo in the database => Keep the one already in authenticatedUser (from Google sign in) 
              if(this.authenticatedUser && this.authenticatedUser?.photo) authedUser.photo = this.authenticatedUser?.photo
              else authedUser.photo = '/assets/images/avatar.png';
            }  
            if(resp.data.created) authedUser.created = resp.data.created;
            if(resp.data.isActive) authedUser.isActive = resp.data.isActive;
            if(resp.data.manageAccounts) authedUser.manageAccounts = resp.data.manageAccounts;
            // console.log("[AuthService] getUserInfo() Returning", authedUser);
            resolve(authedUser);
          } else {
            // No data, return error
            console.log("[AuthService] getUserInfo() Error, NO data in GET request response", resp);
            reject("No user data [bck]");
          }                
        })
        .catch((error) => {
          console.log('[AuthService] getUserInfo() GET Error', error);
          reject(error);
        });
      }).catch((error) => {
        console.log('[AuthService] getUserInfo() getIdToken Error', error);
        reject(error);
      });
      
    });
  }

  // Function to wait for a delay in milliseconds
  async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  

  async handelFirebaseAuthenticatedUser(user: User) {
    return new Promise<AuthenticatedUser>((resolve, reject) => {
      console.log("[AuthService] Signed in to Firebase as " + user.email + " [" + user.uid + "]", user);
      this.saveFBUserToLocalStorage(user);
      let authedUser = new AuthenticatedUser(
        user.uid,
        user.email || '',
        user.displayName || ''
      );
      if(user.photoURL) authedUser.photo = user.photoURL;
      this.authenticatedUser = authedUser;
      console.log("[AuthService] AuthenticatedUser updated", this.authenticatedUser);
      // Notify components listening to user authenticated state (user sign in/sign out or app check sign in at startup)
      this.authenticatedUserSubject.next(authedUser);
      resolve(authedUser);
    });
  }


  updateAuthenticatedUser(authedUser: AuthenticatedUser) {
    this.authenticatedUser = authedUser;
    // Notify components listening to user authenticated state (user sign in/sign out or app check sign in at startup)
    this.authenticatedUserSubject.next(this.authenticatedUser);
  }

  saveFBUserToLocalStorage(user: User) {
    this.authenticatedUser = new AuthenticatedUser(
      user.uid,
      user.email || '',
      user.displayName || '',
      user.photoURL || ''
    );
    console.log("Saving user data to localStorage", this.authenticatedUser);
    localStorage.setItem('user', JSON.stringify(this.authenticatedUser));
  }

  deleteFBUserFromLocalStorage() {
    // Clear user item in localStorage
    localStorage.removeItem('user');
    // Clear admin item in localStorage
    localStorage.removeItem('admin');
    // Clear manageAccounts item in localStorage
    localStorage.removeItem('manageAccounts');
  }

  async checkUserSignedIn() {
    console.log("[AuthService.checkUserSignedIn] Checking that user is signed in");

    if (this.isAuthenticationLoading) {
      // If authentication is currently loading (app start up/refresh), 
      // give it time to let it finish (otherwise calls to getIdToken() will fail!!!)

      // First try - Wait 0.5 second and check authenticatedUser exists and getIdToken() does return a token
      let delay = 500;
      console.log("[AuthService.checkUserSignedIn] First Delayed auth check ("+delay+" ms)");
      await this.sleep(delay);
      let currentUser = this.auth.currentUser;
      console.log('[AuthService.checkUserSignedIn] currentUser after First Delayed auth check', currentUser ? currentUser.email : null);
      if(currentUser) {
        let idToken = await currentUser.getIdToken();
        console.log('[AuthService.checkUserSignedIn] idToken after First Delayed auth check', idToken);
        if (this.authenticatedUser && idToken) {
          console.log("[AuthService.checkUserSignedIn] First Delayed auth check successful");
          return new Promise<boolean>((resolve) => {
            resolve(true);
          })
        }
      }

      // Second try - Not yet a valid authenticatedUser & getIdToken() response => Wait another 1.5 seconds and try again
      delay = 1500;
      console.log("[AuthService.checkUserSignedIn] Second Delayed auth check ("+delay+" ms)");
      await this.sleep(delay);
      currentUser = this.auth.currentUser;
      console.log('[AuthService.checkUserSignedIn] currentUser after Second Delayed auth check', currentUser ? currentUser.email : null);
      if(currentUser) {
        let idToken = await currentUser.getIdToken();
        console.log('[AuthService.checkUserSignedIn] idToken after Second Delayed auth check', idToken);
        if (this.authenticatedUser && idToken) {
          console.log("[AuthService.checkUserSignedIn] Second Delayed auth check successful");
          return new Promise<boolean>((resolve) => {
            resolve(true);
          })
        }
      }

      // Third try - Still no valid authenticatedUser & getIdToken() response => Wait another 5 seconds and try again
      delay = 5000;
      console.log("[AuthService.checkUserSignedIn] Third Delayed auth check ("+delay+" ms)");
      await this.sleep(delay);
      currentUser = this.auth.currentUser;
      console.log('[AuthService.checkUserSignedIn] currentUser after Third Delayed auth check', currentUser ? currentUser.email : null);
      if(currentUser) {
        let idToken = await currentUser.getIdToken();
        console.log('[AuthService.checkUserSignedIn] idToken after Third Delayed auth check', idToken);
        if (this.authenticatedUser && idToken) {
          console.log("[AuthService.checkUserSignedIn] Third Delayed auth check successful");
          return new Promise<boolean>((resolve) => {
            resolve(true);
          })
        }
      }

      // Fourth try - Still no valid authenticatedUser & getIdToken() response => Wait another 8 seconds and try again
      delay = 8000;
      console.log("[AuthService.checkUserSignedIn] Fourth Delayed auth check ("+delay+" ms)");
      await this.sleep(delay);
      currentUser = this.auth.currentUser;
      console.log('[AuthService.checkUserSignedIn] currentUser after Fourth Delayed auth check', currentUser ? currentUser.email : null);
      if(currentUser) {
        let idToken = await currentUser.getIdToken();
        console.log('[AuthService.checkUserSignedIn] idToken after Fourth Delayed auth check', idToken);
        if (this.authenticatedUser && idToken) {
          console.log("[AuthService.checkUserSignedIn] Fourth Delayed auth check successful");
          return new Promise<boolean>((resolve) => {
            resolve(true);
          })
        }
      }

      // Still no valid authenticatedUser & getIdToken() response => Return an error
      console.log("[AuthService.checkUserSignedIn] All auth checks failed");
      return new Promise<boolean>((resolve) => {
        resolve(false);
      })
      
    } else {
      // App is already loaded
      // Instant auth check
      console.log("[AuthService.checkUserSignedIn] Instant auth check");
      if (this.authenticatedUser) {
        console.log("[AuthService.checkUserSignedIn] Instant auth check successful");
        return new Promise<boolean>((resolve) => {
          resolve(true);
        })
      } else {
        console.log("[AuthService.checkUserSignedIn] Instant auth check failed");
        return new Promise<boolean>((resolve) => {
          resolve(false);
        })
      }
    }

  }

  async checkUserHasAccessToAdAccounts() {
    // Check if the user has access to the Ad Accounts section
    // ie. if the user has access to at least one Ad Account
    // This function uses getUserInfo() which calls getIdToken(), so no need to call getIdToken() beforehand

    return new Promise<AdAccount[]|null>((resolve) => {
      this.getUserInfo().then((userInfo) => {
        console.log('[AuthService] checkUserHasAccessToAdAccounts UserInfo', userInfo);
        if(userInfo && userInfo.manageAccounts && userInfo.manageAccounts.length > 0) {
          console.log('[AuthService] checkUserHasAccessToAdAccounts User has access to Ad Accounts', userInfo.manageAccounts);
          let adAccounts: AdAccount[] = [];
          _.forEach(userInfo.manageAccounts, (accountId: string) => {
            adAccounts.push({id: accountId, name: accountId, logo: `${environment.publicAssetsBucketUrl}/app-public/images/${accountId}-logo.png`});
          });
          resolve(adAccounts);
        } else {
          console.log('[AuthService] checkUserHasAccessToAdAccounts User does NOT have access to Ad Accounts');
          resolve(null);
        }
      }).catch((error) => {
        console.log('[AuthService] checkUserHasAccessToAdAccounts failed retrieving UserInfo', error);
        resolve(null);
      })
    });
  }

}
